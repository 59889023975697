import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

let mapStyling = [
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#e0efef",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        hue: "#1900ff",
      },
      {
        color: "#c0e8e8",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        lightness: 700,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#7dcdcd",
      },
    ],
  },
];

let mapData = {
  madagascar: {
    center: {
      lat: -16.214861,
      lng: 46.935444,
    },
    marker: {
      lat: -16.155296,
      lng: 46.995444,
    },
    zoom: 10,
    height: "720px",
  },
  nepal: {
    center: {
      lat: 27.530812,
      lng: 84.4451192,
    },
    marker: {
      lat: 27.530812,
      lng: 84.4451192,
    },
    zoom: 0,
    height: "820px",
  },
  haiti: {
    // 18.561270, -72.064549
    center: {
      lat: 18.56127,
      lng: -72.064549,
    },
    marker: {
      lat: 18.56127,
      lng: -72.064549,
    },
    zoom: 13,
    height: "730px",
  },
};

const MarkerComponent = ({ text }) => (
  <div
    style={{
      height: "50px",
      width: "50px",
      backgroundColor: "rgb(254 203 91)",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bolder",
      fontSize: "20px",
    }}
  >
    {text}
  </div>
);

/**
 * I: purchased_gifts[],
 */
class GoogleMaps extends Component {
  state = {};

  render() {
    return (
      <>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCkRxiZZQqlt5WSCWsD71lxjXYKcVCfXRI",
          }}
          defaultCenter={{ lat: 2.373688, lng: 5.231233 }}
          defaultZoom={0}
          options={{ styles: mapStyling, minZoom: "2" }}
        >
          {["nepal", "madagascar", "haiti"].map((country, idx) => (
            <MarkerComponent
              key={idx}
              lat={mapData[country].marker.lat}
              lng={mapData[country].marker.lng}
              text={
                this.props.purchased_gifts
                  .map((gift) => gift.location === country)
                  .filter((a) => a).length
              }
            />
          ))}
        </GoogleMapReact>{" "}
      </>
    );
  }
}

export default GoogleMaps;
