import React, { Component, createContext } from "react";
import { auth, createUserProfileDocument } from "../firebase";
import { withRouter } from "react-router-dom";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = { user: null };

  state = {
    user: null,
  };

  unsubscribefromAuth = null;

  constructor(props) {
    super(props);
    localStorage.removeItem("userAuth");

    // document.body.style.display = "None";
    // console.log("currentUser: ", auth.currentUser);
  }

  componentDidMount = async () => {
    this.unsubscribefromAuth = auth.onAuthStateChanged(async (userAuth) => {
      // console.log("onAuthStateChanged ", userAuth);
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);
        userRef.onSnapshot((snapshot) => {
          // if user signs in again and they verified their email, update user properties
          // console.log("createUserProfileDocument: ", snapshot.data());
          /** email verified causing error  */
          // if (!snapshot.data().emailVerified && userAuth.emailVerified) {
          //   // update user profile on DB
          //   db
          //     .doc(`/users/${snapshot.id}`)
          //     .update({ emailVerified: userAuth.emailVerified });
          //   // console.log("UPDATING emailVerified")
          // }
          // document.body.style.display = "";

          localStorage.setItem("isSignedIn", true);

          // console.log("window.location: ", window.location);
          if (window.location.pathname === "/login") {
            this.setState(
              { user: { id: snapshot.id, ...snapshot.data() } },
              () => this.props.history.push("/profile")
            );
          } else {
            this.setState({ user: { id: snapshot.id, ...snapshot.data() } });
          }
        });

        // console.log("userAuth: ", userAuth)
      } else {
        localStorage.removeItem("isSignedIn");
        const restrictedEndpoints = ["/mygifts", "/credits", "/admin"];
        if (restrictedEndpoints.includes(window.location.pathname)) {
          // redirect if user isn't allowed to access specific pages
          // console.log("redirecting ...")
          this.props.history.push("/");
          // window.location("/");
        }
        // document.body.style.display = "";
      }

      // console.log("USER: ", userAuth);
      // this.setState({ user: userAuth });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribefromAuth();
  };

  render() {
    const { user } = this.state;
    const { children } = this.props;
    // console.log("Provider: ", user);
    // console.log("DATA: ", user, children);
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
}

export default withRouter(UserProvider);
