/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
// import { UserContext } from "../../Providers/UserProvider";

import {
  Container,
  Row,
  Col,
  Card,
  ButtonToolbar,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactButtonHandler from "../Utils/ReactButtonHandler";

import Steps, { Step } from "rc-steps";
import styled from "styled-components";
import debounce from "lodash.debounce";

// Import left arrow icon
import left_arrow from "../assets/left_arrow.png";
import world_legend from "../assets/world_legend.png";

// STEP 2
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

// STEP 3 (Stripe)
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";

// Config
import { config } from "../config.js";
import { UserContext } from "../Providers/UserProvider";

// Themed gift photos
import christmas_1 from "../assets/theme_christmas_1.png";
import christmas_2 from "../assets/theme_christmas_2.png";
import christmas_3 from "../assets/theme_christmas_3.png";
import birthday_1 from "../assets/theme_birthday_1.png";
// TODO: Change croppie to just ask for a picture of their face (skip the cropping step)
import Croppie from "./Croppie.jsx";
import { storage } from "../firebase";

let BUILD_LEVEL = process.env.REACT_APP_BUILD_LEVEL;
// let BUILD_LEVEL = "staging";

/**
 *
 * ERRORS:
 * - hotmail_error
 * - name_error
 * - date_error
 */

if (BUILD_LEVEL === "prod") {
  console.log("PROD LEVEL:::: ");
  config.stripe = "pk_live_OHeHbMJISyJsEh2K20sVbtNI";
} else if (BUILD_LEVEL === "staging") {
  config.stripe = "pk_test_EFglonl3FLuRht8ikEVQJ9lL";
}

const World = styled.div`
  // width: 95%;
  height 100%;
  overflow: hidden;
`;

const InputCSS = styled.span`
  display: flex;
  justify-content: center;
  .slider_num {
    min-width: 100%;
  }
`;

const ThemeCardCSS = styled.span`
  .card-input-element + .card:hover {
    cursor: pointer;
  }
`;

const CardsCSS = styled.span`
  label {
    width: 100%;
    font-size: 1rem;
  }

  .card-input-element + .card {
    height: calc(36px + 2 * 1rem);
    color: var(--primary);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  .card-input-element + .card:hover {
    cursor: pointer;
  }

  .card-input-element:checked + .card {
    border: 2px solid var(--primary);
    -webkit-transition: border 0.3s;
    -o-transition: border 0.3s;
    transition: border 0.3s;
  }

  .card-input-element:checked + .card::after {
    content: "\e5ca";
    color: #afb8ea;
    font-family: "Material Icons";
    font-size: 24px;
    -webkit-animation-name: fadeInCheckbox;
    animation-name: fadeInCheckbox;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  @-webkit-keyframes fadeInCheckbox {
    from {
      opacity: 0;
      -webkit-transform: rotateZ(-20deg);
    }
    to {
      opacity: 1;
      -webkit-transform: rotateZ(0deg);
    }
  }

  @keyframes fadeInCheckbox {
    from {
      opacity: 0;
      transform: rotateZ(-20deg);
    }
    to {
      opacity: 1;
      transform: rotateZ(0deg);
    }
  }
`;

const Toggle = styled.div`
  .Not-scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    // padding-top: 45%;
    z-index: 1;
    background: rgba(211, 211, 211, 0.4);
  }

  .Scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    text-align: center;
    padding-top: 23%;
  }

  .button {
    background-color: #ffffff;
    border: none;
    color: #4c4848;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }
`;

class GiftingEngine extends Component {
  state = {
    current_step: 0,
    selected_1: "",
    value_1: "",
    selected_2: "",
    value_2: "",
    slider: {
      value: 1,
      min: 1,
      max: 100,
      step: 1,
    },
    truncHeight: 0,
    foliageDensity: 0,
    message: "",
    schedule: false,
    referral_url: "",
    buyer_name: "",
    buyer_email: "",
    recipient_name: "",
    recipient_email: "",
    recipient_send_date: "",
    group_gift: false,
    // Themed gifts
    themed_gift: false,
    gift_cover: "",
    cover_image: null,
    first_name: "",
    last_name: "",
    // Tree credit variables>
    redeem_credit: false,
    num_credits_redeemed: 0,
    total_trees: 0,
    // <Tree credit variables
    world_quant: 0,
    loading: false,
    time_taken: "",
    time_taken_breakdown: {
      step_1: "",
      step_2: "",
      step_3: "",
      step_4: "",
      total: "",
    },
    send_email: false,
    show_group_gift_modal: true,
    errors: [], //
    scrollable_world: false,
  };

  getTimeDiff = (start_time) => {
    return Math.floor((new Date() - start_time) / 1000) + "";
  };

  isHotmail = (state_name) => {
    let email = this.state[state_name];

    if (email.indexOf("@hotm") > -1 || email.indexOf("@liv") > -1) {
      // Add hotmail error to stack
      if (this.state.errors.indexOf("hotmail_error") === -1) {
        let errors = this.state.errors;
        errors.push("hotmail_error");
        this.setState({ errors });
      }
      return true;
    } else {
      return false;
    }
  };

  isName = (state_name) => {
    let name = this.state[state_name];

    if (name.indexOf("@") > -1) {
      // Add name error to stack
      if (this.state.errors.indexOf("name_error") === -1) {
        let errors = this.state.errors;
        errors.push("name_error");
        this.setState({ errors });
      }

      return false;
    } else {
      return true;
    }
  };

  // Check if buyer_email == sender_email
  isBuyerEmailEqualRecipientEmail = () => {
    if (this.state.buyer_email === this.state.recipient_email) {
      if (
        this.state.errors.indexOf("buyer_email_recipient_email_warning") === -1
      ) {
        let errors = this.state.errors;
        errors.push("buyer_email_recipient_email_warning");
        this.setState({ errors });
      }
      return true;
    } else {
      return false;
    }
  };

  onSliderInputChange = (e) => {
    if (Number(e.target.value) > -1 && Number(e.target.value) <= 50) {
      let slider = this.state.slider;
      slider["value"] = Number(e.target.value);
      this.setState({ slider });
      this.worldTreeUpdate(e.target.value);
    }
  };

  onSliderChange = (value) => {
    let slider = this.state.slider;
    slider["value"] = value;
    this.setState({ slider });

    this.worldTreeUpdate(value);
  };

  worldTreeUpdate = debounce((value) => {
    this.setState({ world_quant: value });
  }, 1000);

  onChangeHandle = (e) => {
    if (e.target.name === "schedule") {
      this.setState({ schedule: !this.state.schedule });
    } else if (e.target.name === "send_email") {
      this.setState({ send_email: !this.state.send_email });
    } else if (e.target.name === "group_gift") {
      this.setState({ group_gift: !this.state.group_gift });
    } else if (e.target.name === "redeem_credit") {
      this.setState({ redeem_credit: !this.state.redeem_credit });
    } else if (e.target.name === "themed_gift") {
      this.setState({ themed_gift: !this.state.themed_gift });
    } else {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state);
    }
  };

  isValidDate = (string_date) => {
    let ss = string_date.split("/");

    // [0] day, [1] month, [2] year
    if (Number(ss[0]) < 32 && Number(ss[1]) < 13 && Number(ss[2]) < 24) {
      return true;
    } else {
      // Add date error to stack
      if (
        this.state.errors.indexOf("date_error") === -1 &&
        string_date.length >= 8
      ) {
        let errors = this.state.errors;
        errors.push("date_error");
        this.setState({ errors });
      }

      return false;
    }
  };

  get file() {
    return this.imageInput && this.imageInput.files[0];
  }

  onToken = (token) => {
    const body = {
      data: {
        // Gift details
        // this.state.num_credits_redeemed +
        // this.state.slider.value
        user_id: this.context && this.context.id ? this.context.id : "",
        price: this.state.slider.value * 100,
        quantity: this.state.slider.value + this.state.num_credits_redeemed,
        num_credits_redeemed: this.state.num_credits_redeemed,
        location: this.state.selected_1,
        message: this.state.message,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        recipient_name: this.state.themed_gift
          ? this.state.first_name + " " + this.state.last_name
          : this.state.recipient_name,
        recipient_email:
          this.state.recipient_email === ""
            ? ""
            : this.state.recipient_email.replace(/^\s+|\s+$/g, ""),
        buyer_name: this.state.buyer_name,
        buyer_email: this.state.buyer_email.replace(/^\s+|\s+$/g, ""),
        group_gift: this.state.group_gift,
        time_taken_breakdown: {
          step_1: this.state.time_taken_breakdown["step_1"],
          step_2: this.state.time_taken_breakdown["step_2"],
          step_3: this.state.time_taken_breakdown["step_3"],
          step_4: this.state.time_taken_breakdown["step_4"],
          total: Math.floor((new Date() - this.state.time_taken) / 1000) + "",
        },
        supporters: [
          {
            version: "v2",
            name: this.state.buyer_name,
            quantity: this.state.slider.value + this.state.num_credits_redeemed,
            date: new Date(),
          },
        ],
        show_history: true,
        // schedule details (if any)
        schedule: {
          name: this.state.recipient_name,
          email: this.state.send_email
            ? this.state.recipient_email.replace(/^\s+|\s+$/g, "")
            : "",
          date: this.state.send_email
            ? this.state.schedule
              ? this.state.recipient_send_date.replace(/^\s+|\s+$/g, "")
              : ""
            : "",
        },
        // dev details
        env: BUILD_LEVEL,
        treeAttributes: JSON.stringify(window.treeAttributes),
        errors: this.state.errors,
        themed_gift: this.state.themed_gift,
        gift_cover: this.state.themed_gift ? this.state.gift_cover : "",
      },
      token: token,
    };

    if (BUILD_LEVEL === "prod") {
      body.env = "prod";
    } else if (BUILD_LEVEL === "staging") {
      body.env = "staging";
    }
    this.setState({ loading: true });
    axios
      .post(
        `https://the-tree-project-server.firebaseapp.com/${BUILD_LEVEL}/v1/save-stripe-token`,
        body
      )
      .then((response) => {
        if (response.data.status === "success") {
          if (this.state.gift_cover === "birthday_1") {
            storage
              .ref(`gift_cover_images/${response.data.charge_id}`)
              .put(this.state.cover_image)
              .then((resp) => {
                this.setState({ loading: false });
                if (resp.state == "success") {
                  this.setState({
                    referral_url: `gifts/${response.data.charge_id}`,
                  });
                  toast("Success!", { type: "success" });
                }
              });
          } else {
            this.setState({
              loading: false,
              referral_url: `gifts/${response.data.charge_id}`,
            });
            toast("Success!", { type: "success" });
          }
        }
      });
  };

  componentDidMount() {
    if (this.state.time_taken === "") {
      this.setState({ time_taken: new Date() });
    }
  }

  handleModalClose = () => {
    this.setState({ show_group_gift_modal: !this.state.show_group_gift_modal });
  };

  isPhotoTheme = (gift_cover) => {
    if (["birthday_1"].indexOf(gift_cover) == -1) {
      return false;
    } else {
      return true;
    }
  };

  getSelectedTheme = () => {
    let selected_theme = this.state.gift_cover;
    if (selected_theme == "christmas_1") {
      return christmas_1;
    } else if (selected_theme == "christmas_2") {
      return christmas_2;
    } else if (selected_theme == "christmas_3") {
      return christmas_3;
    } else if (selected_theme == "birthday_1") {
      return birthday_1;
    }
  };

  handleImageUpload = (compressed_image) => {
    if (compressed_image) {
      this.setState({
        cover_image: compressed_image,
      });
    } else {
      this.setState({ cover_image: null });
    }
  };

  static contextType = UserContext;

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    let {
      message,
      buyer_name,
      buyer_email,
      recipient_name,
      recipient_email,
      recipient_send_date,
      first_name,
      last_name,
    } = this.state;

    let total_trees_gift =
      this.state.num_credits_redeemed + this.state.slider.value;

    return (
      <Container id="gift" style={{ height: "100%" }} fluid={false}>
        <ToastContainer />

        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className="loading"
          >
            <h3
              style={{
                display: "flex",
              }}
              className="align-items-center"
            >
              Processing &nbsp;&nbsp;
              <Spinner animation="border" variant="success" />
            </h3>
          </div>
        )}

        <Row className="justify-content-md-center">
          <Card style={{ border: "none", width: "100%" }}>
            <Card.Body>
              <Card.Title
                as={"h3"}
                style={{ textAlign: "center", fontSize: "3.0rem" }}
              >
                {BUILD_LEVEL === "staging" ? "(Staging)" : ""}
              </Card.Title>
              <Row>
                <Col>
                  {/* TODO: CHANGE THIS BACK */}
                  {this.state.referral_url === "" ? (
                    <Container>
                      {/* Steps */}

                      <Row>
                        <Steps
                          progressDot
                          size="small"
                          current={this.state.current_step}
                        >
                          <Step
                            title="Location"
                            description={"Where do you want to plant?"}
                          />
                          <Step
                            title="Quantity"
                            description={"How many trees? ($1/tree)"}
                          />
                          <Step
                            title="Message"
                            description={"What message do you want to leave?"}
                          />
                        </Steps>
                      </Row>

                      {/* Step 0 - Location */}
                      {this.state.current_step === 0 && (
                        <Row>
                          <div
                            style={{ fontSize: "2.5rem", marginTop: "20px" }}
                          >
                            Choose your location{" "}
                          </div>
                          <CardsCSS style={{ width: "-webkit-fill-available" }}>
                            <label style={{ width: "100%" }} className="mt-3">
                              <input
                                type="radio"
                                name="demo"
                                className="card-input-element d-none"
                                id="demo1"
                                checked={this.state.selected_1 === "madagascar"}
                                onChange={() => {}}
                              />
                              <div
                                style={{ width: "100%" }}
                                onClick={() => {
                                  // thin and medium height
                                  // https://www.google.com/search?q=mangrove+forests&source=lnms&tbm=isch&sa=X&ved=0ahUKEwi0x7HhkL7kAhXPmeAKHT8qAUAQ_AUIEigB&biw=1428&bih=750
                                  if (this.state.world_quant > 0) {
                                    this.setState({
                                      selected_1: "madagascar",
                                      truncHeight: 85,
                                      foliageDensity: 6,
                                    });
                                  } else {
                                    this.setState({
                                      selected_1: "madagascar",
                                      truncHeight: 85,
                                      foliageDensity: 6,
                                      world_quant: 1,
                                      scrollable_world: true,
                                    });
                                  }
                                }}
                                className={`card card-body bg-light d-flex flex-row justify-content-between align-items-center ${
                                  this.state.world_quant > 0 ? "" : "card-glow"
                                }`}
                                // className=""
                              >
                                <Card.Body style={{ width: "100%" }}>
                                  <Card.Title
                                    style={{ fontWeight: "500!important" }}
                                  >
                                    <strong>Madagascar</strong>
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    Africa
                                  </Card.Subtitle>
                                </Card.Body>
                              </div>
                            </label>
                            <label className="mt-3">
                              <input
                                type="radio"
                                name="demo"
                                className="card-input-element d-none"
                                value="demo2"
                                checked={this.state.selected_1 === "haiti"}
                                onChange={() => {}}
                              />
                              <div
                                onClick={() => {
                                  if (this.state.world_quant > 0) {
                                    this.setState({
                                      selected_1: "haiti",
                                      truncHeight: 100,
                                      foliageDensity: 3,
                                    });
                                  } else {
                                    this.setState({
                                      selected_1: "haiti",
                                      truncHeight: 100,
                                      foliageDensity: 3,
                                      world_quant: 1,
                                      scrollable_world: true,
                                    });
                                  }
                                }}
                                className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                              >
                                <Card.Body>
                                  <Card.Title
                                    style={{ fontWeight: "500!important" }}
                                  >
                                    <strong>Haiti</strong>
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    Caribbean
                                  </Card.Subtitle>
                                </Card.Body>
                              </div>
                            </label>

                            <label className="mt-3">
                              <input
                                type="radio"
                                name="demo"
                                className="card-input-element d-none"
                                value="demo2"
                                checked={this.state.selected_1 === "nepal"}
                                onChange={() => {}}
                              />
                              <div
                                onClick={() => {
                                  if (this.state.world_quant > 0) {
                                    this.setState({
                                      selected_1: "nepal",
                                      truncHeight: 70,
                                      foliageDensity: 5,
                                    });
                                  } else {
                                    this.setState({
                                      selected_1: "nepal",
                                      truncHeight: 70,
                                      foliageDensity: 5,
                                      world_quant: 1,
                                      scrollable_world: true,
                                    });
                                  }
                                }}
                                className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                              >
                                {/* normal but short 
                  https://www.tentree.com/blogs/posts/types-of-trees-we-plant-in-nepal
          `       https://www.google.com/search?biw=1428&bih=750&tbm=isch&sa=1&ei=hFVzXY_6A8GIggexhpCYCg&q=%22acacia+nilotica%22&oq=%22acacia+nilotica%22&gs_l=img.3..0i67l8j0j0i67.5943.6938..7211...0.0..0.127.196.1j1......0....1..gws-wiz-img.MQ0q8py4E58&ved=0ahUKEwiPzPnckb7kAhVBhOAKHTEDBKMQ4dUDCAY&uact=5#imgrc=H_tvlzTkU0YWzM:                          */}
                                <Card.Body>
                                  <Card.Title>
                                    <strong>Nepal</strong>
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    Asia
                                  </Card.Subtitle>
                                </Card.Body>
                              </div>
                            </label>
                          </CardsCSS>
                          <Row
                            style={{ width: "-webkit-fill-available" }}
                            className=""
                          >
                            <Col>
                              <ButtonToolbar style={{ marginTop: "15px" }}>
                                <Button
                                  disabled={true}
                                  onClick={() => {
                                    if (this.state.selected_1 === "") {
                                      toast.warn("Please select an option");
                                    } else {
                                      ReactButtonHandler(
                                        "Button 1 - Country Option",
                                        this.state.time_taken
                                      );

                                      if (
                                        this.state.time_taken_breakdown[
                                          "step_1"
                                        ] === ""
                                      ) {
                                        let time_taken_breakdown = this.state
                                          .time_taken_breakdown;

                                        time_taken_breakdown[
                                          "step_1"
                                        ] = this.getTimeDiff(
                                          this.state.time_taken
                                        );

                                        this.setState({
                                          current_step:
                                            this.state.current_step + 1,
                                          time_taken_breakdown,
                                        });
                                      } else {
                                        this.setState({
                                          current_step:
                                            this.state.current_step + 1,
                                        });
                                      }
                                    }
                                  }}
                                  variant="primary"
                                  size="lg"
                                  block
                                  active
                                >
                                  Select
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Row>
                      )}

                      {/* Step 1 - Quantity */}
                      {this.state.current_step === 1 && (
                        <Row>
                          <Row>
                            <div
                              style={{
                                fontSize: "2.5rem",
                                marginTop: "20px",
                              }}
                            >
                              {" "}
                              Quantity{" "}
                            </div>
                          </Row>
                          <Row
                            style={{
                              marginLeft: "0px",
                              marginRight: "0px",
                            }}
                          >
                            <div
                              style={{
                                width: "90%",
                                marginLeft: "0px",
                                marginTop: "40px",
                                left: "49px",
                                top: "329px",
                                background: "#D4EDDA",
                                border: "1px solid #000000",
                                boxSizing: "border-box",
                                borderRadius: "5px",
                                display: "flex",
                                flexFlow: "row wrap",
                              }}
                            >
                              <Row
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{ margin: "auto", fontSize: "40px" }}
                                >
                                  <label
                                    style={{
                                      minWidth: "60px",
                                      display: "inline-block",
                                      fontWeight: "700",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <InputCSS>
                                        <input
                                          id="txt"
                                          type="text"
                                          className="slider_num"
                                          value={this.state.slider.value}
                                          style={{
                                            border: "none",
                                            color: "black",
                                            background: "#D4EDDA",
                                            // minWidth: "10px",
                                            textAlign: "center",
                                            paddingTop: "35px",
                                            width: "15%",
                                          }}
                                          onChange={this.onSliderInputChange}
                                        />
                                      </InputCSS>
                                    </p>
                                  </label>
                                </div>
                              </Row>
                              <span style={{ width: "80%" }}>
                                <Slider
                                  style={{
                                    marginBottom: "12px",
                                    marginLeft: "10%",
                                    marginRight: "10%",
                                  }}
                                  value={this.state.slider.value}
                                  min={this.state.slider.min}
                                  max={this.state.slider.max}
                                  step={this.state.slider.step}
                                  onChange={this.onSliderChange}
                                  trackStyle={[
                                    { backgroundColor: "#9aad40" },
                                    { backgroundColor: "green" },
                                  ]}
                                  railStyle={{ backgroundColor: "grey" }}
                                />
                              </span>
                            </div>
                            <label
                              style={{
                                visibility:
                                  this.context && this.context.id
                                    ? "visible"
                                    : "hidden",
                                height:
                                  this.context && this.context.id
                                    ? "auto"
                                    : "0px",
                                marginLeft: "15px",
                                marginTop: ".5rem",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="redeem_credit"
                                value={this.state.redeem_credit}
                                checked={this.state.redeem_credit}
                                onChange={this.onChangeHandle}
                              />
                              &nbsp;&nbsp; Redeem Tree Credit{" "}
                            </label>
                          </Row>

                          {this.state.redeem_credit && [
                            <Row
                              key="redeem_credit"
                              style={{
                                marginRight: "0",
                                marginLeft: "0",
                                width: "100%",
                                marginBottom: "30px",
                              }}
                            >
                              <Col
                                style={{
                                  paddingLeft: "0",
                                  paddingRight: "0",
                                }}
                              >
                                <Row
                                  style={{
                                    // position: "absolute",
                                    width: "90%",
                                    height: "55px",
                                    left: "49px",
                                    top: "566px",
                                    background: "#D4EDDA",
                                    border: "1px solid #000000",
                                    boxSizing: "border-box",
                                    borderRadius: "5px",
                                    marginLeft: "0px",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                    // justify-content: space-between;
                                  }}
                                >
                                  <span
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                      fontSize: "1.25rem",
                                      marginLeft: "1rem",
                                    }}
                                  >
                                    Tree Credits
                                  </span>
                                  <span
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                      marginLeft: "1rem",
                                      marginRight: "2rem",
                                      fontSize: "36px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {this.state.num_credits_redeemed}
                                  </span>
                                  <ButtonToolbar
                                    style={{
                                      flexDirection: "column",
                                      position: "absolute",
                                      marginLeft: "91%",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        height: "25px",
                                        borderTopWidth: "0px",
                                        paddingTop: "0px",
                                        borderBottomWidth: "0px",
                                        fontWeight: "bold",
                                        lineHeight: "22px",
                                        paddingBottom: "2px",
                                      }}
                                      onClick={() => {
                                        if (
                                          this.context.tree_credits >
                                          this.state.num_credits_redeemed
                                        ) {
                                          this.setState({
                                            num_credits_redeemed:
                                              this.state.num_credits_redeemed +
                                              1,
                                          });
                                        }
                                      }}
                                    >
                                      <span>+</span>
                                    </Button>
                                    <Button
                                      style={{
                                        marginTop: "5px",
                                        height: "25px",
                                        borderTopWidth: "0px",
                                        paddingTop: "0px",
                                        borderBottomWidth: "0px",
                                        fontWeight: "bold",
                                        lineHeight: "22px",
                                        paddingBottom: "2px",
                                      }}
                                      onClick={() => {
                                        if (
                                          0 < this.state.num_credits_redeemed
                                        ) {
                                          this.setState({
                                            num_credits_redeemed:
                                              this.state.num_credits_redeemed -
                                              1,
                                          });
                                        }
                                      }}
                                      variant="secondary"
                                    >
                                      -
                                    </Button>
                                  </ButtonToolbar>
                                </Row>
                                <div
                                  style={{
                                    marginTop: "4px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  👋 Hi{" "}
                                  {this.context &&
                                    this.context.display_name.split(" ")[0]}
                                  , you have{" "}
                                  <strong>
                                    {this.context && this.context.tree_credits}{" "}
                                    credit
                                  </strong>{" "}
                                  in your account
                                </div>
                                <br />

                                <Row
                                  style={{
                                    width: "90%",
                                    height: "55px",
                                    left: "49px",
                                    top: "566px",
                                    background: "#ADD218",
                                    border: "1px solid #000000",
                                    boxSizing: "border-box",
                                    borderRadius: "5px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                      fontSize: "1.25rem",
                                      marginLeft: "1px",
                                      justifyContent: "space-between",
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <strong
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        fontSize: "1.25rem",
                                        marginLeft: "1rem",
                                      }}
                                    >
                                      Total trees planted
                                    </strong>
                                    <span
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginLeft: "1rem",
                                        marginRight: "2rem",
                                        fontSize: "36px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {total_trees_gift}
                                    </span>
                                  </span>
                                </Row>
                              </Col>
                            </Row>,
                          ]}
                          <Row
                            style={{ width: "-webkit-fill-available" }}
                            className=""
                          >
                            <Col>
                              <ButtonToolbar
                                style={{ marginTop: "15px", width: "90%" }}
                              >
                                {Number(this.state.slider.value) >= 1 ? (
                                  <Button
                                    onClick={() => {
                                      ReactButtonHandler(
                                        "Button 2 - Quantity",
                                        this.state.time_taken
                                      );

                                      if (this.state.slider.value === 0) {
                                        toast.warn(
                                          "Please select the quantity you want"
                                        );
                                      } else {
                                        if (
                                          this.state.time_taken_breakdown[
                                            "step_2"
                                          ] === ""
                                        ) {
                                          let time_taken_breakdown = this.state
                                            .time_taken_breakdown;

                                          time_taken_breakdown[
                                            "step_2"
                                          ] = this.getTimeDiff(
                                            this.state.time_taken
                                          );

                                          this.setState({
                                            current_step:
                                              this.state.current_step + 1,
                                            time_taken_breakdown,
                                          });
                                        } else {
                                          this.setState({
                                            current_step:
                                              this.state.current_step + 1,
                                          });
                                        }
                                      }
                                    }}
                                    variant="primary"
                                    size="lg"
                                    block
                                    active
                                  >
                                    Next
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      ReactButtonHandler(
                                        "Button 2 - [Disabled] Quantity",
                                        this.state.time_taken
                                      );

                                      if (this.state.slider.value === 0) {
                                        toast.warn(
                                          "Please select the quantity you want"
                                        );
                                      } else {
                                        this.setState({
                                          current_step:
                                            this.state.current_step + 1,
                                        });
                                      }
                                    }}
                                    variant="primary"
                                    size="lg"
                                    block
                                    active
                                    disabled
                                  >
                                    Next
                                  </Button>
                                )}

                                <Button
                                  onClick={() => {
                                    ReactButtonHandler(
                                      "Button 2 [Back] - Quantity",
                                      this.state.time_taken
                                    );

                                    this.setState({
                                      current_step: this.state.current_step - 1,
                                    });
                                  }}
                                  variant="secondary"
                                  size="lg"
                                  block
                                  active
                                >
                                  Back
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Row>
                      )}

                      {/* Step 2 - Write Message */}
                      {this.state.current_step === 2 && (
                        <Row style={{ marginTop: "20px" }}>
                          <div
                            style={{ fontSize: "2.5rem", marginLeft: "-5px" }}
                          >
                            {" "}
                            Gift details{" "}
                          </div>
                          <Form
                            onSubmit={handleSubmit}
                            style={{ width: "100%" }}
                          >
                            <Form.Group controlId="formGroupMessage">
                              <Form.Row>
                                <Form.Label>
                                  <strong>Your name</strong> (required)
                                </Form.Label>
                                <Form.Control
                                  required
                                  value={buyer_name}
                                  name="buyer_name"
                                  onChange={this.onChangeHandle}
                                  placeholder="Enter name"
                                  autoComplete="name"
                                  isInvalid={!this.isName("buyer_name")}
                                />
                                {!this.isName("buyer_name") && (
                                  <p className="invalid-feedback">
                                    Are you sure this is your name?
                                  </p>
                                )}
                              </Form.Row>
                              {this.isName("buyer_name") && <br />}
                              <Form.Row>
                                <Form.Label>
                                  <strong>Your email</strong> (required)
                                </Form.Label>
                                <Form.Control
                                  required
                                  value={buyer_email}
                                  name="buyer_email"
                                  onChange={this.onChangeHandle}
                                  placeholder="Enter email"
                                  autoComplete="email"
                                  isInvalid={this.isHotmail("buyer_email")}
                                />
                                {this.isHotmail("buyer_email") && (
                                  <p className="invalid-feedback">
                                    Sorry
                                    {this.state.buyer_name !== ""
                                      ? " " + this.state.buyer_name
                                      : ""}
                                    ! Microsoft's hotmail/live addresses don't
                                    receive our emails at the moment. We're
                                    working on solving this issue as we speak.
                                  </p>
                                )}
                              </Form.Row>
                              {!this.isHotmail("buyer_email") && <br />}
                              <Form.Row>
                                <Form.Label>
                                  <strong>Message you want to leave</strong>{" "}
                                  (required)
                                </Form.Label>
                                <Form.Control
                                  style={{
                                    height: "100px",
                                  }}
                                  required
                                  value={message}
                                  name="message"
                                  onChange={this.onChangeHandle}
                                  as="textarea"
                                  placeholder={"Enter message"}
                                />
                              </Form.Row>

                              <label style={{ marginTop: ".5rem" }}>
                                <input
                                  type="checkbox"
                                  name="themed_gift"
                                  value={this.state.themed_gift}
                                  checked={this.state.themed_gift}
                                  onChange={this.onChangeHandle}
                                />
                                &nbsp;&nbsp; Make this a themed gift
                              </label>
                            </Form.Group>
                          </Form>

                          <Row
                            style={{ width: "-webkit-fill-available" }}
                            className=""
                          >
                            <Col>
                              <ButtonToolbar
                                style={{
                                  marginTop: "15px",
                                  display: "block",
                                }}
                              >
                                {this.state.message === "" ||
                                this.state.buyer_email === "" ||
                                this.state.buyer_name === "" ||
                                this.isHotmail("buyer_email") ||
                                !this.isName("buyer_name") ? (
                                  <Button
                                    disabled
                                    variant="primary"
                                    size="lg"
                                    block
                                    active
                                    onClick={() => {
                                      ReactButtonHandler(
                                        "Button 3 - [Disabled] Gift Message",
                                        this.state.time_taken
                                      );
                                    }}
                                  >
                                    Next
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      ReactButtonHandler(
                                        "Button 3 - Gift Message",
                                        this.state.time_taken
                                      );

                                      if (
                                        this.state.time_taken_breakdown[
                                          "step_3"
                                        ] === ""
                                      ) {
                                        let time_taken_breakdown = this.state
                                          .time_taken_breakdown;

                                        time_taken_breakdown[
                                          "step_3"
                                        ] = this.getTimeDiff(
                                          this.state.time_taken
                                        );
                                        if (this.state.themed_gift) {
                                          this.setState({
                                            current_step: 2.5,
                                            time_taken_breakdown,
                                          });
                                        } else {
                                          this.setState({
                                            current_step:
                                              this.state.current_step + 1,
                                            time_taken_breakdown,
                                          });
                                        }
                                      } else {
                                        if (this.state.themed_gift) {
                                          this.setState({
                                            current_step: 2.5,
                                          });
                                        } else {
                                          this.setState({
                                            current_step:
                                              this.state.current_step + 1,
                                          });
                                        }
                                      }
                                    }}
                                    variant="primary"
                                    size="lg"
                                    block
                                    active
                                  >
                                    Next
                                  </Button>
                                )}
                                <Button
                                  onClick={() => {
                                    ReactButtonHandler(
                                      "Button 3 [Back] - Gift Message",
                                      this.state.time_taken
                                    );
                                    this.setState({
                                      current_step: this.state.current_step - 1,
                                    });
                                  }}
                                  variant="secondary"
                                  size="lg"
                                  block
                                  active
                                >
                                  Back
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Row>
                      )}

                      {/* STEP 2.5 - Selecting themed gifts */}
                      {this.state.current_step === 2.5 &&
                        this.state.themed_gift && (
                          <Row>
                            <div
                              style={{
                                fontSize: "2.5rem",
                                marginTop: "20px",
                                marginLeft: "-5px",
                              }}
                            >
                              Gift themes{" "}
                            </div>
                            {/* <div>More themes coming soon</div> */}
                            <ThemeCardCSS
                              style={{
                                display: "flex",
                                flexFlow: "wrap",
                                justifyContent: "space-evenly",
                                height: "450px",
                                overflowY: "scroll",
                                backgroundColor: "beige",
                              }}
                            >
                              <label className="mt-3">
                                <input
                                  type="radio"
                                  name="demo"
                                  className="card-input-element d-none"
                                  value="demo3"
                                  checked={
                                    this.state.selected_1 === "birthday_1"
                                  }
                                  onChange={() => {}}
                                />
                                <div
                                  style={{
                                    height: "125px",
                                    width: "350px",
                                    backgroundImage: `url(${birthday_1})`,
                                    backgroundSize: "cover",
                                    border: "solid",
                                    borderColor:
                                      this.state.gift_cover === "birthday_1"
                                        ? "#d29d00"
                                        : "black",
                                    borderWidth:
                                      this.state.gift_cover === "birthday_1"
                                        ? "thick"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (
                                      this.state.gift_cover === "birthday_1"
                                    ) {
                                      this.setState({ gift_cover: "" });
                                    } else {
                                      this.setState({
                                        gift_cover: "birthday_1",
                                      });
                                    }
                                  }}
                                  className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                                ></div>
                              </label>
                              <label className="mt-3">
                                <input
                                  type="radio"
                                  name="demo"
                                  className="card-input-element d-none"
                                  value="demo1"
                                  checked={
                                    this.state.gift_cover === "christmas_1"
                                  }
                                  onChange={() => {}}
                                />
                                <div
                                  style={{
                                    height: "125px",
                                    width: "350px",
                                    backgroundImage: `url(${christmas_1})`,
                                    backgroundSize: "cover",
                                    border: "solid",
                                    borderColor:
                                      this.state.gift_cover === "christmas_1"
                                        ? "#d29d00"
                                        : "black",
                                    borderWidth:
                                      this.state.gift_cover === "christmas_1"
                                        ? "thick"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (
                                      this.state.gift_cover === "christmas_1"
                                    ) {
                                      this.setState({ gift_cover: "" });
                                    } else {
                                      this.setState({
                                        gift_cover: "christmas_1",
                                      });
                                    }
                                  }}
                                  className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                                ></div>
                              </label>
                              <label className="mt-3">
                                <input
                                  type="radio"
                                  name="demo"
                                  className="card-input-element d-none"
                                  value="demo2"
                                  checked={
                                    this.state.selected_1 === "christmas_2"
                                  }
                                  onChange={() => {}}
                                />
                                <div
                                  style={{
                                    height: "125px",
                                    width: "350px",
                                    backgroundImage: `url(${christmas_2})`,
                                    backgroundSize: "cover",
                                    border: "solid",
                                    borderColor:
                                      this.state.gift_cover === "christmas_2"
                                        ? "#d29d00"
                                        : "black",
                                    borderWidth:
                                      this.state.gift_cover === "christmas_2"
                                        ? "thick"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (
                                      this.state.gift_cover === "christmas_2"
                                    ) {
                                      this.setState({ gift_cover: "" });
                                    } else {
                                      this.setState({
                                        gift_cover: "christmas_2",
                                      });
                                    }
                                  }}
                                  className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                                ></div>
                              </label>
                              <label className="mt-3">
                                <input
                                  type="radio"
                                  name="demo"
                                  className="card-input-element d-none"
                                  value="demo3"
                                  checked={
                                    this.state.selected_1 === "christmas_3"
                                  }
                                  onChange={() => {}}
                                />
                                <div
                                  style={{
                                    height: "125px",
                                    width: "350px",
                                    backgroundImage: `url(${christmas_3})`,
                                    backgroundSize: "cover",
                                    border: "solid",
                                    borderColor:
                                      this.state.gift_cover === "christmas_3"
                                        ? "#d29d00"
                                        : "black",
                                    borderWidth:
                                      this.state.gift_cover === "christmas_3"
                                        ? "thick"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (
                                      this.state.gift_cover === "christmas_3"
                                    ) {
                                      this.setState({ gift_cover: "" });
                                    } else {
                                      this.setState({
                                        gift_cover: "christmas_3",
                                      });
                                    }
                                  }}
                                  className="card card-body bg-light d-flex flex-row justify-content-between align-items-center"
                                ></div>
                              </label>
                            </ThemeCardCSS>

                            <Row
                              style={{ width: "-webkit-fill-available" }}
                              className=""
                            >
                              <Col>
                                <ButtonToolbar
                                  style={{ marginTop: "15px", width: "100%" }}
                                >
                                  {this.state.gift_cover !== "" ? (
                                    <Button
                                      onClick={() => {
                                        if (
                                          this.state.time_taken_breakdown[
                                            "theme_gift"
                                          ] === ""
                                        ) {
                                          let time_taken_breakdown = this.state
                                            .time_taken_breakdown;

                                          time_taken_breakdown[
                                            "theme_gift"
                                          ] = this.getTimeDiff(
                                            this.state.time_taken
                                          );

                                          this.setState({
                                            current_step: 3,
                                            time_taken_breakdown,
                                          });
                                        } else {
                                          this.setState({
                                            current_step: 3,
                                          });
                                        }
                                      }}
                                      variant="primary"
                                      size="lg"
                                      block
                                      active
                                    >
                                      Next
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      size="lg"
                                      block
                                      active
                                      disabled
                                    >
                                      Next
                                    </Button>
                                  )}

                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        current_step: 2,
                                      });
                                    }}
                                    variant="secondary"
                                    size="lg"
                                    block
                                    active
                                  >
                                    Back
                                  </Button>
                                </ButtonToolbar>
                              </Col>
                            </Row>
                          </Row>
                        )}

                      {/* STEP 3 - Optional Scheduling */}
                      {this.state.current_step === 3 && (
                        <Row>
                          <div
                            style={{
                              fontSize: "2.5rem",
                              marginTop: "20px",
                              marginLeft: "-5px",
                            }}
                          >
                            Recipient details{" "}
                          </div>

                          <Form
                            onSubmit={handleSubmit}
                            style={{ width: "100%" }}
                          >
                            <Form.Group
                              key={"formGroupMessage_"}
                              style={{ marginBottom: "0rem" }}
                              controlId="formGroupMessage"
                            >
                              {/* Send now / Select day */}
                              {this.state.themed_gift === false && (
                                <Form.Row key={"recipient_form_no_gift_cover"}>
                                  <Form.Label>
                                    <strong>Their name</strong> (required)
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    value={recipient_name}
                                    name="recipient_name"
                                    onChange={this.onChangeHandle}
                                    placeholder="Enter name"
                                    autoComplete="name"
                                    isInvalid={!this.isName("recipient_name")}
                                  />
                                  {!this.isName("recipient_name") && (
                                    <p className="invalid-feedback">
                                      Are you sure this is their name?
                                    </p>
                                  )}
                                </Form.Row>
                              )}

                              {this.state.themed_gift === true && [
                                <Form.Row key={"selected_theme"}>
                                  <Form.Label>
                                    <strong>Selected theme</strong>
                                  </Form.Label>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "beige",
                                      height: "150px",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "130px",
                                        marginTop: "10px",
                                        boxShadow:
                                          "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      }}
                                      src={this.getSelectedTheme()}
                                    />
                                  </div>
                                </Form.Row>,
                                <Form.Row
                                  key={"recipient_form_gift_cover"}
                                  style={{ marginTop: "15px" }}
                                >
                                  <Col>
                                    <Form.Label>
                                      <strong>Their first name</strong>{" "}
                                      (required)
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      value={first_name}
                                      name="first_name"
                                      onChange={this.onChangeHandle}
                                      autoComplete="first name"
                                      isInvalid={!this.isName("first_name")}
                                      placeholder="First name"
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Label>
                                      <strong>Their last name</strong>{" "}
                                      (optional)
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      value={last_name}
                                      name="last_name"
                                      onChange={this.onChangeHandle}
                                      autoComplete="last name"
                                      isInvalid={!this.isName("last_name")}
                                      placeholder="Last name"
                                    />
                                  </Col>
                                </Form.Row>,
                                this.isPhotoTheme(this.state.gift_cover) && [
                                  <Form.Row
                                    key={"upload_photo_row"}
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Col>
                                      <Form.Label>
                                        <strong>
                                          Upload photo of gift recipient
                                        </strong>
                                      </Form.Label>
                                    </Col>
                                  </Form.Row>,
                                  <Croppie
                                    cover_image={this.state.cover_image}
                                    handleImageUpload={this.handleImageUpload}
                                  />,
                                ],
                              ]}
                              <label style={{ marginTop: ".5rem" }}>
                                <input
                                  type="checkbox"
                                  name="send_email"
                                  value={this.state.send_email}
                                  checked={this.state.send_email}
                                  onChange={this.onChangeHandle}
                                />
                                &nbsp;&nbsp; I want to send this gift by email
                              </label>
                              <br />
                              {this.state.send_email && [
                                <React.Fragment key={1}>
                                  <Form.Row>
                                    <Form.Label>
                                      <strong>Their email address</strong>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      value={recipient_email}
                                      name="recipient_email"
                                      onChange={this.onChangeHandle}
                                      placeholder="Enter email"
                                      type="email"
                                      autoComplete="email"
                                      isInvalid={this.isHotmail(
                                        "recipient_email"
                                      )}
                                    />
                                    {this.isBuyerEmailEqualRecipientEmail() && (
                                      <p
                                        style={{
                                          color: "green",
                                          width: "100%",
                                          marginTop: ".25rem",
                                          fontSize: "80%",
                                        }}
                                      >
                                        Hi {this.state.buyer_name}! 👋 Just
                                        letting you know that this email is the
                                        same as yours. If this is a mistake,
                                        make sure to change it!
                                      </p>
                                    )}
                                    {this.isHotmail("recipient_email") && (
                                      <p className="invalid-feedback">
                                        Sorry {this.state.buyer_name}!
                                        Microsoft's hotmail/live addresses don't
                                        receive our emails at the moment. We're
                                        working on solving this issue as we
                                        speak.
                                      </p>
                                    )}
                                  </Form.Row>
                                  <label style={{ marginTop: ".5rem" }}>
                                    <input
                                      type="checkbox"
                                      name="schedule"
                                      value={this.state.schedule}
                                      checked={this.state.schedule}
                                      onChange={this.onChangeHandle}
                                    />
                                    &nbsp;&nbsp; I want to schedule this email
                                    (otherwise it will be sent immediately)
                                  </label>
                                  {this.state.schedule && [
                                    <React.Fragment key={"datetobesent"}>
                                      <Form.Row>
                                        <Form.Label>
                                          <strong>Date to be sent</strong>{" "}
                                          (dd/mm/yy)
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          value={recipient_send_date}
                                          name="recipient_send_date"
                                          onChange={this.onChangeHandle}
                                          placeholder="dd/mm/yy"
                                          isInvalid={
                                            !this.isValidDate(
                                              recipient_send_date
                                            ) && recipient_send_date.length >= 8
                                          }
                                        />
                                        {!this.isValidDate(
                                          recipient_send_date
                                        ) &&
                                          recipient_send_date.length >= 8 && (
                                            <p className="invalid-feedback">
                                              Please enter date in the correct
                                              format (i.e 3rd of June 2020 is
                                              03/06/20)
                                            </p>
                                          )}
                                      </Form.Row>
                                      <Form.Text className="text-muted">
                                        Email will be sent at 10AM EST to{" "}
                                        {recipient_email === ""
                                          ? "their email"
                                          : `${recipient_email}`}
                                        .
                                      </Form.Text>
                                    </React.Fragment>,
                                  ]}
                                </React.Fragment>,
                              ]}
                            </Form.Group>
                          </Form>

                          <Row
                            style={{ width: "-webkit-fill-available" }}
                            className=""
                          >
                            <Col>
                              <ButtonToolbar
                                style={{
                                  marginTop: "15px",
                                  display: "block",
                                }}
                              >
                                {(this.state.themed_gift &&
                                  this.state.first_name === "") ||
                                (!this.state.themed_gift &&
                                  this.state.recipient_name === "") ||
                                (!this.state.themed_gift &&
                                  !this.isName("recipient_name")) ||
                                (this.state.send_email === true &&
                                  (this.state.recipient_email === "" ||
                                    this.isHotmail("recipient_email"))) ||
                                (!this.isValidDate(recipient_send_date) &&
                                  recipient_send_date.length >= 8) ||
                                (this.state.schedule &&
                                  recipient_send_date.length < 8) ? (
                                  <Button
                                    disabled
                                    variant="primary"
                                    size="lg"
                                    block
                                    active
                                    onClick={() =>
                                      ReactButtonHandler(
                                        "Button 3 - [Disabled] Gift Message",
                                        this.state.time_taken
                                      )
                                    }
                                  >
                                    Gift
                                  </Button>
                                ) : (
                                  <StripeCheckout
                                    style={{
                                      width: "100%",
                                      marginBottom: "8px",
                                      background: "none!important",
                                      backgroundImage: "none!important",
                                    }}
                                    allowRememberMe={false}
                                    image="https://www.openmoji.org/data/color/svg/1F333.svg" // the pop-in header image (default none)
                                    currency="USD"
                                    token={this.onToken}
                                    amount={this.state.slider.value * 100}
                                    name="Thank You Trees"
                                    label="Gift"
                                    stripeKey={config.stripe}
                                    allowRememberMe={false}
                                    panelLabel="{{amount}} (USD)"
                                  >
                                    <Button
                                      variant="primary"
                                      size="lg"
                                      block
                                      style={{
                                        width: "100%",
                                        marginBottom: ".5rem",
                                      }}
                                      active
                                      onClick={() => {
                                        ReactButtonHandler(
                                          "Button 4 - [Enabled] Gift",
                                          this.state.time_taken
                                        );

                                        if (
                                          this.state.time_taken_breakdown[
                                            "step_4"
                                          ] === ""
                                        ) {
                                          let time_taken_breakdown = this.state
                                            .time_taken_breakdown;

                                          time_taken_breakdown[
                                            "step_4"
                                          ] = this.getTimeDiff(
                                            this.state.time_taken
                                          );

                                          this.setState({
                                            time_taken_breakdown,
                                          });
                                        }
                                      }}
                                    >
                                      Gift
                                    </Button>
                                  </StripeCheckout>
                                )}

                                <Button
                                  onClick={() => {
                                    ReactButtonHandler(
                                      "Button 4 [Back] - Share",
                                      this.state.time_taken
                                    );

                                    if (this.state.themed_gift === false) {
                                      this.setState({
                                        current_step:
                                          this.state.current_step - 1,
                                      });
                                    } else if (
                                      this.state.themed_gift === true
                                    ) {
                                      this.setState({
                                        current_step:
                                          this.state.current_step - 0.5,
                                      });
                                    }
                                  }}
                                  variant="secondary"
                                  size="lg"
                                  block
                                  active
                                >
                                  Back
                                </Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        </Row>
                      )}
                    </Container>
                  ) : (
                    <div
                      style={{
                        height: "500px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#155724",
                          backgroundColor: "#d4edda",
                          borderColor: "#c3e6cb",
                          padding: "25px",
                        }}
                      >
                        <h1> Congratulations! 🎉</h1>
                        <p>
                          {" "}
                          You've planted{" "}
                          {total_trees_gift === 1
                            ? `a tree${
                                this.state.recipient_name === ""
                                  ? ""
                                  : ` for ${this.state.recipient_name}`
                              }! 🎁`
                            : `${total_trees_gift} trees${
                                this.state.recipient_name === ""
                                  ? ""
                                  : ` for ${this.state.recipient_name}`
                              }! 🎁`}
                        </p>
                        <ButtonToolbar
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Button
                            style={{ width: "40%" }}
                            variant="success"
                            href={this.state.referral_url}
                            target="_blank"
                          >
                            Your gift
                          </Button>
                        </ButtonToolbar>
                      </span>
                    </div>
                  )}
                </Col>

                <Col id="home-forest-vis" className="world-column">
                  <World>
                    <Row
                      style={{
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <Toggle>
                        <div
                          className={
                            this.state.scrollable_world
                              ? "Scrollable-world"
                              : "Not-scrollable-world"
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            onClick={ReactButtonHandler(
                              `Button - Navigate World ${window.location.pathname}`,
                              this.state.time_taken
                            )}
                            className="button button3"
                            style={{
                              borderRadius: "10px",
                              margin: "auto",
                            }}
                          >
                            <img
                              alt=""
                              style={{ width: "25px" }}
                              src={left_arrow}
                            />{" "}
                            &nbsp;
                            <span>Select a country</span>
                          </span>
                        </div>
                      </Toggle>
                      <div
                        width="100%"
                        height="100%"
                        truncheight={this.state.truncHeight}
                        foliagedensity={this.state.foliageDensity}
                        quantity={
                          this.state.world_quant > 30
                            ? 30
                            : this.state.world_quant
                        }
                        country={this.state.selected_1}
                        id="worlds"
                      />
                    </Row>
                    {/* {console.log("STATE: ", this.state)} */}
                    <Row
                      style={{
                        height: "0px",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <img
                        alt=""
                        style={{
                          width: "20%",
                          position: "relative",
                          bottom: "100px",
                          justifyContent: "flex-end",
                        }}
                        src={world_legend}
                      />{" "}
                    </Row>
                    <Row>
                      <Col
                        style={{ textAlign: "center" }}
                        className="text-muted world-column"
                      >
                        Interact with your gift in the world above.{" "}
                      </Col>
                    </Row>
                  </World>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default GiftingEngine;
