import React, { Component } from "react";
import styled from "styled-components";
import logo from "../assets/logo.jpg";
import gift_icon from '../assets/gift_icon.jpeg'

const PREVIEW_CONTENT = styled.div`
  .section {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    // padding-bottom: 5.625em;
  }
  .page-width {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    width: 750px;
  }

  .preview.preview--bordered {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
  }

  .preview__content {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    // padding-top: 2.8125em;
    padding-right: 1.875em;
    padding-left: 1.875em;
    padding-bottom: 2.8125em;
  }

  .pdf-container {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    background: #ffffff;
  }

  .pdf-block.certificate {
    height: 350px;
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #dfe3e8;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }

  .certificate__header {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
  }

  .certificate__details {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    margin: 30px 0;
  }

  .certificate__footer {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
  }

  .pdf-section--left {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: left;
  }

  .pdf-section--right {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: right;
    text-align: right;
  }
  .certificate__logo {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    margin-bottom: 15px;
    float: left;
  }

  .pdf-section--left > div > img {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    vertical-align: middle;
    max-width: 100%;
    max-height: 24px;
    background: wheat;
  }

  .pdf-section--left > h5 {
    box-sizing: border-box;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, sans-serif;
    display: block;
    margin-bottom: 0.4615384615em;
    color: #212b35;
    font-size: 0.8125em;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-left: 30px;
    line-height: 24px;
  }

  .pdf-section--right > h5 {
    text-align: right;
    box-sizing: border-box;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, sans-serif;
    display: block;
    margin-bottom: 0.4615384615em;
    color: #212b35;
    font-size: 0.8125em;
    line-height: 24px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  .pdf-section--left.certificate__properties {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: left;
    width: 66.6666666667%;
  }

  .certificate__gift-image.pdf-section--left {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: left;
    width: 33.3333333333%;
    padding-left: 30px;
    padding-top: 15px;
  }

  .certificate__gift-image.pdf-section--left > img {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    vertical-align: middle;
    max-width: 100%;
  }

  .inline-property {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
  }

  .inline-property__label {
    box-sizing: border-box;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #212b35;
    line-height: 1.2;
    font-weight: 700;
    font-family: ShopifySans, Helvetica, Arial, sans-serif;
    font-size: 1.25em;
    margin-bottom: 0.9em;
    float: left;
  }

  .inline-property__value {
    box-sizing: border-box;
    margin-top: 0;
    -webkit-font-smoothing: antialiased;
    display: block;
    color: #212b35;
    line-height: 1.2;
    font-weight: 700;
    font-family: ShopifySans, Helvetica, Arial, sans-serif;
    font-size: 1.25em;
    margin-bottom: 0.9em;
    border-bottom: 1px solid #dfe3e8;
    margin-left: 80px;
  }

  .certificate__footer > .pdf-section--left {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: left;
  }

  .certificate__footer > .pdf-section--right {
    -webkit-font-smoothing: antialiased;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #637381;
    font-weight: 400;
    box-sizing: border-box;
    float: right;
    text-align: right;
  }
`;

let locationIndex = {
  nepal: "Chitwan National Park",
  madagascar: "Ankarafantsika National Park",
  haiti: "Providence University",
};
class GiftCertificate extends Component {
  state = {};

  render() {
    // console.log("{this.props.gift_id}: ", this.props.gift_id);
    return (
      <PREVIEW_CONTENT className="print_cert_div">
        <section className="section">
          <div
            id="print_cert"
            style={{
              display: "none",
            }}
            className="page-width"
          >
            <div className="preview preview--bordered">
              <div className="preview__content">
                <div className="pdf-container">
                  <div className="pdf-block certificate">
                    <div className="certificate__header">
                      <div
                        style={{
                          width: "50%",
                        }}
                        className="pdf-section--left"
                      >
                        <div className="certificate__logo">
                          <img
                            alt="logo"
                            src={logo}
                            style={{ background: "white" }}
                          />
                        </div>

                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "0.8125em",
                            textTransform: "uppercase",
                            boxSizing: "border-box",
                            marginTop: "0",
                            WebkitFontSmoothing: "antialiased",
                            fontFamily:
                              "ShopifySans,Helvetica,Arial,sans-serif",
                            display: "block",
                            marginBottom: "0.4615384615em",
                            color: "#212b35",
                            letterSpacing: "0.2em",
                            marginLeft: "30px",
                            lineHeight: "24px",
                          }}
                          className="company-name"
                        >
                          Thank You Trees
                        </div>
                      </div>

                      <div className="pdf-section--right">
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "0.8125em",
                            textTransform: "uppercase",
                            boxSizing: "border-box",
                            marginTop: "0",
                            WebkitFontSmoothing: "antialiased",
                            fontFamily:
                              "ShopifySans,Helvetica,Arial,sans-serif",
                            display: "block",
                            marginBottom: "0.4615384615em",
                            color: "#212b35",
                            letterSpacing: "0.2em",
                            marginLeft: "30px",
                            lineHeight: "24px",
                          }}
                        >
                          Gift certificate
                        </div>
                      </div>
                    </div>

                    <div className="certificate__details">
                      <div className="pdf-section--left certificate__properties">
                        <div className="inline-property">
                          <h3 className="inline-property__label">
                            <strong>Planted:&nbsp;</strong>
                          </h3>
                          <h3 className="inline-property__value">
                            {" "}
                            {this.props.quantity
                              ? this.props.quantity === "1"
                                ? "1 tree"
                                : this.props.quantity + " trees"
                              : "loading"}{" "}
                          </h3>
                        </div>
                        <div className="inline-property">
                          <h3 className="inline-property__label">
                            <strong>Location: &nbsp;</strong>
                          </h3>
                          <h3 className="inline-property__value">
                            {this.props.location
                              ? locationIndex[this.props.location]
                              : "loading"}
                          </h3>
                        </div>
                        <div className="inline-property">
                          <h3 className="inline-property__label">
                            <strong>Country:&nbsp;</strong>
                          </h3>
                          <h3 className="inline-property__value">
                            {this.props.country
                              ? this.props.country
                              : "loading"}
                          </h3>
                        </div>

                        <div className="inline-property">
                          <h3 className="inline-property__label">
                            <strong>For:&nbsp;</strong>
                          </h3>
                          <h3 className="inline-property__value">
                            {this.props.to ? this.props.to : "loading"}
                          </h3>
                        </div>
                        <div className="inline-property">
                          <h3 className="inline-property__label">
                            <strong>By:&nbsp;</strong>
                          </h3>
                          <h3 className="inline-property__value">
                            {this.props.by ? this.props.by : "loading"}
                          </h3>
                        </div>
                      </div>

                      <div className="certificate__gift-image pdf-section--left">
                        <img
                          alt="gift logo"
                          src={gift_icon}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                      className="certificate__footer"
                    >
                      <p
                        style={{
                          margin: "auto",
                          backgroundColor: "beige",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          color: "green",
                          fontWeight: "600",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          fontSize: "15px",
                        }}
                      >
                        {`thankyoutrees.io/gifts/${this.props.gift_id}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PREVIEW_CONTENT>
    );
  }
}

export default GiftCertificate;
