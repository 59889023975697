import React, { Component } from "react";
import gift_box from "../../assets/your_gifts_icon.png";
import { UserContext } from "../../Providers/UserProvider";
import dayjs from "dayjs";

class YourGifts extends Component {
  static contextType = UserContext;

  state = {};

  render() {
    return (
      <>
        {" "}
        <h3>Latest gifts </h3>
        <hr
          style={{
            height: "3px",
            backgroundColor: "#e5e5e5",
          }}
        />
        <div>
          {this.props.purchased_gifts.slice(0, 3).length == 0
            ? "No gifts have been purchased yet."
            : this.props.purchased_gifts
                .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
                .slice(0, 3)
                .map((gift, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      paddingTop: idx > 0 ? "10px" : "",
                      // justifyContent: "center",
                    }}
                  >
                    {" "}
                    {/* Left side content - image */}
                    <span>
                      {gift.id === "#" ? (
                        <span>
                          {" "}
                          <img
                            style={{
                              width: "50px",
                            }}
                            src={gift_box}
                          />
                        </span>
                      ) : (
                        <a
                          style={{
                            textDecoration: "none",
                          }}
                          target="_blank"
                          href={`/gifts/${gift.id}`}
                        >
                          <img
                            style={{
                              width: "50px",
                            }}
                            src={gift_box}
                          />
                        </a>
                      )}
                    </span>{" "}
                    {/* Right side content */}
                    <span
                      style={{
                        display: "flex",
                        flexFlow: "column",
                        paddingLeft: "10px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                        }}
                      >
                        {gift.id === "#" ? (
                          <span
                            style={{
                              color: "#212529",
                              textDecoration: "none",
                            }}
                          >
                            {gift.recipient_name}
                          </span>
                        ) : (
                          <a
                            style={{
                              color: "#212529",
                              textDecoration: "none",
                            }}
                            target="_blank"
                            href={`/gifts/${gift.id}`}
                          >
                            {gift.recipient_name}
                          </a>
                        )}
                      </span>{" "}
                      <span>
                        {" "}
                        {gift.location.charAt(0).toUpperCase() +
                          gift.location.slice(1)}{" "}
                        - {dayjs(gift.createdAt.toDate()).fromNow()}
                      </span>
                    </span>
                  </div>
                ))}
        </div>
      </>
    );
  }
}
export default YourGifts;
