export const config = {
  firebase: {
    apiKey: "AIzaSyCkRxiZZQqlt5WSCWsD71lxjXYKcVCfXRI",
    authDomain: "tree-gift-project.firebaseapp.com",
    databaseURL: "https://tree-gift-project.firebaseio.com",
    projectId: "tree-gift-project",
    storageBucket: "tree-gift-project.appspot.com",
    messagingSenderId: "187198608456",
    appId: "1:187198608456:web:58316c1f60d6d1e9b8747d",
  },
};
