import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";

class Messages extends Component {
  render() {
    return (
      <>
        <section className="py-5">
          <Container>
            <h1 className="font-weight-light">
              Examples to help you write great messages
            </h1>
            <h3 style={{ marginTop: "1.5em" }}> Mother's Day messages </h3>
            <hr />
            <Card border="info" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Mother's Day Message 1</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Happy Mother's day Mom!
                  <br />
                  To my Mom, my best friend, and my biggest cheerleader - Happy
                  Mother's day!
                  <br />
                  Raising me took a lot of patience, and I want to thank you for
                  hanging in there with me.
                  <br />
                  Sending you a forest of pure love, literally! I planted 22
                  Mangrove trees in Madagascar for every year you've supported
                  me. Thank you for helping me grow into the person I am proud
                  of being today, and for everything you have done for me.
                  <br />
                  I wish you a magical day Mom,
                  <br />
                  John
                </Card.Text>
              </Card.Body>
            </Card>

            <br />
            <Card border="info" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Happy Mother's Day Message 2</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Dear Mom,
                  <br />
                  Yasmina and I are celebrating Mother's day by planting 35
                  trees in Nepal in your name! We know you've been wanting to
                  travel to South East Asia for a while, and you care about the
                  environment.
                  <br />
                  Many of these trees will grow to be a mother tree one day, to
                  support seedlings as they become a forest. We thought we could
                  give back to mother nature to thank you, in a different way,
                  for making us better listeners and more empathetic people.
                  <br />
                  You're missed dearly during this special day, but we're
                  holding you close in our hearts and hoping it’s a happy one
                  for you.
                  <br />
                  Lots of hugs and kisses,
                  <br />
                  Yasmina and Noura
                </Card.Text>
              </Card.Body>
            </Card>

            <h3 style={{ marginTop: "1.5em" }}> Birthday messages </h3>
            <hr />
            <Card border="success" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Birthday Message 1</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Happy birthday Lina!
                  <br />
                  Sending you a forest of love (literally!).
                  <br />
                  Since you're turning 25 today, I planted 25 Mangrove trees in
                  Madagascar in your name.
                  <br />
                  Wishing you a fabulous day,
                  <br />
                  Fady
                </Card.Text>
              </Card.Body>
            </Card>

            <br />
            <Card border="success" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Birthday Message 2</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Dear Anas,
                  <br />
                  I wish you a healthy and beautiful day with your friends and
                  family.
                  <br />
                  Arthur and I planted 21 trees in Nepal since you are turning
                  21 today, and you've been planning on visiting South East Asia
                  for a while.
                  <br />
                  Have a great day,
                  <br />
                  Zarah
                </Card.Text>
              </Card.Body>
            </Card>

            <h3 style={{ marginTop: "1.5em" }}> Thank you messages </h3>
            <hr />
            <Card border="warning" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Thank You Message 1</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Hey Lindi,
                  <br />
                  I planted a couple of trees in your name in Haiti as a way of
                  saying thank you for helping me out with my resume and
                  answering all my simple questions.
                  <br />
                  I found your tips on polishing my resume very helpful, and I
                  look forward to updating you on how my job hunting goes.
                  <br />
                  Thank you for your time!
                  <br />
                  Anwar
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
            <Card border="warning" style={{ width: "100%" }}>
              <Card.Header>
                <strong>Thank You Message 2</strong>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Hey Emily,
                  <br />
                  Thank you for taking the time to chat with me and my class
                  today about your work at FriendlyEggs.
                  <br />
                  We learnt a lot about story telling through different channels
                  and how to efficiently use video to support our messages.
                  <br />
                  My class and I decided planted a couple of trees in Madagascar
                  in your name as our way of saying thank you.
                  <br />
                  Have a great day,
                  <br />
                  Sarah
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </section>
      </>
    );
  }
}

export default Messages;
