/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { Button, ButtonGroup, Col, Container, Form } from "react-bootstrap";
import treeSVG from "../assets/tree_summary.svg";
import peopleSVG from "../assets/people_summary.svg";
import earthSVG from "../assets/earth_summary.svg";
import co2_summary from "../assets/co2_summary.png";
import heart_summary from "../assets/heart_summary.png";
import flight_summary from "../assets/flight_summary.png";
import styled from "styled-components";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import Croppie from "./Croppie.jsx";
import { UserContext } from "../Providers/UserProvider";

const MobileColumnPading = styled.div`
  @media screen and (max-width: 600px) {
    padding-bottom: 16px;
  }
`;

const BottomMobileColumnPading = styled.div`
  @media screen and (max-width: 600px) {
    padding-top: 10px !important;
    padding-bottom: 20px;
  }
`;

const RemovingDropdownToggle = styled.div`
  .dropdown-toggle::after {
    display: none;
  }
`;

const getHeight = (gift) => {
  if (Object.keys(gift).length === 0) {
    return 0;
  }
  let created_at = gift.createdAt;
  let now = dayjs();
  let num_months = now.diff(created_at.toDate(), "month", true);
  let height_growth_year = 15; // 15 cm growth every year
  let curr_height = 0;
  for (let i = 4; i < num_months; i += 4) {
    curr_height = ((i / 12) * height_growth_year).toFixed(0); // 5 x 4 OR 5 x 8 ...
    // let date_of_notification = dayjs(created_at.toDate()).add(i, "month");
  }

  return curr_height;
};

// logic here, 308KG/25 year
const months_to_CO2 = (months) => {
  let yearlyCO2 = 308 / 25;
  let tree_years_lived = months / 12;
  return (yearlyCO2 * tree_years_lived).toFixed(0);
};

const real_timeCO2 = (gift) => {
  if (Object.keys(gift).length === 0) {
    return 0;
  }

  let total = 0;
  let now = dayjs();

  let created_at = gift.createdAt;
  let num_months = now.diff(created_at.toDate(), "month", true);
  total = num_months * gift.quantity;

  return months_to_CO2(total);
};

const flight_calc = (sequestration) => {
  return Math.floor(sequestration / 141.3);
};

class GiftSummary extends Component {
  static contextType = UserContext;
  state = {
    user_email: this.context && this.context.email,
  };

  handleImageUpload = (compressed_image) => {
    let user_email =
      this.context && this.context.email ? this.context.email : "";

    if (compressed_image) {
      // if user_id == (buyer_email or recipient_email)
      if (
        this.props.gift.recipient_email === user_email ||
        this.props.gift.buyer_email === user_email
      ) {
        // then update photo

        this.props.uploadImage(compressed_image);
      }
    } else {
      // console.log("NOT ALLOWED");
    }
  };

  render() {
    let supporters = this.props.gift.supporters
      ? this.props.gift.supporters
      : [];

    supporters = supporters.filter((supporter) => supporter.quantity != 0);
    let edit_options = [];

    if (
      this.props.gift.gift_cover === "birthday_1" ||
      this.props.gift.gift_cover === "togetherness"
    ) {
      edit_options.push(
        <React.Fragment key="edit_photo">
          <div>
            <strong>Edit photo</strong>
            <>
              <Croppie
                on_gift_page={true}
                handleImageUpload={this.handleImageUpload}
              />
            </>
          </div>
          <br />
        </React.Fragment>
      );
    }

    if (this.props.gift.gift_cover === "togetherness") {
      edit_options.push(
        <React.Fragment key="edit_name">
          <div>
            <strong>Edit forest name</strong>
            <Form.Control
              style={{
                height: "50px",
                marginBottom: "10px",
              }}
              value={this.props.gift.forest_name}
              name="message"
              onChange={this.props.onChangeForestNameHandle}
              as="textarea"
              placeholder={"Enter message"}
            />
          </div>
          <Button onClick={() => this.props.submitForestName()}>
            Submit forest name
          </Button>
        </React.Fragment>
      );
    }

    let user_email =
      this.context && this.context.email ? this.context.email : "";
    return (
      <Container>
        {this.context &&
          this.context.id &&
          edit_options.length > 0 &&
          (this.props.gift.recipient_email === user_email ||
            this.props.gift.buyer_email === user_email) && (
            <RemovingDropdownToggle>
              <ButtonGroup
                size="lg"
                style={{ width: "100%", paddingBottom: "10px" }}
              >
                <Dropdown as={ButtonGroup} style={{ width: "100%" }}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: "100%", padding: "10px" }}>
                    {edit_options.map((option) => option)}
                  </Dropdown.Menu>
                </Dropdown>
              </ButtonGroup>
            </RemovingDropdownToggle>
          )}
        <MobileColumnPading>
          {" "}
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "#FFE9AF",
              borderRadius: "10px",
              border: "1px solid #000000",
            }}
          >
            <div
              style={{
                paddingTop: "15px",
                display: "flex",
                justifyContent: "center",
                fontSize: "27px",
              }}
            >
              {this.props.forest_page_flag === true
                ? "Real-time forest"
                : "Gift"}{" "}
              summary
            </div>
            {this.props.forest_page_flag === true ? (
              <></>
            ) : (
              <span
                style={{
                  paddingTop: "4px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                }}
              >
                Gift metrics below are updated daily
              </span>
            )}
            <div
              style={{
                paddingLeft: "25px",
                paddingTop: "15px",
                display: "flex",
              }}
            >
              <Col
                lg={1}
                xs={1}
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={treeSVG}
                  style={{
                    width: "40px",
                    height: "auto",
                  }}
                  alt=""
                />
              </Col>
              <Col>
                Forest has reached average height of{" "}
                {getHeight(this.props.gift)} cm
              </Col>
            </div>
            <div
              style={{
                paddingLeft: "25px",
                paddingTop: "15px",
                display: "flex",
              }}
            >
              <Col
                lg={1}
                xs={1}
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={earthSVG}
                  style={{
                    width: "40px",
                    height: "auto",
                  }}
                  alt=""
                />
              </Col>
              <Col>
                {/* contributor #1 */}
                <span>
                  {" "}
                  {/* Either: 
                        Length 2: John Wilson planted 8 more trees
                        Length 3: John Wilson and Lina planted 8 more trees
                        Length 4: John Wilson and 2 more people planted 8 more trees
                        */}
                  {real_timeCO2(this.props.gift)} KG of CO₂ have been
                  sequestered
                </span>
              </Col>
            </div>
            <div
              style={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
                display: "flex",
              }}
            >
              <Col
                lg={1}
                xs={1}
                style={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={flight_summary}
                  style={{
                    width: "40px",
                    height: "auto",
                  }}
                  alt=""
                />
              </Col>
              <Col>
                {flight_calc(real_timeCO2(this.props.gift))} short-haul flights
                offset as of today
              </Col>
            </div>
            {this.props.forest_page_flag === true ? (
              <></>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "20px",
                  paddingTop: "10px",
                }}
              >
                <ButtonGroup size="lg">
                  <Button href={"/profile"} variant="success">
                    View progress
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </div>
        </MobileColumnPading>

        {this.props.forest_page_flag === true ? (
          <></>
        ) : (
          <BottomMobileColumnPading
            style={{
              paddingTop: "10px",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "rgba(229, 235, 200, 0.62)",
                borderRadius: "10px",
                border: "1px solid #000000",
              }}
            >
              <div
                style={{
                  paddingTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "27px",
                }}
              >
                Gift highlights
              </div>
              <div
                style={{
                  paddingLeft: "25px",
                  paddingTop: "15px",
                  display: "flex",
                }}
              >
                <Col
                  lg={1}
                  xs={1}
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={heart_summary}
                    style={{
                      width: "40px",
                      height: "auto",
                    }}
                    alt=""
                  />
                </Col>
                <Col>
                  {(this.props.gift.sender && this.props.gift.sender.name) ||
                    (this.props.gift.buyer_name &&
                      this.props.gift.buyer_name)}{" "}
                  {/* Case for togetherness forests */}
                  {this.props.gift &&
                    this.props.gift.gift_cover === "togetherness" &&
                    `and ${supporters[1]["name"]} have `}
                  planted {this.props.gift.quantity && this.props.gift.quantity}{" "}
                  {this.props.gift.quantity == 0 ? "tree" : "trees"} in{" "}
                  {this.props.gift.location &&
                    this.props.gift.location.charAt(0).toUpperCase() +
                      this.props.gift.location.slice(1)}{" "}
                  {this.props.gift &&
                  this.props.gift.gift_cover === "togetherness"
                    ? ``
                    : `for ${this.props.gift.recipient_name}`}
                </Col>
              </div>
              {this.props.gift &&
                this.props.gift.gift_cover != "togetherness" &&
                supporters &&
                supporters.length >= 2 && (
                  // [0] buyer, [1] supporter_1
                  <div
                    style={{
                      paddingLeft: "25px",
                      paddingTop: "15px",
                      display: "flex",
                    }}
                  >
                    <Col
                      lg={1}
                      xs={1}
                      style={{
                        paddingLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={peopleSVG}
                        style={{
                          width: "40px",
                          height: "auto",
                        }}
                        alt=""
                      />
                    </Col>
                    <Col>
                      {supporters.length > 1 && supporters[1]["name"]}{" "}
                      {/* contributor #1 */}
                      <span>
                        {" "}
                        {/* Either: 
                        Length 2: John Wilson planted 8 more trees
                        Length 3: John Wilson and Lina planted 8 more trees
                        Length 4: John Wilson and 2 more people planted 8 more trees
                        */}
                        {supporters.length === 3 &&
                          `and ${supporters[2]["name"]} `}
                        {supporters.length > 3 &&
                          `and ${supporters.length - 2} more people `}
                      </span>
                      planted{" "}
                      {supporters
                        .slice(1)
                        .reduce(
                          (sum, x) => Number(sum) + Number(x.quantity),
                          0
                        )}{" "}
                      additional trees
                    </Col>
                  </div>
                )}

              <div
                style={{
                  paddingLeft: "25px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  display: "flex",
                }}
              >
                <Col
                  lg={1}
                  xs={1}
                  style={{
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={co2_summary}
                    style={{
                      width: "40px",
                      height: "auto",
                    }}
                    alt=""
                  />
                </Col>
                <Col>
                  {isNaN(this.props.gift.quantity * 308)
                    ? 0
                    : (this.props.gift.quantity * 308).toLocaleString()}{" "}
                  KG of CO₂ to be removed over 25 years
                </Col>
              </div>
            </div>
          </BottomMobileColumnPading>
        )}
      </Container>
    );
  }
}
export default GiftSummary;
