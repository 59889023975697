import React, { Component } from "react";
// tree height
import gift_update_1 from "../../assets/gift_update_1.png";
// co2 sequestration
import gift_update_2 from "../../assets/gift_update_2.png";
// others contribute to your gift
import gift_update_3 from "../../assets/gift_update_3.png";
// you bought a gift
import gift_update_4 from "../../assets/gift_update_4.png";
// you contributed to a gift
import gift_update_5 from "../../assets/gift_update_5.png";
// someone bought you a gift
import gift_update_6 from "../../assets/gift_update_6.png";
// people contributed bought you a gift
// import gift_update_7 from "../../assets/gift_update_7.png";

import dayjs from "dayjs";
import { Col, Form } from "react-bootstrap";

class GiftUpdates extends Component {
  state = {
    notification_type_direction: "all",
    notification_type_detail: "all_notifications",
  };

  newContributionGiftForMeCard = (gift, supporters, date) => {
    return (
      <div
        key={`${date.toDate()} - ${gift.id} - new_contribution`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "#FEF2D7",
          borderColor: "#C68B09",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "10px",
            }}
          >
            <img
              style={{
                height: "40px",
              }}
              src={gift_update_3}
              alt=""
            />
          </span>
          <span>
            {gift.id === "#" ? (
              <span
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {supporters}
              </span>
            ) : (
              <a
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                href={`/gifts/${gift.id}`}
              >
                {supporters}
              </a>
            )}
            contributed to a gift made for you
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(date.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  newContributionGiftForMeNotification = (gift) => {
    let supporters = gift.supporters.slice(1);
    if (supporters.length === 0) {
      return [];
    } else if (supporters.length === 1) {
      return [
        {
          card: this.newContributionGiftForMeCard(
            gift,
            supporters[0].name,
            supporters[0].date
          ),
          date: supporters[0].date.toDate(),
          type: "new_contribution_for_me",
        },
      ];
    } else if (supporters.length === 2) {
      return [
        {
          card: this.newContributionGiftForMeCard(
            gift,
            supporters[0].name + " and " + supporters[1].name,
            supporters[1].date
          ),
          date: supporters[1].date.toDate(),
          type: "new_contribution_for_me",
        },
      ];
    } else if (supporters.length > 2) {
      return [
        {
          card: this.newContributionGiftForMeCard(
            gift,
            supporters[0].name + ` and ${supporters.length - 1} more people`,
            supporters[1].date
          ),
          date: supporters[1].date.toDate(),
          type: "new_contribution_for_me",
        },
      ];
    }
  };

  newGiftForMeCard = (gift) => {
    return (
      <div
        key={`${gift.createdAt.toDate()} - ${gift.id} - new_gift`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "#FFF3E9",
          borderColor: "#D28D67",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "15px",
            }}
          >
            <img
              style={{
                height: "40px",
              }}
              src={gift_update_6}
              alt=""
            />
          </span>
          <span>
            {gift.id === "#" ? (
              <span
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {gift.buyer_name}
              </span>
            ) : (
              <a
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                href={`/gifts/${gift.id}`}
              >
                {gift.buyer_name}
              </a>
            )}{" "}
            made you a thank you gift
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(gift.createdAt.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  newGiftForMeNotification = (gift) => {
    return [
      {
        card: this.newGiftForMeCard(gift),
        date: gift.createdAt.toDate(),
        type: "for_me_gift",
      },
    ];
  };

  newHeightCard = (gift, curr_height, date_of_notification) => {
    return (
      <div
        key={`${date_of_notification} - ${gift.id} - new_height`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          background: "rgba(99, 51, 34, 0.05)",
          borderColor: "#633322",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <img
              style={{
                height: "52px",
              }}
              src={gift_update_1}
              alt=""
            />
          </span>
          <span>
            <a
              style={{
                color: "rgb(33, 37, 41)",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              href={`/gifts/${gift.id}`}
            >
              {gift.type == "for_me" ? gift.buyer_name : gift.recipient_name}'s
              gift
            </a>{" "}
            from{" "}
            {gift.createdAt
              .toDate()
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")}
            , has reached a new height of {curr_height} cm
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {/* 1 day ago */}
          {dayjs(date_of_notification).fromNow()}
        </span>
      </div>
    );
  };

  // 14.71cm/year growth: http://medcraveonline.com/JAMB/JAMB-07-00211.pdf

  // # get num_months
  /**
   *
   * for every 4 months
   *
   */
  newHeightNotifications = (gift) => {
    let arr = [];
    let created_at = gift.createdAt;
    let now = dayjs();
    let num_months = now.diff(created_at.toDate(), "month", true);
    let height_growth_year = 15; // 15 cm growth every year

    for (let i = 4; i < num_months; i += 4) {
      let curr_height = ((i / 12) * height_growth_year).toFixed(0); // 5 x 4 OR 5 x 8 ...
      let date_of_notification = dayjs(created_at.toDate()).add(i, "month");
      arr.push({
        card: this.newHeightCard(gift, curr_height, date_of_notification),
        date: date_of_notification.toDate(),
        type: "new_height",
      });
    }
    return arr;
  };

  newContributionCard = (gift, supporters, date) => {
    return (
      <div
        key={`${date.toDate()} - ${gift.id} - new_contribution`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "#FEF2D7",
          borderColor: "#C68B09",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "15px",
            }}
          >
            <img
              style={{
                height: "40px",
              }}
              src={gift_update_3}
              alt=""
            />
          </span>
          <span>
            <a
              style={{
                color: "rgb(33, 37, 41)",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              href={`/gifts/${gift.id}`}
            >
              {supporters}
            </a>{" "}
            contributed to {gift.recipient_name}’s gift
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(date.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  newContributionNotification = (gift) => {
    let supporters = gift.supporters.slice(1);
    if (supporters.length === 0) {
      return [];
    } else if (supporters.length === 1) {
      return [
        {
          card: this.newContributionCard(
            gift,
            supporters[0].name,
            supporters[0].date
          ),
          date: supporters[0].date.toDate(),
          type: "new_contribution",
        },
      ];
    } else if (supporters.length === 2) {
      return [
        {
          card: this.newContributionCard(
            gift,
            supporters[0].name + " and " + supporters[1].name,
            supporters[1].date
          ),
          date: supporters[1].date.toDate(),
          type: "new_contribution",
        },
      ];
    } else if (supporters.length > 2) {
      return [
        {
          card: this.newContributionCard(
            gift,
            supporters[0].name + ` and ${supporters.length - 1} more people`,
            supporters[1].date
          ),
          date: supporters[1].date.toDate(),
          type: "new_contribution",
        },
      ];
    }
  };

  newSequestrationMilestoneCard = (gift, milestone, date) => {
    return (
      <div
        key={`${date.toDate()} - ${gift.id} - sequestration_milestone`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "#EFF8FF",
          borderColor: "#286D9F",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <img
              style={{
                height: "40px",
              }}
              src={gift_update_2}
              alt=""
            />
          </span>
          <span>
            <a
              style={{
                color: "rgb(33, 37, 41)",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              href={`/gifts/${gift.id}`}
            >
              {gift.type == "for_me" ? gift.buyer_name : gift.recipient_name}’s
              gift
            </a>{" "}
            has sequestered the emissions of {milestone}
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(date.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  // logic here, 308KG/25 year
  months_to_CO2 = (months) => {
    let yearlyCO2 = 308 / 25;
    let tree_years_lived = months / 12;
    return (yearlyCO2 * tree_years_lived).toFixed(0);
  };

  real_timeCO2 = (gift) => {
    let total = 0;
    let now = dayjs();

    let created_at = gift.createdAt;
    let num_months = now.diff(created_at.toDate(), "month", true);
    total = num_months * gift.quantity;
    return this.months_to_CO2(total);
  };

  newSequestrationMilestoneNotification = (gift) => {
    let arr = [];
    let sequestered_carbon = this.real_timeCO2(gift);

    // 3 hour ride, 1 short flight (2 hours), 1 long flight (9 hours)
    // Tool: https://www.icao.int/environmental-protection/Carbonoffset/Pages/default.aspx
    // Short hault flights are any flight under 3 hours (2 hour and 10 mins), YYZ to YYG = 141.3KG
    // Long hault flights are any flight above 3 hours (5 hours), YYZ to vancouver = 251KG

    let milestones = [
      { milestone_name: "1 short-haul flight", carbon_amount: 141.3 },
      { milestone_name: "1 long-haul flight", carbon_amount: 251 },
    ];

    milestones.map((milestone) => {
      if (sequestered_carbon > milestone.carbon_amount) {
        let co2_monthly_rate = (308 / 25) * (1 / 12) * gift.quantity;
        let months = milestone.carbon_amount / co2_monthly_rate;
        let date = dayjs(gift.createdAt.toDate()).add(months, "month");

        arr.push([
          {
            card: this.newSequestrationMilestoneCard(
              gift,
              milestone.milestone_name,
              date
            ),
            date: date.toDate(),
            type: "sequestration",
          },
        ]);
      }
      return null;
    });

    return arr;
  };

  newGiftCard = (gift) => {
    return (
      <div
        key={`${gift.createdAt.toDate()} - ${gift.id} - new_gift`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "#FFF4F4",
          borderColor: "#9D5B5B",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              paddingRight: "15px",
            }}
          >
            <img
              style={{
                height: "40px",
              }}
              src={gift_update_4}
              alt=""
            />
          </span>
          <span>
            <a
              style={{
                color: "rgb(33, 37, 41)",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              href={`/gifts/${gift.id}`}
            >
              You bought
            </a>{" "}
            {gift.recipient_name} a thank you gift
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(gift.createdAt.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  newGiftPurchases = (gift) => {
    return [
      {
        card: this.newGiftCard(gift),
        date: gift.createdAt.toDate(),
        type: "new_gift",
      },
    ];
  };

  newGiftContributionCard = (gift) => {
    return (
      <div
        key={`${gift.date.toDate()} - ${gift.gift_id} - new_gift`}
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: "rgba(244, 255, 196, 0.5)",
          borderColor: "#7B8C2B",
          borderStyle: "solid",
          borderRadius: "10px",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div>
          <span
            style={{
              paddingLeft: "20px",
              marginBottom: "10px",
              height: "100%",
              paddingBottom: "10px",
            }}
          >
            <img
              style={{
                height: "60px",
              }}
              src={gift_update_5}
              alt=""
            />
          </span>
          <span>
            {gift.gift_id === "#" ? (
              <span
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                You contributed
              </span>
            ) : (
              <a
                style={{
                  color: "rgb(33, 37, 41)",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                href={`/gifts/${gift.gift_id}`}
              >
                You contributed
              </a>
            )}
            to {gift.recipient_name}'s gift
          </span>
        </div>
        <span
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            padding: "20px",
          }}
        >
          {dayjs(gift.date.toDate()).fromNow()}
        </span>
      </div>
    );
  };
  newGiftContributions = (contribution) => {
    return [
      {
        card: this.newGiftContributionCard(contribution),
        date: contribution.date.toDate(),
        type: "contribution",
      },
    ];
  };

  notificationsEngine = (purchased_gifts, contributed_gifts, gifts_for_me) => {
    let arr = [];

    // All gifts you have bought
    purchased_gifts
      .filter((gift) => gift.id !== "#")
      .filter((a) => a.type == "bought")
      .map((gift) => {
        this.newHeightNotifications(gift).map((card) => {
          arr.push([card]);
        });
        arr.push(this.newContributionNotification(gift));
        arr.push(...this.newSequestrationMilestoneNotification(gift));
        arr.push(this.newGiftPurchases(gift));
        return null;
      });

    // all gifts you have supported
    purchased_gifts
      .filter((a) => a.type == "supported")
      .map((gift) => {
        this.newHeightNotifications(gift).map((card) => {
          arr.push([card]);
        });
        arr.push(this.newContributionNotification(gift));
        arr.push(...this.newSequestrationMilestoneNotification(gift));

        return null;
      });

    contributed_gifts.map((contribution) => {
      arr.push(this.newGiftContributions(contribution));
      return null;
    });

    // all gifts bought for you
    gifts_for_me.map((gift) => {
      this.newHeightNotifications(gift).map((card) => {
        arr.push([card]);
      });
      arr.push(...this.newSequestrationMilestoneNotification(gift));
      arr.push(this.newGiftForMeNotification(gift));
      arr.push(this.newContributionGiftForMeNotification(gift));
    });

    let res = arr.filter((a) => a.length !== 0);
    res = res.sort((a, b) => b[0].date - a[0].date).map((a) => a[0]);
    return res;
  };

  render() {
    let notifications = this.notificationsEngine(
      this.props.purchased_gifts,
      this.props.contributed_gifts,
      this.props.gifts_for_me
    );
    let filtered_notification = notifications;

    if (this.state.notification_type_detail == "all_notifications") {
      filtered_notification = notifications;
    } else if (this.state.notification_type_detail == "height_notification") {
      filtered_notification = notifications.filter(
        (notif) => notif.type === "new_height"
      );
    } else if (this.state.notification_type_detail == "co2_notification") {
      filtered_notification = notifications.filter(
        (notif) => notif.type === "sequestration"
      );
    }

    return (
      <div>
        <br />
        <br />
        <div>
          <h3>Gift updates </h3>
          <hr
            style={{
              height: "3px",
              backgroundColor: "#e5e5e5",
            }}
          />
        </div>{" "}
        <span>
          {notifications.length === 0
            ? this.state.notification_type_direction === "received"
              ? "No gifts have been received yet."
              : "No gifts have been purchased yed yet."
            : "Discover updates about how your gifts are constantly changing the world."}
        </span>
        <Form>
          <Form.Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0px",
              marginTop: "12px",
            }}
          >
            <Col md={4}>
              <Form.Group
                style={{
                  marginBottom: "0px",
                }}
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control
                  onChange={(e) => {
                    this.setState({
                      notification_type_detail: e.target.value,
                    });
                  }}
                  as="select"
                >
                  <option value="all_notifications">All notifications</option>
                  <option value="height_notification">Height</option>
                  <option value="co2_notification">CO₂</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
        {filtered_notification.map((a) => a.card)}
      </div>
    );
  }
}

export default GiftUpdates;
