import React, { Component } from "react";
import { Form } from "react-bootstrap";
import emoji from "./Emoji";
import dayjs from "dayjs";
class GiftMessage extends Component {
  state = {};

  render() {
    return (
      <>
        {/* {this.props.gift &&
          this.props.gift.gift_cover === "togetherness" &&
          this.props.gift.supporters.map(
            (supp, id) =>
              supp.support_message && (
                <div className="speechbubble" key={"id-" + id}>
                  <p>
                    {supp.support_message}
                    <span className="username">{supp.name}</span>
                  </p>
                </div>
              )
          )} */}

        {this.props.gift && this.props.gift.gift_cover === "togetherness" ? (
          this.props.gift.supporters &&
          []
            .concat({
              message: this.props.gift.message,
              name: this.props.gift.buyer_name,
              date: dayjs(this.props.gift.createdAt.toDate()).fromNow(),
              quantity: this.props.gift.supporters[0].quantity,
            })
            .concat(
              this.props.gift.supporters.slice(1).map((supp) => ({
                message: supp.support_message,
                name: supp.name,
                date: dayjs(supp.date.toDate()).fromNow(),
                quantity: supp.quantity,
              }))
            )
            .filter((obj) => obj.message)
            .map((obj, id) => (
              <div className="speechbubble" key={"id-" + id}>
                <div>
                  <div>
                    <span
                      style={{
                        color: "#155724",
                        fontWeight: "bold",
                      }}
                    >
                      {obj.name}
                    </span>{" "}
                    <span>•</span>
                    <span
                      style={{
                        color: "#15572480",
                      }}
                    >
                      {" "}
                      {obj.date}
                    </span>
                  </div>
                </div>
                <span>
                  {obj.message}
                  <span className="username_v2">{obj.quantity} trees </span>
                </span>
              </div>
            ))
        ) : (
          <React.Fragment key="some_key">
            {this.props.gift.group_gift &&
            this.props.gift.supporters.length > 1 ? (
              <React.Fragment>
                <Form id="message">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      <strong
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        A thank you gift from everyone here 🎁
                      </strong>
                    </Form.Label>
                  </Form.Group>
                </Form>
                <div className="speechbubble">
                  <p>
                    {this.props.gift.message}
                    <span className="username">
                      {this.props.gift.buyer_name}
                    </span>
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <Form id="message">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label style={{ fontSize: "20px" }}>
                    <strong
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      A message from{" "}
                      {(this.props.gift.sender &&
                        this.props.gift.sender.name) ||
                        (this.props.gift.buyer_name &&
                          this.props.gift.buyer_name)}{" "}
                      🎁
                    </strong>
                  </Form.Label>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    {emoji.slack_to_unicode(this.props.gift.message)}
                  </p>
                </Form.Group>
                <br />
              </Form>
            )}
          </React.Fragment>
        )}
      </>
    );
  }
}

export default GiftMessage;
