import React, { Component } from "react";
import { Container, Card, Button, Alert } from "react-bootstrap";
import { collectIdsAndDocs } from "../Utils/CollectIdsAndDocs";

class History extends Component {
  state = {
    gifts: [],
    alert: true,
  };

  componentDidMount() {
    if (
      !(
        this.props.match.params.email === "fadifessa@gmail.com" ||
        this.props.match.params.email === "fadel@uwo.ca"
      )
    ) {
      this.props.db
        .collection("gifts")
        .where("buyer_email", "==", this.props.match.params.email)
        .where("show_history", "==", true)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            // console.log("No emails for today.");
            return;
          }
          // console.log("resp: ", snapshot.docs)
          let gifts = snapshot.docs.map(collectIdsAndDocs);
          // console.log("gifts: ", gifts);
          this.setState({ gifts });
        });
    }
  }
  render() {
    let total = this.state.gifts.reduce(
      (sum, x) => Number(sum) + Number(x.quantity),
      0
    );
    return (
      <div>
        <section className="py-5">
          <Container>
            <div style={{ width: "100%" }}>
              <h3 style={{ marginTop: "1.5em" }}> History </h3>
              <p>
                {" "}
                You have planted{" "}
                <strong>
                  {this.state.gifts.length > 0 ? total : 0} trees
                </strong>{" "}
                in total, which offsets{" "}
                <strong>
                  {" "}
                  {((total * 308) / 20000).toFixed(1)} human years{" "}
                </strong>{" "}
                of carbon footprint.
              </p>
            </div>
            <hr />
            {this.state.gifts.length > 0 &&
              this.state.gifts.map((gift) => (
                <Card key={gift.id} style={{ marginBottom: "25px" }}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> {gift.recipient_name}</strong>
                    </Card.Title>
                    <Card.Text>{gift.message}</Card.Text>
                    <Button href={`/gifts/${gift.id}`} variant="primary">
                      Gift
                    </Button>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    {gift.location.charAt(0).toUpperCase() +
                      gift.location.slice(1)}{" "}
                    - {gift.quantity} trees
                  </Card.Footer>
                </Card>
              ))}
            {this.state.gifts.length > 0 && this.state.alert && (
              <Alert
                variant="success"
                onClose={() => this.setState({ alert: !this.state.alert })}
                dismissible
              >
                <Alert.Heading>
                  Hey, nice to see you again {this.state.gifts[0].buyer_name}
                </Alert.Heading>
                <p>
                  We want you to have a{" "}
                  <strong>
                    seamless experience accessing your information.{" "}
                  </strong>{" "}
                  As a result, this tool is public and only those with this
                  specific link can see this information.
                </p>
                <hr />
                <p className="mb-0">
                  If you'd like us to disable this for your account, please
                  <a
                    href={`mailto:hello@thankyoutrees.io?Subject=Disable history view for ${this.state.gifts[0].buyer_email}`}
                    target="_top"
                  >
                    {" "}
                    email us{" "}
                  </a>{" "}
                  and you won't see your history here again.
                </p>
              </Alert>
            )}
          </Container>
        </section>
      </div>
    );
  }
}

export default History;
