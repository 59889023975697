import React, { Component } from "react";
import { Container, Form, Button, ButtonGroup, Row } from "react-bootstrap";
import { FaGoogle } from "react-icons/fa";
import { signInWithGoogle, signOut } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { UserContext } from "../Providers/UserProvider";
import "react-toastify/dist/ReactToastify.css";
let BUILD_LEVEL = process.env.REACT_APP_BUILD_LEVEL;

class Admin extends Component {
  state = {
    gift_url: "",
    message: "",
    support_index: 1,
    type_edit: "message",
    submit: false,
    supporters: [],
    pwd: "",
    email_type: "",
  };

  static contextType = UserContext;

  // componentDidMount() {}

  onChangeHandle = (e) => {
    let state;

    if (this.state.type_edit === "support" && e.target.name === "supporters") {
      state = this.state.supporters;
      state[this.state.support_index].support_message = e.target.value;
      this.setState(state);
    } else {
      state = {};
      state[e.target.name] = e.target.value;
      console.log("CHANGE: ", state);
      this.setState(state);
    }
  };

  get giftRef() {
    return this.props.db.collection("gifts").doc(this.state.gift_url);
  }

  messageChange = () => {
    if (this.state.type_edit === "message") {
      this.giftRef
        .update({
          message: this.state.message,
        })
        .then((resp) => {
          toast("Success!", { type: "success" });
          console.log("RESP: ", resp);
          this.setState({ submit: true });
        });
    } else if (this.state.type_edit === "support") {
      this.giftRef
        .update({
          supporters: this.state.supporters,
        })
        .then((resp) => {
          toast("Success!", { type: "success" });
          console.log("RESP: ", resp);
          this.setState({ submit: true });
        });
    }
  };

  emailRequest = () => {
    let { pwd, email_type, gift_url } = this.state;

    if (pwd !== "" && email_type !== "email_type" && gift_url !== "") {
      let body = {
        pwd,
        email_type,
      };
      axios
        .post(
          `https://the-tree-project-server.firebaseapp.com/${BUILD_LEVEL}/v1/resend/${gift_url}`,
          body,
          { crossdomain: true }
        )
        .then((response) => {
          if (response.data.status === "email sent") {
            toast(`Email successfully sent ${this.state.name}!`, {
              type: "success",
            });
          } else if (response.data.status === "email not sent") {
            toast("Email was not sent", { type: "error" });
          } else if (response.data.status === "auth not correct") {
            toast("Authentication is not correct", { type: "error" });
          }
        });
    } else {
      toast("Please fill out form correctly", { type: "error" });
    }
  };

  // ch_1Gjrg0JGYzY5FZVd3JbnrWed
  urlSubmit = () => {
    if (this.state.type_edit === "message") {
      this.giftRef
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log("No such document!");
            this.setState({ error: true });
          } else {
            console.log("GIFT: ", doc.data());
            this.setState({ message: doc.data().message });
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
        });
    } else if (this.state.type_edit === "support") {
      console.log("SUPPORT MESSAGE ");
      this.giftRef
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log("No such document!");
            this.setState({ error: true });
          } else {
            console.log("GIFT: ", doc.data());
            this.setState({
              supporters: doc.data().supporters,
            });
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
        });
    }
  };

  populateProfile = () => {
    // console.log("populateProfile", db);
    // db.collection("gifts")
    //   .where("buyer_email", "==", this.context.email)
    //   .where("show_history", "==", true)
    //   .onSnapshot((snapshot) => {
    //     if (snapshot.empty) {
    //       return;
    //     }

    //     // console.log("resp: ", snapshot.docs)
    //     let gifts = snapshot.docs.map(collectIdsAndDocs);
    //     console.log("gifts: ", gifts);
    //     // const expertRef = db.doc(`users/${this.context.id}`);
    //     // expertRef.update({ last_login: new Date(), login_history: firestore.FieldValue.arrayUnion(new Date()) });

    //   });
    console.log("onboarding");
  };
  render() {
    let { gift_url, support_index, email_type, pwd } = this.state;
    console.log("context: ", this.context);
    return (
      <span>
        {this.context && this.context.id && (
          <Container>
            <ToastContainer />
            <br />
            <br />
            <h1 style={{ textAlign: "center" }}> Admin </h1>
            <br />
            <Button onClick={this.populateProfile}>Populate profile</Button>

            <p>
              <strong> ID: </strong>ch_1Gjrg0JGYzY5FZVd3JbnrWed
            </p>

            <Row
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ButtonGroup size="lg" className="mb-2">
                <Button
                  onClick={() => {
                    this.setState({
                      type_edit: "message",
                    });
                  }}
                  variant={`${
                    this.state.type_edit === "message" ? "info" : "light"
                  }`}
                >
                  Messages
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      type_edit: "support",
                    });
                  }}
                  variant={`${
                    this.state.type_edit === "support" ? "info" : "light"
                  }`}
                >
                  Supporter comments
                </Button>
              </ButtonGroup>
            </Row>
            <br />
            <div>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  {" "}
                  <h2> URL</h2>
                </Form.Label>
                <Form.Control
                  onChange={this.onChangeHandle}
                  value={gift_url}
                  name="gift_url"
                  type="text"
                  placeholder="Enter URL"
                />
                {this.state.type_edit === "support" && (
                  <span>
                    <br />

                    <Form.Label>
                      {" "}
                      <h2> Support Index</h2>
                    </Form.Label>
                    <Form.Control
                      onChange={this.onChangeHandle}
                      value={support_index}
                      name="support_index"
                      type="text"
                      placeholder="Enter Support Index"
                    />
                  </span>
                )}
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    console.log("URL SUBMIT");
                    this.urlSubmit();
                  }}
                  style={{ width: "40%" }}
                  variant="primary"
                >
                  Fetch message
                </Button>
              </div>
              <br />
              <br />
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    <h2> Message </h2>
                  </Form.Label>
                  <Form.Control
                    value={
                      this.state.type_edit === "message"
                        ? this.state.message
                        : this.state.supporters[this.state.support_index] &&
                          this.state.supporters[this.state.support_index]
                            .support_message
                    }
                    onChange={this.onChangeHandle}
                    name={
                      this.state.type_edit === "message"
                        ? "message"
                        : "supporters"
                    }
                    as="textarea"
                    rows="3"
                  />
                </Form.Group>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    console.log("messageChange");
                    this.messageChange();
                  }}
                  style={{ width: "40%" }}
                  variant="primary"
                >
                  Change message/comment
                </Button>
              </div>
              <br />
              <br />
              {this.state.submit && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    href={`/gifts/${gift_url}`}
                    target="_blank"
                    style={{ width: "40%" }}
                    variant="success"
                  >
                    Check gift
                  </Button>
                </div>
              )}
              <hr
                style={{
                  border: "1px solid black",
                }}
              />

              <br />
              <br />
              <h2>Send emails</h2>
              <Form>
                <Form.Group controlId="formGroupEmail">
                  <Form.Label>Gift ID</Form.Label>
                  <Form.Control
                    onChange={this.onChangeHandle}
                    value={gift_url}
                    name="gift_url"
                    type="text"
                    placeholder="Enter gift URL"
                  />
                </Form.Group>
                <Form.Group controlId="formGroupEmailType">
                  <Form.Label>
                    Type of request to be sent (buyer_email etc.)
                  </Form.Label>
                  <Form.Control
                    autoComplete="new-password"
                    value={email_type}
                    name="email_type"
                    onChange={this.onChangeHandle}
                    type="text"
                    placeholder="Enter request type"
                  />
                </Form.Group>
                <Form.Group controlId="formGroupAuthPassword">
                  <Form.Label>Authentication</Form.Label>
                  <Form.Control
                    autoComplete="new-password"
                    value={pwd}
                    name="pwd"
                    onChange={this.onChangeHandle}
                    type="text"
                    placeholder="Authentication code"
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      console.log("emailRequest");
                      this.emailRequest();
                    }}
                    style={{ width: "40%" }}
                    variant="primary"
                  >
                    Send request
                  </Button>
                </div>
              </Form>
              <br />
              <br />
              <h2> Sign in via google</h2>
              <ButtonGroup>
                <Button
                  onClick={signInWithGoogle}
                  style={{ color: "white" }}
                  className="btn btn-danger"
                >
                  <i className="fa fa-google"></i>{" "}
                  <FaGoogle style={{ color: "white" }} /> Google
                </Button>
                <Button
                  onClick={() => {
                    signOut();
                    // this.props.history.push("/")
                    toast("Signed out", { type: "success" });
                  }}
                >
                  Sign Out
                </Button>
              </ButtonGroup>
            </div>
          </Container>
        )}
      </span>
    );
  }
}

export default Admin;
