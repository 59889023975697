import React, { Component } from "react";
import { Row, Col, Container, Button, ButtonToolbar } from "react-bootstrap";
import GiftMap from "../Components/GiftMap";
import TreeInfo from "../Components/TreeInfo";
import TreeGraph from "../Components/TreeGraph";
import Gallery from "../Components/Gallery";
import PlantingTeam from "../Components/PlantingTeam";
import GiftSummary from "../Components/GiftSummary";
import styled from "styled-components";
import Scrollspy from "react-scrollspy";
import dayjs from "dayjs";
import gift_update_1 from "../assets/gift_update_1.png";
import NewGallery from "../Components/NewGallery";

const CallToActions = styled.span`
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const TopButton = styled.a`
  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
`;

const GiftBackground = styled.div`
  background-color: #fff5d8; ;
`;

const MobileBodyMargin = styled.div`
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const GiftMessageStyle = styled.div`
  @media (max-width: 600px) {
    display: contents;
    padding-left: 2%;
    padding-right: 2%;
  }
  @media (min-width: 601px) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const UpdateCard = styled.div`
  @media (max-width: 600px) {
    display: flex;
  }
`;

const StyledCol = styled(Col)`
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-top: 15px;
  }
  width: 50%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
`;

const MobileIMG = styled.img`
  @media (max-width: 600px) {
    border-radius: 0px !important;
  }
`;

const StyledSubtitle = styled.div`
  @media (max-width: 600px) {
    line-height: 1.1;
    padding-top: 15px;
  }
`;

const GalleryComponent = (country) => {
  return (
    <>
      {" "}
      <Row>
        <div
          style={{
            paddingTop: "15px",
            width: "100%",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-start",
          }}
        >
          <Col
            style={{
              width: "50%",
              minWidth: "200px",
            }}
          >
            <MobileIMG
              // Left
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px 0px 0px 10px",
              }}
              src="https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1625&q=80"
            />
          </Col>
          <StyledCol>
            <Row>
              <Col
                style={{
                  width: "50%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    paddingBottom: "15px",
                  }}
                  // top left
                  src="https://images.unsplash.com/photo-1542202229-7d93c33f5d07?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                />
              </Col>
              <Col
                style={{
                  width: "50%",
                  paddingLeft: "0px",
                }}
              >
                <MobileIMG
                  style={{
                    width: "100%",
                    paddingBottom: "15px",
                    borderRadius: "0px 10px 0px 0px",
                  }}
                  // top right
                  src="https://images.unsplash.com/photo-1516214104703-d870798883c5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  width: "50%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  //bottom left
                  src="https://images.unsplash.com/photo-1511432718564-b37b3a906211?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                />
              </Col>
              <Col
                style={{
                  width: "50%",
                  paddingLeft: "0px",
                }}
              >
                <MobileIMG
                  style={{
                    width: "100%",
                    borderRadius: "0px 0px 10px 0px",
                  }}
                  //bottom right
                  src="https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1651&q=80"
                />
              </Col>
            </Row>
          </StyledCol>
        </div>
      </Row>{" "}
    </>
  );
};

class Forests extends Component {
  state = {
    gift: {
      location: "madagascar",
      quantity: "10",
      createdAt: dayjs("2015-12-25T19:15:14.098Z"),
    },
  };
  render() {
    return (
      <GiftBackground color={"christmas_1"}>
        <Container
          style={{
            height: "100%",
            paddingTop: "15px",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col>
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "500",
                }}
              >
                Madagascar
              </div>
              <StyledSubtitle
                style={{
                  fontSize: "2rem",
                  fontWeight: "300",
                  marginTop: "-10px",
                }}
              >
                15 trees planted by Matt
              </StyledSubtitle>
            </Col>
            <Col
              style={{
                alignSelf: "flex-end",
                height: "55%",
                paddingRight: "15px",
                display: "flex",
                flexFlow: "row-reverse",
              }}
            >
              <CallToActions>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  target="_blank"
                  href="/profile"
                  variant="success"
                  size={"lg"}
                  as={TopButton}
                >
                  View Status
                </Button>
                <Button variant="secondary" size={"lg"}>
                  Share
                </Button>
              </CallToActions>
            </Col>
          </Row>
          <NewGallery />
          <br />

          <Row>
            <Row
              style={{
                width: "100%",
                paddingBottom: "25px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Col
                md={4}
                style={{
                  paddingRight: "0",
                  paddingLeft: "0",
                }}
              >
                <GiftSummary forest_page_flag={true} {...this.state} />{" "}
              </Col>
              <Col>
                <h3
                  style={
                    {
                      // paddingTop: "1.5em",
                    }
                  }
                >
                  {" "}
                  Latest forest update
                </h3>
                <hr />
                <span
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    key={`new_height`}
                    style={{
                      paddingTop: "40px",
                      paddingBottom: "40px",
                      background: "rgba(99, 51, 34, 0.05)",
                      borderColor: "#633322",
                      borderStyle: "solid",
                      borderRadius: "10px",
                      position: "relative",
                      marginTop: "20px",
                    }}
                  >
                    <UpdateCard>
                      <span
                        style={{
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <img
                          style={{
                            height: "52px",
                          }}
                          src={gift_update_1}
                          alt=""
                        />
                      </span>
                      <span>This forest has reached a new height of 75 cm</span>
                    </UpdateCard>

                    <span
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "0",
                        padding: "20px",
                      }}
                    >
                      {/* 1 day ago */}
                      {/* {dayjs(date_of_notification).fromNow()} */}2 days ago
                    </span>
                  </div>
                </span>
                <br />
                <ButtonToolbar
                  id="contribute_message"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <Button
                    className="contribute_button"
                    style={{ width: "40%" }}
                    variant="success"
                    size="lg"
                    href="/profile"
                    target="_blank"
                  >
                    See all updates
                  </Button>
                </ButtonToolbar>{" "}
              </Col>
            </Row>
          </Row>
          <Row
            as={MobileBodyMargin}
            style={{
              width: "100%",
            }}
          >
            <div className="col-2 side-menu" id="myScrollspy">
              <Scrollspy
                style={{
                  marginTop: "2.5em",
                }}
                items={[
                  "location",
                  "tree",
                  "ecosystem",
                  "planting_team",
                  "impact",
                ]}
                componentTag="div"
                currentClassName="active"
                scrolledPastClassName=""
                className="list-group"
              >
                {/* <a
                          className="list-group-item list-group-item-action"
                          href="#message"
                        >
                          Message
                        </a> */}
                {/* {this.state.gift.location !== "haiti" && ( */}
                <a
                  className="list-group-item list-group-item-action"
                  href="#location"
                >
                  Location
                </a>
                <a
                  key={"tree"}
                  className="list-group-item list-group-item-action"
                  href="#tree"
                >
                  Tree type
                </a>
                <a
                  className="list-group-item list-group-item-action"
                  href="#ecosystem"
                >
                  Ecosystem
                </a>
                <a
                  className="list-group-item list-group-item-action"
                  href="#planting_team"
                >
                  Planting team
                </a>
                <a
                  className="list-group-item list-group-item-action"
                  href="#impact"
                >
                  Your impact
                </a>
              </Scrollspy>
            </div>

            <div className="col-10 gift_body">
              <Row>
                <GiftMessageStyle>
                  {/* Gift community */}
                  {/* <SupportList community={this.state.gift.supporters} /> */}
                  <GiftMap country={this.state.gift.location} />
                  <TreeInfo country={this.state.gift.location} />
                  {/* <Timeline /> */}
                  {/* Gift Gallery */}
                  <Gallery country={this.state.gift.location} />

                  {/* PlantingTeam */}
                  <PlantingTeam
                    forest_page_flag={true}
                    country={this.state.gift.location}
                  />

                  {/* CO2 GRAPH */}
                  <TreeGraph quantity={this.state.gift.quantity} />
                </GiftMessageStyle>
              </Row>
            </div>
          </Row>
        </Container>
      </GiftBackground>
    );
  }
}

export default Forests;
