import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UserContext } from "../Providers/UserProvider";
import { db, firestore } from "../firebase";
import { collectIdsAndDocs } from "../Utils/CollectIdsAndDocs";
import styled from "styled-components";

// Images for profile page
import YourGifts from "./Profile/YourGifts";
import YourNetwork from "./Profile/YourNetwork";
import ImpactSummary from "./Profile/ImpactSummary";
import GiftUpdates from "./Profile/GiftUpdates";
import GoogleMaps from "./Profile/GoogleMaps";
import ProfileCard from "./Profile/ProfileCard";
import YourForests from "./Profile/YourForests";

const Toggle = styled.span`
  .Not-scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1;
    background: #ffcd5c21;
  }

  .Scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    text-align: center;
    padding-top: 23%;
  }
  .button {
    background-color: #ffffff;
    border: none;
    color: #4c4848;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }
`;

class Profile extends Component {
  static contextType = UserContext;

  state = {
    scrollable_map: false,
    purchased_gifts: [],
    contributed_gifts: [],
    gifts_for_me: [],
    loaded: false,
    network: [],
  };

  render() {
    if (this.state.loaded === false && this.context && this.context.email) {
      // let email = this.context.email;
      let purchased_gifts = [];
      let contributed_gifts = [];
      let gifts_for_me = [];

      db.collection("gifts")
        .where("recipient_email", "==", this.context.email)
        // .where("env", "==", "prod")
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          gifts_for_me = snapshot.docs.map(collectIdsAndDocs);

          gifts_for_me = [
            ...gifts_for_me.map((obj) => ({
              ...obj,
              type: "for_me",
            })),
          ];

          db.collection("gifts")
            .where("buyer_email", "==", this.context.email)
            // .where("env", "==", "prod")
            .orderBy("createdAt", "desc")
            .onSnapshot((snapshot) => {
              purchased_gifts = snapshot.docs.map(collectIdsAndDocs);
              db.collection("supporters")
                .where("email", "==", this.context.email)
                .orderBy("date", "desc")
                .onSnapshot((snapshot) => {
                  contributed_gifts = snapshot.docs.map(collectIdsAndDocs);
                  // console.log("contributed_gifts: ", contributed_gifts);

                  // need to condition because 'IN' firebase condition needs a non-empty array
                  if (contributed_gifts.length > 0) {
                    db.collection("gifts")
                      .where(
                        firestore.FieldPath.documentId(),
                        "in",
                        contributed_gifts.map((gift) => gift.gift_id).slice(0, 10)
                      )
                      .get()
                      .then((resp) => {
                        // label which gifts are which (no avoid bought gifts for supported)
                        purchased_gifts = [
                          ...purchased_gifts.map((obj) => ({
                            ...obj,
                            type: "bought",
                          })),
                          ...resp.docs
                            .map(collectIdsAndDocs)
                            .map((obj) => ({ ...obj, type: "supported" })),
                        ];

                        // remove duplicates
                        purchased_gifts = purchased_gifts
                          .sort((a, b) => a.id.localeCompare(b.id))
                          .filter((a, b) => a.id != b.id);

                        // console.log("supported_gifts: ", purchased_gifts);

                        this.setState({
                          purchased_gifts,
                          contributed_gifts,
                          gifts_for_me,
                          loaded: true,
                        });
                      });
                  } else {
                    purchased_gifts = [
                      ...purchased_gifts.map((obj) => ({
                        ...obj,
                        type: "bought",
                      })),
                    ];
                    this.setState({
                      purchased_gifts,
                      contributed_gifts,
                      gifts_for_me,
                      loaded: true,
                    });
                  }
                });
            });
        });
    }

    return (
      <div
        style={{
          maxWidth: "100%",
          padding: "0px",
          margin: "0px",
          backgroundColor: "#FFFFF3",
        }}
      >
        {" "}
        {/* Profile cover section */}
        <Row
          style={{
            width: "100%",
            padding: "0px",
            margin: "0px",
          }}
        >
          {/* Profile card */}
          <Col
            xs={5}
            style={{
              backgroundColor: "#FFEEC6",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <ProfileCard purchased_gifts={this.state.purchased_gifts} />
          </Col>
          {/* Google maps */}
          <Col
            style={{
              height: "300px",
              padding: "0px",
            }}
          >
            <Toggle
              style={{
                display: "flex",
              }}
            >
              <div
                className={
                  this.state.scrollable_map
                    ? "Scrollable-world"
                    : "Not-scrollable-world"
                }
                onClick={() => {
                  this.setState({ scrollable_map: true });
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    alignSelf: "center",
                  }}
                  className="button button3"
                >
                  &nbsp;
                  <span>View gift map</span>
                </button>
              </div>
            </Toggle>
            <span
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <GoogleMaps
                all_gifts={[]
                  .concat(this.state.purchased_gifts)
                  .concat(this.state.gifts_for_me)}
              />
            </span>
          </Col>
        </Row>
        <YourForests
          all_gifts={[]
            .concat(this.state.purchased_gifts)
            .concat(this.state.gifts_for_me)}
        />
        <Container>
          <Row
            style={{
              width: "100%",
            }}
          >
            <Col lg={4}>
              <YourGifts purchased_gifts={this.state.purchased_gifts} />
              <br />
              <YourNetwork purchased_gifts={this.state.purchased_gifts} />
            </Col>
            <Col lg={8}>
              {" "}
              <ImpactSummary
                gifts_for_me={this.state.gifts_for_me}
                purchased_gifts={this.state.purchased_gifts}
              />
              <GiftUpdates
                contributed_gifts={this.state.contributed_gifts}
                purchased_gifts={this.state.purchased_gifts}
                gifts_for_me={this.state.gifts_for_me}
              />
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    );
  }
}

export default Profile;
