import ReactGA from "react-ga";

const ReactButtonHandler = (title, start_time) => {
  let endDate = new Date();
  let diff = (endDate - start_time) / 1000

  // if(start_time){
  //   console.log("time taken: ", Math.floor(diff), title);
  // }
  if (window.location.host !== "localhost:3000") {
    ReactGA.event({
      category: "Button v2 - time adjusted",
      action: title,
      value: Math.floor(diff), // in seconds
    });
  } else {
    // console.log("GA - Disabled");
  }
};

export default ReactButtonHandler;
