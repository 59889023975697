import React, { Component } from "react";
import { UserContext } from "../../Providers/UserProvider";

class ProfileCard extends Component {
  static contextType = UserContext;

  state = {};

  process_network = (gifts) => {
    let network = {};
    let arr = [];
    gifts.map((gift) => {
      if (network[gift.recipient_name]) {
        network[gift.recipient_name].count++;
      } else {
        network[gift.recipient_name] = { count: 1 };
      }

      return null;
    });

    for (let [k, v] of Object.entries(network)) {
      arr.push({ name: k, count: v.count });
    }
    return arr;
  };
  render() {
    let network = this.process_network(this.props.purchased_gifts);
    return (
      <>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "10px",
          }}
        >
          {" "}
          <div>
            {" "}
            {this.context && this.context.photo_url ? (
              <img
                style={{
                  borderRadius: "200px",
                  height: "200px",
                  width: "200px",
                }}
                src={`${this.context ? this.context.photo_url : ""}`}
              />
            ) : (
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  backgroundColor: "#543054",
                  borderRadius: "200px",
                  display: "flex",
                  color: "white",
                }}
              >
                <span
                  style={{
                    margin: "auto",
                    fontSize: "80px",
                  }}
                >
                  {this.context &&
                    this.context.display_name &&
                    this.context.display_name[0]}
                </span>
              </div>
            )}
          </div>{" "}
          <div
            style={{
              paddingLeft: "25px",
              display: "flex",
              flexFlow: "column",
              alignContent: "center",
            }}
          >
            {" "}
            <span
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "50px",
              }}
            >
              {this.context && this.context.display_name}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <span
                style={{
                  fontSize: "24px",
                }}
              >
                📘 {network.length}{" "}
                {network.length == 1 ? "contact" : "contacts"}{" "}
              </span>{" "}
              <span
                style={{
                  fontSize: "24px",
                }}
              >
                🎁 {this.props.purchased_gifts.length}{" "}
                {this.props.purchased_gifts.length == 1 ? "gift" : "gifts"}{" "}
              </span>
              <span
                style={{
                  fontSize: "24px",
                }}
              >
                🌲 {this.context && this.context.tree_num}{" "}
                {this.context && this.context.tree_num == 1 ? "tree" : "trees"}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ProfileCard;
