import React, { Component } from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

// Images for Christmas cover #1
import wrappedGift from "../assets/wrapped_gift.png";
import wrappedGiftMobile from "../assets/wrapped_gift_mobile.png";
import treeDecor from "../assets/tree_decor.png";
// Image for Christmas cover #2
import santa from "../assets/santa.png";
// Image for Christmas cover #3
import deer from "../assets/deer.png";
import boxesOfGifts from "../assets/boxes_of_gifts.png";
// Images for Birthday cover #1
import birthday_1_yellowblob from "../assets/birthday/birthday_1_yellowblob.png";
import forest_cover from "../assets/gift_themes/forest_cover.png";
import birthday_1_yellowblob_mobile from "../assets/birthday/birthday_1_yellowblob_mobile.png";
import { storage } from "../firebase";
let storageRef = storage.ref();

const RemoveForMobile = styled.div`
  @media screen and (max-width: 850px) {
    display: none !important;
  }
`;

// CSS for covers
const ChristmasCover1 = styled.div`
  @media screen and (max-width: 480px) {
    height: 700px !important;
  }
  background-color: #faf8e8;
  height: 526px;
  display: flex;
  flex-direction: column;
`;

const ChristmasCover2 = styled.div`
  @media screen and (max-width: 480px) {
    height: 900px !important;
  }
  height: 526px;
  background: #f7c945;
`;

const ChristmasCover3 = styled.div`
  @media screen and (max-width: 480px) {
    height: 1026px !important;
  }

  height: 526px;
  background: linear-gradient(
    180deg,
    #fee7b9 0%,
    #ffffff 99.45%,
    #fefbf4 99.46%,
    rgba(255, 254, 252, 0.026178) 99.47%
  );
`;

const BirthdayCover1 = styled.div`
  @media screen and (max-width: 480px) {
    height: 800px !important;
  }

  background-image: url(${forest_cover});
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Gift_Cover_Christmas_1 = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }
  return (
    <ChristmasCover1>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <img
          style={{
            height: "150px",
          }}
          alt="icon_1"
          src={treeDecor}
        />
      </div>
      <Col
        style={{
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
          width: "fit-content",
          flexDirection: "column",
          fontFamily: "Spirax",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "80px",
          lineHeight: "124px",
          marginLeft: "5%",
          color: "rgb(223 161 0 / 0.8)",
          textShadow: "1px 2px 0 #EEE, 0 0 0 #000, 1px 2px 0 #EEE",
        }}
      >
        Merry Christmas {name}
      </Col>
      <RemoveForMobile
        style={{
          position: "absolute",
          marginTop: "25px",
        }}
      >
        <img
          style={{
            height: "500px",
          }}
          alt="icon_1"
          src={wrappedGift}
        />
      </RemoveForMobile>
    </ChristmasCover1>
  );
};

const Gift_Cover_Christmas_1_Mobile = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }
  return (
    <ChristmasCover1>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <img
          style={{
            height: "150px",
          }}
          alt="icon_1"
          src={treeDecor}
        />
      </div>
      <Col
        style={{
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
          width: "fit-content",
          flexDirection: "column",
          fontFamily: "Spirax",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "75px",
          lineHeight: "124px",
          color: "#DFA100",
          marginLeft: "5%",
          paddingTop: "100px",
        }}
      >
        Merry Christmas {name}
      </Col>
      <div
        style={{
          //   position: "absolute",
          marginTop: "25px",
        }}
      >
        <img
          style={{
            height: "125px",
          }}
          alt="icon_1"
          src={wrappedGiftMobile}
        />
      </div>
    </ChristmasCover1>
  );
};

const Gift_Cover_Christmas_2 = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }

  return (
    <ChristmasCover2>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <img
          style={{
            height: "150px",
          }}
          alt="icon_1"
          src={treeDecor}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "5%",
        }}
      >
        <span
          style={{
            fontFamily: "Lobster Two",
            fontSize: "80px",
            lineHeight: "109%",
            textAlign: "center",
            letterSpacing: "-0.02em",
          }}
        >
          Merry Christmas
        </span>
        <br />
        <span
          style={{
            fontFamily: "Lobster Two",
            fontWeight: "bold",
            fontSize: "120px",
            lineHeight: "109%",
            textAlign: "center",
            letterSpacing: "-0.02em",
          }}
        >
          {name}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "50px",
          height: "100%",
        }}
      >
        <RemoveForMobile
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "flex-end",
            flexFlow: "column",
          }}
        >
          <img
            style={{
              height: "500px",
            }}
            alt="icon_1"
            src={santa}
          />
        </RemoveForMobile>
      </div>
    </ChristmasCover2>
  );
};

const Gift_Cover_Christmas_2_Mobile = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }

  return (
    <ChristmasCover2>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <img
          style={{
            height: "150px",
          }}
          alt="icon_1"
          src={treeDecor}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "5%",
          paddingBottom: "180px",
        }}
      >
        <span
          style={{
            fontFamily: "Lobster Two",
            fontSize: "80px",
            lineHeight: "109%",
            textAlign: "center",
            letterSpacing: "-0.02em",
          }}
        >
          Merry Christmas
        </span>
        <br />
        <span
          style={{
            fontFamily: "Lobster Two",
            fontWeight: "bold",
            fontSize: "120px",
            lineHeight: "109%",
            textAlign: "center",
            letterSpacing: "-0.02em",
          }}
        >
          {name}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "flex-end",
            flexFlow: "column",
          }}
        >
          <img
            style={{
              height: "300px",
            }}
            alt="icon_1"
            src={santa}
          />
        </div>
      </div>
    </ChristmasCover2>
  );
};

const Gift_Cover_Christmas_3 = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }

  return (
    <ChristmasCover3
      style={{
        width: "100%",
        margin: "0",
      }}
      as={Row}
    >
      <Col>
        <div
          style={{
            fontFamily: "Lobster Two",
            fontWeight: "normal",
            fontSize: "80px",
            lineHeight: "150%",
            textAlign: "center",
            letterSpacing: "-0.02em",
            color: "#9C791F",
            paddingTop: "100px",
          }}
        >
          Merry Christmas <br />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "80px",
            }}
          >
            {" "}
            {name}
          </span>
        </div>
        <RemoveForMobile
          style={{
            position: "absolute",
            bottom: "0px",
          }}
        >
          <img
            style={{
              height: "150px",
            }}
            alt="icon_1"
            src={boxesOfGifts}
          />
        </RemoveForMobile>
      </Col>
      <Col
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <img
          style={{
            height: "425px",
          }}
          alt="icon_1"
          src={deer}
        />
      </Col>
    </ChristmasCover3>
  );
};

const Gift_Cover_Christmas_3_Mobile = (props) => {
  let name = "";
  if (props.gift && props.gift.first_name) {
    name = props.gift.first_name;
  }
  return (
    <ChristmasCover3
      style={{
        width: "100%",
        height: "100%!important",
        margin: "0",
      }}
      as={Col}
    >
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <img
          style={{
            height: "125px",
          }}
          alt="icon_1"
          src={treeDecor}
        />
      </div>
      <Row>
        <div
          style={{
            fontFamily: "Lobster Two",
            fontWeight: "normal",
            fontSize: "80px",
            lineHeight: "150%",
            textAlign: "center",
            letterSpacing: "-0.02em",
            color: "#9C791F",
            paddingTop: "100px",
          }}
        >
          Merry Christmas <br />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "100px",
            }}
          >
            {name}
          </span>
        </div>
        <RemoveForMobile
          style={{
            position: "absolute",
            bottom: "0px",
          }}
        >
          <img
            style={{
              height: "200px",
            }}
            alt="icon_1"
            src={boxesOfGifts}
          />
        </RemoveForMobile>
      </Row>
      <Row
        style={{
          justifyContent: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        <img
          style={{
            height: "425px",
          }}
          alt="icon_1"
          src={deer}
        />
      </Row>
    </ChristmasCover3>
  );
};

class GiftCover extends Component {
  state = {
    width: window.innerWidth,
    gift_cover_url: null,
    loaded: false,
    img_counter: 0,
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  Gift_Cover_Birthday_1 = (props) => {
    let name = "";
    if (props.gift && props.gift.first_name) {
      name = props.gift.first_name;
    }

    if (this.state.gift_cover_url == null) {
      // console.log()
      if (this.props.gift_id_demo === "") {
        storageRef
          .child(
            `gift_cover_images/${window.location.pathname.split("/gifts/")[1]}`
          )
          .getDownloadURL()
          .then((url) => {
            this.setState({ gift_cover_url: url });
          })
          .catch(function (error) {
            // Handle any errors
            console.log("error: ", error);
          });
      } else {
        storageRef
          .child(`gift_cover_images/${this.props.gift_id_demo}`)
          .getDownloadURL()
          .then((url) => {
            this.setState({ gift_cover_url: url });
          })
          .catch(function (error) {
            // Handle any errors
            console.log("error: ", error);
          });
      }
    }

    return (
      <BirthdayCover1
        style={{
          width: "100%",
          margin: "0",
        }}
        as={Row}
      >
        <img
          style={{
            height: "150px",
            position: "absolute",
            overflow: "hidden",
          }}
          alt="icon_1"
          src={birthday_1_yellowblob}
        />{" "}
        <div
          style={{
            zIndex: "1",
            display: "flex",
            width: "100%",
          }}
        >
          <Col
            style={{
              alignSelf: "center",
              textAlign: "center",
              fontFamily: "Lobster Two",
              fontSize: "80px",
              lineHeight: "109%",
              textAlign: "center",
              letterSpacing: "-0.02em",
            }}
          >
            Happy Birthday {name}!
          </Col>
          <Col
            style={{
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                height: "350px",
                width: "350px",
                backgroundColor: "#EA7E4E",
                display: "inline-flex",
                borderRadius: "200px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  border: "3px solid #000000",
                  height: "300px",
                  width: "300px",
                  borderRadius: "400px",
                }}
                src={this.state.gift_cover_url}
              />
            </span>
          </Col>
        </div>
      </BirthdayCover1>
    );
  };

  Gift_Cover_Birthday_1_Mobile = (props) => {
    let name = "";
    if (props.gift && props.gift.first_name) {
      name = props.gift.first_name;
    }
    if (this.state.gift_cover_url == null) {
      storageRef
        .child(
          `gift_cover_images/${window.location.pathname.split("/gifts/")[1]}`
        )
        .getDownloadURL()
        .then((url) => {
          this.setState({ gift_cover_url: url });
        })
        .catch(function (error) {
          // Handle any errors
          console.log("error: ", error);
        });
    }

    return (
      <BirthdayCover1 as={Col}>
        <img
          style={{
            height: "150px",
            position: "absolute",
            overflow: "hidden",
            right: "0px",
          }}
          alt="icon_1"
          src={birthday_1_yellowblob_mobile}
        />{" "}
        <div
          style={{
            zIndex: "1",
            display: "flex",
            width: "100%",
            flexFlow: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Row
            style={{
              zIndex: "2",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                height: "350px",
                width: "350px",
                backgroundColor: "#EA7E4E",
                display: "inline-flex",
                borderRadius: "200px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  border: "3px solid #000000",
                  height: "300px",
                  width: "300px",
                  borderRadius: "400px",
                }}
                src={this.state.gift_cover_url}
              />
            </span>
          </Row>
          <Row
            style={{
              alignSelf: "center",
              textAlign: "center",
              fontFamily: "Lobster Two",
              fontSize: "80px",
              lineHeight: "109%",
              textAlign: "center",
              letterSpacing: "-0.02em",
            }}
          >
            Happy Birthday {name}!
          </Row>
        </div>
      </BirthdayCover1>
    );
  };

  Gift_Cover_Together = (props) => {
    let name = "";
    if (props.gift && props.gift.forest_name) {
      name = props.gift.forest_name;
    }

    if (this.state.gift_cover_url == null) {
      if (this.props.gift_id_demo === "") {
        storageRef
          .child(
            `gift_cover_images/${window.location.pathname.split("/gifts/")[1]}`
          )
          .getDownloadURL()
          .then((url) => {
            this.setState({ gift_cover_url: url, loaded: true });
          })
          .catch(function (error) {
            // Handle any errors
            // console.log("error: ", error);
          });
      } else {
        storageRef
          .child(`gift_cover_images/${this.props.gift_id_demo}`)
          .getDownloadURL()
          .then((url) => {
            this.setState({ gift_cover_url: url });
          })
          .catch(function (error) {
            // Handle any errors
            console.log("error: ", error);
          });
      }
    }
    if (this.state.img_counter != this.props.img_counter) {
      storageRef
        .child(
          `gift_cover_images/${window.location.pathname.split("/gifts/")[1]}`
        )
        .getDownloadURL()
        .then((url) => {
          this.setState({
            gift_cover_url: url,
            loaded: true,
            img_counter: this.props.img_counter,
          });
        })
        .catch(function (error) {
          this.setState({
            loaded: true,
            img_counter: this.props.img_counter,
          });
        });
    }

    return (
      <BirthdayCover1 as={Col}>
        <div
          style={{
            zIndex: "1",
            display: "flex",
            width: "100%",
            flexFlow: "column",
            height: "80%",
            justifyContent: "center",
          }}
        >
          <Row
            style={{
              zIndex: "2",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                height: "250px",
                width: "250px",
                backgroundColor: "#EA7E4E",
                display: "inline-flex",
                borderRadius: "200px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  border: "3px solid #000000",
                  height: "200px",
                  width: "200px",
                  borderRadius: "300px",
                  objectFit: "cover",
                }}
                src={
                  this.state.gift_cover_url == null && this.state.loaded
                    ? "https://picsum.photos/id/1025/4951/3301"
                    : this.state.gift_cover_url
                }
              />
            </span>
          </Row>
          <Row
            style={{
              alignSelf: "center",
              textAlign: "center",
              fontFamily: "Lobster Two",
              fontSize: "60px",
              textAlign: "center",
              letterSpacing: "-0.02em",
            }}
          >
            {this.props.gift.forest_name ? this.props.gift.forest_name : ""}
          </Row>
          <Row
            style={{
              alignSelf: "center",
              textAlign: "center",
              fontSize: "30px",
              lineHeight: "100%",
              textAlign: "center",
              letterSpacing: "-0.02em",
            }}
          >
            <span>
              <span
                style={{
                  fontWeight: "bold",
                  color: "green",
                }}
              >
                {this.props.gift.quantity} trees{" "}
              </span>
              planted in{" "}
              {this.props.gift.location.charAt(0).toUpperCase() +
                this.props.gift.location.slice(1)}
            </span>
          </Row>
        </div>
      </BirthdayCover1>
    );
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    if (isMobile && this.props.gift) {
      return (
        <>
          {/* Gift cover #1 */}
          {this.props.gift.gift_cover === "christmas_1" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_1_Mobile {...this.props} />
          )}

          {/* Gift cover #2 */}
          {this.props.gift.gift_cover === "christmas_2" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_2_Mobile {...this.props} />
          )}

          {/* Gift cover #3 */}
          {this.props.gift.gift_cover === "christmas_3" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_3_Mobile {...this.props} />
          )}
          {this.props.gift.gift_cover === "birthday_1" &&
            this.Gift_Cover_Birthday_1_Mobile({ ...this.props })}
        </>
      );
    } else if (this.props.gift) {
      return (
        <>
          {/* Gift cover #1 */}
          {this.props.gift.gift_cover === "christmas_1" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_1 {...this.props} />
          )}

          {/* Gift cover #2 */}
          {this.props.gift.gift_cover === "christmas_2" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_2 {...this.props} />
          )}

          {/* Gift cover #3 */}
          {this.props.gift.gift_cover === "christmas_3" && (
            // eslint-disable-next-line
            <Gift_Cover_Christmas_3 {...this.props} />
          )}

          {this.props.gift.gift_cover === "birthday_1" &&
            this.Gift_Cover_Birthday_1({ ...this.props })}

          {this.props.gift.gift_cover === "togetherness" &&
            this.Gift_Cover_Together({ ...this.props })}
        </>
      );
    }
  }
}

export default GiftCover;
