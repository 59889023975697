import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

const StyledCol = styled(Col)`
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-top: 15px;
  }
  width: 50%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
`;

const MobileIMG = styled.img`
  @media (max-width: 600px) {
    border-radius: 0px !important;
  }
`;

let country_galleries = {
  nepal: [
    "https://source.unsplash.com/SGPAuZK0VmE",
    "https://source.unsplash.com/MAYnxlzib10",
    "https://source.unsplash.com/_rHplGon_uU", // Ow4ijRO4ZJw
    "https://source.unsplash.com/NlN_7qd3ubQ",
    "https://source.unsplash.com/BXPda4sRjjs",
  ],
};

class NewGallery extends Component {
  state = {
    gift: {
      location: "madagascar",
    },
  };

  GalleryComponent = (location) => {
    // console.log("this.props.pages: ", this.props.page);

    return (
      <>
        {" "}
        <Row
          style={{
            justifyContent: "center",
          }}
        >
          <div
            style={{
              paddingTop: "15px",
              width: this.props.page === "gifts" ? "90%" : "100%",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
            }}
          >
            <Col
              style={{
                width: "50%",
                minWidth: "200px",
              }}
            >
              <MobileIMG
                // Left
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px 0px 0px 10px",
                }}
                src={country_galleries[location][0]}
              />
            </Col>
            <StyledCol>
              <Row>
                <Col
                  style={{
                    width: "50%",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      paddingBottom: "15px",
                      height: "100%",
                    }}
                    // top left
                    src={country_galleries[location][1]}
                    // "https://images.unsplash.com/photo-1542202229-7d93c33f5d07?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  />
                </Col>
                <Col
                  style={{
                    width: "50%",
                    paddingLeft: "0px",
                  }}
                >
                  <MobileIMG
                    style={{
                      width: "100%",
                      paddingBottom: "15px",
                      borderRadius: "0px 10px 0px 0px",
                      height: "100%",
                    }}
                    // top right
                    src={country_galleries[location][2]}
                    // "https://images.unsplash.com/photo-1516214104703-d870798883c5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    width: "50%",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    //bottom left
                    src={country_galleries[location][3]}
                    // "https://images.unsplash.com/photo-1511432718564-b37b3a906211?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  />
                </Col>
                <Col
                  style={{
                    width: "50%",
                    paddingLeft: "0px",
                  }}
                >
                  <MobileIMG
                    style={{
                      width: "100%",
                      borderRadius: "0px 0px 10px 0px",
                      height: "100%",
                    }}
                    //bottom right
                    src={country_galleries[location][4]}
                    // "https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1651&q=80"
                  />
                </Col>
              </Row>
            </StyledCol>
          </div>
        </Row>{" "}
        {location === "nepal" && (
          <div
            style={{
              textAlign: "center",
              paddingTop: "5px",
              marginBottom: "-10px",
              color: "#00000080",
            }}
          >
            The photo gallery above is a snapshot of{" "}
            {location.charAt(0).toUpperCase() + location.slice(1)}'s natural
            habitat
          </div>
        )}
      </>
    );
  };
  render() {
    return this.GalleryComponent(this.props.gift && this.props.gift.location);
  }
}
export default NewGallery;
