import React, { Component } from "react";
import { Container, Card, Button, Accordion } from "react-bootstrap";
import audit from "./../assets/eden_project_audit.pdf";

class FAQ extends Component {
  render() {
    return (
      <div>
        <br />
        <br />
        <Container>
          <Accordion>
            <h3> General </h3>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  What are Thank You Trees about?
                </Accordion.Toggle>
              </Card.Header>
              {/* 
                1: platform to pay it forward with in-kind gifts
                2: private kickstarter for your relationships
                3: a platform to say thank you
              */}
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  Thank You Trees is a platform to share a climate conscious
                  gift with your friends, family and acquaintances. You can also
                  think of it as your private Kickstarter for your relationships
                  when you want to pay it forward with in-kind gifts.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  When would you use a Thank You Tree?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  For many reasons actually! This can be for praising peer at
                  work, thanking someone for their time and help or even a
                  christmas gift for those who are environmentally conscious.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <br />
          <Accordion>
            <h3> Logistic </h3>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Who plants the trees?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  We work with a fabulous organisation called{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://edenprojects.org/"
                    target="_blank"
                  >
                    The Eden Prject
                  </a>
                  .
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  How do you ensure the trees are planted?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  Our partners are regularly audited by third parties. You can
                  check out{" "}
                  <a href={audit} rel="noopener noreferrer" target="_blank">
                    {" "}
                    this annual audit{" "}
                  </a>{" "}
                  for our Madagascan tree planting project.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  How do I know my trees aren’t cut down?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  That's a great question. On top of our partner hiring guards
                  to safe-keep the newly developed forest, they also take other
                  measures including:
                  <ol>
                    <li>
                      {" "}
                      Provide fair wage employment for workers so they don't
                      need to cut down trees.
                    </li>
                    <li>
                      {" "}
                      Work with local government to create support from the
                      local communities
                    </li>
                    <li>
                      {" "}
                      10% of planted forests are 'Agro Forest Trees'. This means
                      10% of trees planted are for human use (i.e fruit/fuel
                      wood trees/construction wood) that provide an income for
                      the community.{" "}
                    </li>
                    <li>
                      {" "}
                      Local ownership and pride. In Nepal, land is given to
                      local community and reforested. This brings ownership to
                      the people to take care and feel proud in their work.{" "}
                    </li>
                  </ol>
                  For more information, you can check out{" "}
                  <a
                    href="https://youtu.be/k_EOvBkcYjU?t=530"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    a short video
                  </a>{" "}
                  by our partner explaining this in more details.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  When will the tree be planted?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  This varies on on the region you have chosen and the time of
                  year. Planting usually happens during the rainy season, when
                  the soil provides the best conditions for tree survival.
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  How can I contact you?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  {" "}
                  You can contact us on our{" "}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2sjRt380cxdi3q9C-JbTYThxm7NvEqXQfokBvTc0vYqFoPA/viewform?usp=sf_link">
                    contact page
                  </a>
                  , or you can reach out to our founder on
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/fadifessa"
                  >
                    {" "}
                    Twitter
                  </a>
                  .
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
        <br />
        <br />
      </div>
    );
  }
}

export default FAQ;
