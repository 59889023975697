import React, { Component } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

// Gift images
/** MADAGASCAR */
// https://commons.wikimedia.org/wiki/File:Lowland_rainforest,_Masoala_National_Park,_Madagascar_(4026784053).jpg
import md_1 from "../assets/md_1.jpg";
// https://www.pxfuel.com/en/free-photo-ohsci
import md_2 from "../assets/md_2.jpg";
// https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Anjajavyforestrazorback.jpg/640px-Anjajavyforestrazorback.jpg
import md_3 from "../assets/md_3.jpg";
// https://en.wikipedia.org/wiki/File:Madagascar_Deforestation.jpg
import md_4 from "../assets/md_4.jpg";

/** NEPAL */
// https://en.wikipedia.org/wiki/File:Nepal,_2006._Photo-_AusAID_(10709569594).jpg
import np_1 from "../assets/np_1.jpg";
// https://commons.wikimedia.org/wiki/File:Forest_near_CHANGU_NARAYAN_Temple,_Nepal.jpg
import np_2 from "../assets/np_2.jpg";
// https://en.wikipedia.org/wiki/File:Red_Panda.JPG
import np_3 from "../assets/np_3.jpg";
// https://pixabay.com/photos/nepal-annapurna-trekking-forest-978323/
import np_4 from "../assets/np_4.jpg";

/** HAITI */
// https://commons.wikimedia.org/wiki/File:View_of_Haitian_Landscape_hispaniola.jpg
import ht_1 from "../assets/ht_1.jpg";
// https://www.pxfuel.com/en/free-photo-eohhz
import ht_2 from "../assets/ht_2.jpg";
// https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Anjajavyforestrazorback.jpg/640px-Anjajavyforestrazorback.jpg
import ht_3 from "../assets/ht_3.jpg";
// https://www.flickr.com/photos/reverend_anath/4337907453/in/photolist-58JLko-7cwfbu-7BjUKP-2g5q5C7
import ht_4 from "../assets/ht_4.jpg";
import ReactPlayer from "react-player";

const GalleryCSS = styled.span`
  // Variables
  $black: #111;
  //   $radius: 10px;
  //   $transition: all 0.25s ease-in-out;

  html,
  body {
    min-height: 100%;
    height: 100%;
  }

  html {
    font-size: 16px;
  }

  body {
    position: relative;
    font-size: 100%;
  }

  .gallery-card {
    position: relative;
    overflow: hidden;
  }

  .gallery-thumbnail {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .card-icon-open {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease-in-out;

    &:focus,
    &:hover {
      color: #111;
    }
  }

  .gallery-thumbnail:focus ~ .card-icon-open,
  .gallery-thumbnail:hover ~ .card-icon-open,
  .gallery-thumbnail ~ .card-icon-open:focus,
  .gallery-thumbnail ~ .card-icon-open:hover {
    opacity: 1;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(21, 21, 21, 0.75);
  }

  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    padding: 0;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 1.5rem;
    color: #111;
    transition: all 0.25s ease-in-out;

    &:focus,
    &:hover {
      color: #fff;
    }
  }
`;

let md_pics = [md_1, md_2, md_3, md_4];
let np_pics = [np_1, np_2, np_3, np_4];
let ht_pics = [ht_1, ht_2, ht_3, ht_4];

// Component for gallery image
class GalleryImage extends Component {
  render() {
    return (
      <img
        className={this.props.className}
        src={this.props.src}
        alt={this.props.alt}
      />
    );
  }
}

// Component for gallery modal
class GalleryModal extends Component {
  render() {
    if (this.props.isOpen === false) {
      return null;
    }

    return (
      <Modal
        size="lg"
        show={this.props.isOpen}
        onHide={() => this.props.onClick()}
        dialogClassName="modal-overlay"
      >
        <div
          className="modal-body"
          style={{
            color: "white",
            position: "absolute",
            right: "8px",
            fontSize: "1.5rem",
          }}
        >
          <FaTimes
            style={{ cursor: "pointer" }}
            onClick={() => this.props.onClick()}
          />
        </div>
        <div
          style={{ border: "none" }}
          className="modal-overlay"
          name={this.props.name}
        >
          <div className="">
            <img style={{ width: "100%" }} alt="" src={this.props.src} />
          </div>
          {this.props.country === "haiti" &&
            this.props.index === 3 && [
              <div
                key={this.props.index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.flickr.com/photos/reverend_anath/4337907453/in/photolist-58JLko-7cwfbu-7BjUKP-2g5q5C7"
                >
                  Tunnel of Green{" "}
                </a>
                &nbsp; by h3xtacy /{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://creativecommons.org/licenses/by-nd/2.0/"
                >
                  CC BY-ND 2.0
                </a>
              </div>,
            ]}
        </div>
      </Modal>
    );
  }
}

// Component for gallery
class Gallery extends Component {
  state = {
    showModal: false,
    url: "",
    index: -1,
  };

  // Function for opening modal dialog
  openModal({ url, index }, e) {
    this.setState({
      showModal: true,
      url: url,
      index,
    });
  }

  // Function for closing modal dialog
  closeModal() {
    this.setState({
      showModal: false,
      url: "",
    });
  }

  render() {
    let imgUrls = [];

    if (this.props.country === "madagascar") {
      imgUrls = md_pics;
    } else if (this.props.country === "nepal") {
      imgUrls = np_pics;
    } else if (this.props.country === "haiti") {
      imgUrls = ht_pics;
    }

    return (
      <div
        style={{
          width: "100%",
        }}
        id="ecosystem"
      >
        <h3 style={{ marginTop: "1.5em" }}>
          <span>Natural habitat in </span>
          <span className="gift_titles">
            {this.props.country &&
              this.props.country.charAt(0).toUpperCase() +
                this.props.country.slice(1)}{" "}
            {"🌳🦜"}
          </span>
        </h3>
        <hr />
        <GalleryCSS>
          <div className="container-fluid">
            <div style={{ justifyContent: "space-around" }} className="row">
              {imgUrls.map((url, index) => {
                return (
                  <div
                    key={url}
                    style={{
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    }}
                    className="col-sm-3 col-md-3 col-xl-3 gallery_photo"
                  >
                    <div
                      className="gallery-card"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => this.openModal({ url, index }, e)}
                    >
                      <GalleryImage
                        className="gallery-thumbnail"
                        src={url}
                        alt={"Image number " + (index + 1)}
                      />

                      <span
                        className="card-icon-open fa fa-expand"
                        value={url}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          {/* Nepal */}
          {this.props.country && this.props.country === "nepal" && (
            <>
              <p>
                {" "}
                The Chitwan National Park is one of the reforestation
                destinations in Nepal (one of the locations of your gift).{" "}
              </p>{" "}
              <p>
                This national park is home to a healthy population of lemurs,
                rhinos, elephants and around 500 species of birds! You can hear
                some of them below.
              </p>
              <ReactPlayer
                url="https://soundcloud.com/wildambience/jungle-book-forest-chitwan"
                style={{ width: "100%!important" }}
                width=""
                height={150}
              />
            </>
          )}

          {/* Madagascar */}
          {this.props.country && this.props.country === "madagascar" && (
            <>
              <p>
                {" "}
                The Andasibe-Mantadia National Park in Madagascar borders the
                reforestation locations where your gifts will be planted.{" "}
              </p>{" "}
              {/* https://www.wildlifeworldwide.com/locations/andasibe-mantadia-national-park */}
              <p>
                This is home to a healthy population of Indri lemurs (second
                image above), Madagascar falcon, Madagascar long-eared owl and
                the Madagascar wagtail. You can hear the calls of Indri lemurs
                below.
              </p>
              <ReactPlayer
                url="https://soundcloud.com/eddrewitt/indri-at-andasibe-mantadia-national-park-madagascar"
                style={{ width: "100%!important" }}
                width=""
                height={150}
              />
            </>
          )}

          {/* Haiti */}
          {this.props.country && this.props.country === "haiti" && (
            <>
              <p>
                {" "}
                During the dry season, local Haitians gather native seeds and
                prepare them in nurseries. When the raining season begins they
                quickly plant all the trees in the field.
              </p>{" "}
              <p>
                {" "}
                Steve Fitch from Eden Projects, the reforestation partner of
                Thank You Trees, explains in the video below the impact of
                planting trees in the Haitian society and habitat.{" "}
              </p>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=If_1_75MJqw"
                style={{ width: "100%!important" }}
                width=""
                height={350}
                controls={true}
              />
            </>
          )}
          <GalleryModal
            isOpen={this.state.showModal}
            onClick={() => this.closeModal()}
            src={this.state.url}
            country={this.props.country}
            index={this.state.index}
          />
        </GalleryCSS>
      </div>
    );
  }
}

export default Gallery;
