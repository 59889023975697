import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import axios from "axios";

// Config
import { config } from "./config.js";
import { collectIdsAndDocs } from "./Utils/CollectIdsAndDocs.jsx";

// console.log("config: ", config.firebase);

firebase.initializeApp(config.firebase);
export const firestore = firebase.firestore;
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const signOut = () => auth.signOut();
export const sendPasswordResetEmail = ({ email, actionCodeSettings }) =>
  auth.sendPasswordResetEmail(email, actionCodeSettings);
export const signInWithEmailAndPassword = ({ email, password }) =>
  auth.signInWithEmailAndPassword(email, password);

window.firebase = firebase;

export const createNewUserProfileDocument = async (user, additionalData) => {
  if (!user) return;

  // see if user has prof is db
  // get a reef place in the db where the user profile might be
  const userRef = db.doc(`users/${user.uid}`);

  // Go and fetch the document from that locatioon
  const snapshot = await userRef.get();

  if (true) {
    const { display_name, email, photoURL } = user;
    const created_at = new Date();

    // console.log("createUserProfileDocument/user: ", user);

    try {
      await userRef
        .set({
          display_name,
          tree_credits: 0,
          tree_num: 0,
          email,
          credit_history: [],
          photo_url: photoURL,
          created_at,
          credit_questions: [],
          ...additionalData,
        })
        .then((rep) => {
          axios.post(`https://the-tree-project-server.firebaseapp.com/AASDFA`, {
            display_name: additionalData.display_name,
          });
        });
    } catch (error) {
      console.error("Error creating user: ", error.message);
    }
  }

  return getUserDocument(user.uid);
};

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) return;

  // see if user has prof is db
  // get a reef place in the db where the user profile might be
  const userRef = db.doc(`users/${user.uid}`);

  // Go and fetch the document from that locatioon
  const snapshot = await userRef.get();
  // console.log("SNAPSHOT: ", snapshot.exists);

  // previously this ('!snapshot.exists')
  if (true) {
    const { email, photoURL } = user;
    // console.log("snapshot #0");

    try {
      await db
        .collection("gifts")
        .where("buyer_email", "==", email)
        // .limit(10)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            // console.log("onSnapshot empty #1");
          }

          let gifts = snapshot.docs.map(collectIdsAndDocs);

          // console.log(`gifts: for email: ${email}`, gifts.length);
          let tree_num_gift_buyer = gifts.reduce(
            (sum, x) => Number(sum) + Number(x.supporters[0].quantity),
            0
          );

          db.collection("supporters")
            .where("email", "==", email)
            // .limit(10)
            .onSnapshot((snapshot) => {
              if (snapshot.empty) {
                // console.log("onSnapshot empty #2");
                // console.log("New account: No supported gifts ");
              }
              let contributions = snapshot.docs.map(collectIdsAndDocs);
              let tree_num_gift_contributor = contributions.reduce(
                (sum, x) => Number(sum) + Number(x.quantity),
                0
              );
              // console.log("tree_num_gift_buyer: ", tree_num_gift_buyer);

              // console.log(
              //   "tree_num_gift_contributor: ",
              //   tree_num_gift_contributor
              // );
              userRef.update({
                last_login: new Date(),
                tree_num: tree_num_gift_buyer + tree_num_gift_contributor,
                gift_num: gifts.length,
                photo_url: photoURL,
                ...additionalData,
              });
            });
        });
    } catch (error) {
      console.error("Error creating user: ", error.message);
    }
  }

  return getUserDocument(user.uid);
};

export const getUserDocument = async (uid) => {
  if (!uid) return null;

  // console.log("getUserDocument", uid);
  try {
    return db.collection("users").doc(uid);
  } catch (error) {
    console.error("Error fetching user: ", error.message);
  }
};
