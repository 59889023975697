import React, { Component } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const Supporter = styled.li`
  margin-top: 10px;
  .mr {
    margin-right: 1rem !important;
  }

  .a-avatar {
    border-radius: 50%;
    color: #fff;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 2.5em;
    width: 2.5em;
  }

  .m-person-info {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .m-person-info-name {
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .list-unstyled {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .m-donation-meta .m-meta-list-item,
  .m-donation-meta .m-meta-list-item:not(:first-child):before {
    margin-right: 0.5rem;
  }

  .o-donation-list-item:not(:last-child) {
    padding-bottom: 0.75rem;
  }

  .o-donation-list-item:not(:first-child) {
    border-top: 1px solid #ddd;
    padding-top: 0.75rem;
  }
`;

const abbr = (time_ago) => {
  // console.log(time_ago)
  if (time_ago === "in an hour") {
    return "1h";
  } else if (
    time_ago.split(" ")[0] === "in" &&
    time_ago.split(" ")[2] === "minutes"
  ) {
    return time_ago.split(" ")[1] + "mins";
  }

  let time_ago_str = time_ago.split(" ");

  // 12 days ago
  if (time_ago_str[1][0] === "h") {
    return "today";
  }
  // - a month ago
  // - a few seconds ago
  else if (time_ago_str[0][0] === "a") {
    if (time_ago_str.length === 4) {
      return "today";
    } else {
      return "1" + time_ago_str[1][0];
    }
  } else {
    return time_ago_str[0] + time_ago_str[1][0];
  }
};

class SupportList extends Component {
  render() {
    return (
      <div id="community" style={{ width: "100%" }}>
        <h3 style={{ marginTop: "1.5em" }}>
          {" "}
          Community{" "}
          <span role="img" aria-label="community emoji">
            🙋‍♀️ 🙋‍♂️
          </span>
        </h3>
        <hr />
        <p>Below is your community who have planted trees in your forrest.</p>
        <ul className="o-campaign-sidebar-donations list-unstyled o-donation-list">
          {this.props.community && [
            this.props.community
              .filter((person) => person.quantity > 0)
              .map((person) => (
                <Supporter
                  className="o-donation-list-item"
                  key={`${person.name}-${person.quantity}`}
                >
                  <div className="m-donation m-person-info">
                    <img
                      className="a-default-avatar m-person-info-avatar mr a-avatar a-base-image"
                      alt=""
                      src="https://www.gofundme.com/static/media/DefaultAvatar.4bb188e1d41df75419450a820a958679.svg"
                    />
                    <div>
                      <div className="o-person-info-name">
                        <strong>{person.name} &nbsp;</strong>
                      </div>
                      <div className="o-person-info-content">
                        <ul className="o-donation-meta list-unstyled m-meta-list m-meta-list--bullet">
                          <li className="m-meta-list-item">
                            <span className="weight-900">
                              {person.quantity} trees •{" "}
                              <span className="color-gray">
                                {person.version === "v2"
                                  ? abbr(dayjs(person.date).fromNow())
                                  : abbr(dayjs(person.date.toDate()).fromNow())}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Supporter>
              )),
          ]}
        </ul>
      </div>
    );
  }
}

export default SupportList;
