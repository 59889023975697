/* eslint-disable */

import React, { Component } from "react";
import { Container } from "react-bootstrap";

class About extends Component {
  render() {
    return (
      <div>
        <section className="py-5">
          <Container>
            <span>
              <h1 className="font-weight-light">Hi, there 👋</h1>
              <p className="lead">
                Thank You Trees was born out of a painful experience of buying
                Thank You gifts online. So we created a product to help you
                find exciting, impactful and zero-waste gifts.
              </p>
            </span>
            <br />
            <h1 className="font-weight-light">Our mission</h1>
            <p className="lead">
              {" "}
              <a
                target="_blank"
                href="https://theweek.com/articles/441360/brief-history-christmas-present"
              >
                Eighteen percent{" "}
              </a>
              of gifts are never used by the person who receives them and{" "}
              <a target="_blank" href="https://www.finder.com/unwanted-gifts">
                {" "}
                4 percent{" "}
              </a>
              are thrown away. We want to bring you exciting experiences to
              offer your friends, family and acquaintances. Thank You Trees works
              with reforestation partners that have the best track records and
              maintain transparency to make sure your trees are always looked
              after.
            </p>

            <p className="lead">
              Finding zero-waste gifts that delivered a great experience is
              personal. Our founder struggled with the same issues you’re having
              today - spending a lot of money on something he wasn't sure would
              bring value, when he'd rather support climate change.
              {/* Read about his journey here. */}
            </p>
            <p className="lead">
              If you have suggestions for how we can make your experience
              better,{" "}
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe2sjRt380cxdi3q9C-JbTYThxm7NvEqXQfokBvTc0vYqFoPA/viewform"
              >
                we’re all ears
              </a>
              .
            </p>

            {/* <p className="lead">
              This small act of kindness meant the world for him. Since the
              people he wanted to thank were in different cities, and he wasn't
              a strong writer, he knew he needed a way to show his gratitude.
              Fady wanted to make a gesture that would{" "}
              <strong> reflect his personality, be affordable</strong> at this
              delicate time and just{" "}
              <strong>show how much their help meant</strong> to him.
            </p>
            <p className="lead">
              After donating a couple of trees online through some amazing
              reforestation charities, he found the current online experience
              lacking since he was doing a lot of extra explanation when sharing
              their gifts.
            </p>
            <p className="lead">
              So Fady decided to build a better experience around sharing
              affordable, and meaningful gifts. His vision for Thank You Trees, is
              to be the <strong>go-to platform </strong>
              for small and meaningful gifts. In particular for those who care
              about the impact that a small gift can make with climate change
              and sustainability.
            </p> */}
          </Container>
        </section>
      </div>
    );
  }
}

export default About;
