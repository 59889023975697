import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{ marginTop: "0px!important" }}
      className="container pt-4 pt-md-5 border-top"
    >
      <div className="row">
        <div className="col-12 col-md">
          Thank You Trees{" "}
          <img
            className="mb-2"
            src="https://www.openmoji.org/data/color/svg/1F333.svg"
            alt=""
            width="24"
            height="24"
          />
          <small className="d-block mb-3 text-muted">&copy; 2020</small>
        </div>
        <div className="col-6 col-md">
          <h5>Resources</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://www.notion.so/fadyazmy/Thank-You-Trees-Track-Your-Forests-0b1fe1460dc3401485a74cbf0245635d"
              >
                Track Your Forest
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://www.notion.so/fadyazmy/Thank-You-Trees-Themes-a9fd41d7529d4e1caad5017a53255166"
              >
                Gift Themes
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="/messages"
              >
                Messages
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe2sjRt380cxdi3q9C-JbTYThxm7NvEqXQfokBvTc0vYqFoPA/viewform?usp=sf_link"
              >
                Contact
              </a>
            </li>

            <li>
              <a
                rel="noopener noreferrer"
                className="text-muted"
                target="_blank"
                href="https://www.notion.so/fadyazmy/Thank-You-Trees-FAQ-e5e21a5e506f4967b44e76bd4d211a29"
              >
                FAQ
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://www.notion.so/fadyazmy/Thank-You-Tree-Slack-App-d67c1aaeddb1408ab28bd00f246f498e"
              >
                Slack <sup style={{ color: "blue" }}>coming soon</sup>
              </a>
            </li> */}
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>Legal</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://www.notion.so/fadyazmy/Privacy-Policy-1e0f35f0d4b944f98320416f4b4d0e5e"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted"
                href="https://www.notion.so/fadyazmy/Thank-You-Trees-Terms-a9c5e443416640d5aeab0d54af0094b1"
              >
                Terms and Conditions
              </a>
            </li>
          </ul>
        </div>
      </div>
      <br />
    </footer>
  );
};

export default Footer;
