import React, { Component } from "react";
import {
  Container,
  Card,
  Button,
  ButtonGroup,
  Jumbotron,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";

import { UserContext } from "../Providers/UserProvider";
import { collectIdsAndDocs } from "../Utils/CollectIdsAndDocs";
import { db, firestore } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

const Toggle = styled(Dropdown.Toggle)`
  :after {
    display: none;
  }
`;
class GiftPurchases extends Component {
  static contextType = UserContext;
  state = {
    purchased_gifts: [],
    received_gifts: [],
    checked_purchased: false,
    checked_received: false,
    total: 0,
    gift_edits: {},
    gift_type: "purchased",
    // states for invitation flow
    showModal: false,
    invitation_load: false,
    invitation_code: "",
    gift: {},
  };

  onChangeHandle = (e) => {
    let gift_num = e.target.id;
    let purchased_gifts = this.state.purchased_gifts;
    purchased_gifts[gift_num]["message"] = e.target.value;
    this.setState({ purchased_gifts });
  };

  getTimeFromNow = (time) => {
    return dayjs(time.toDate()).fromNow();
  };

  isInvitationValid = (gift) => {
    let time_ago = this.getTimeFromNow(gift.invitation.expiry);
    return time_ago.split(" ").indexOf("ago") === -1;
  };

  render() {
    if (this.context && this.context.email) {
      if (!this.state.checked_purchased) {
        db.collection("gifts")
          .where("buyer_email", "==", this.context.email)
          // .where("env", "==", "prod")
          // .orderBy("createdAt", "desc") <-- not working because "createdAt" is saved as string not timestamp
          .limit(10)
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              // console.log("No purchased emails.");
              this.setState({ checked_purchased: true });
              return;
            }

            let purchased_gifts = snapshot.docs.map(collectIdsAndDocs);
            this.setState({
              purchased_gifts,
              checked_purchased: true,
            });
          });
      }

      if (!this.state.checked_received) {
        db.collection("gifts")
          .where("recipient_email", "==", this.context.email)
          .limit(10)
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              // console.log("No recipient email.");
              this.setState({ checked_received: true });
              return;
            }

            let received_gifts = snapshot.docs.map(collectIdsAndDocs);
            this.setState({
              received_gifts,
              checked_received: true,
            });
          });
      }
    }

    return (
      <>
        <ToastContainer />
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create invitation link </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Invitations allow others to contribute a message with or without
            planting trees.
          </Modal.Body>

          {this.state.gift &&
          this.state.gift.invitation &&
          this.isInvitationValid(this.state.gift) ? (
            this.state.gift.invitation.code.length > 0 && (
              <Row
                style={{
                  marginRight: "1rem",
                  marginLeft: "1rem",
                  backgroundColor: "#dcdc8a",
                  alignContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <span
                  style={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <span>
                    <strong>
                      Link (expires {" "}
                      {this.getTimeFromNow(this.state.gift.invitation.expiry)}):{" "}
                    </strong>
                  </span>{" "}
                  <span>
                    <a
                      target="_blank"
                      href={`/gifts/${this.state.gift.id}?invitation_code=${this.state.gift.invitation.code}`}
                    >
                      {`thankyoutrees.io/gifts/${this.state.gift.id}?invitation_code=${this.state.gift.invitation.code}`}
                    </a>
                  </span>
                </span>
              </Row>
            )
          ) : this.state.gift && this.state.gift.invitation ? (
            <Row
              style={{
                marginRight: "1rem",
                marginLeft: "1rem",
                backgroundColor: "#dcdc8a",
                alignContent: "center",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <span>
                  <strong>
                    {this.state.gift.invitation && [
                      `Link (expires `,
                      this.getTimeFromNow(this.state.gift.invitation.expiry),
                      "):",
                    ]}{" "}
                  </strong>
                </span>{" "}
                <span>
                  <a
                    target="_blank"
                    href={`/gifts/${this.state.gift.id}?invitation_code=${this.state.gift.invitation.code}`}
                  >
                    {`thankyoutrees.io/gifts/${this.state.gift.id}?invitation_code=${this.state.gift.invitation.code}`}
                  </a>
                </span>
              </span>
            </Row>
          ) : (
            <Row
              style={{
                marginRight: "-0px",
                marginLeft: "0px",
                justifyContent: "center",
              }}
            >
              <Button
                variant="primary"
                onClick={() => {
                  // invitation = first three letters of name + 3 numbers
                  // this.context.display_name.toLowerCase()
                  Date.prototype.addDays = function (days) {
                    var date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                  };

                  var date = new Date();

                  let random_num = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
                  let invitation_code =
                    this.state.gift.location +
                    "_" +
                    [...Array(random_num).keys()].splice(1).join("");
                  this.setState({ invitation_code: invitation_code });

                  // https://www.iditect.com/how-to/53482750.html
                  db.collection("gifts")
                    .doc(this.state.gift.id)
                    .update({
                      invitation: {
                        code: invitation_code,
                        expiry: firestore.Timestamp.fromDate(date.addDays(7)),
                      },
                    })
                    .then((resp) => {
                      db.collection("gifts")
                        .where("buyer_email", "==", this.context.email)
                        // .where("env", "==", "prod")
                        .limit(10)
                        .onSnapshot((snapshot) => {
                          if (snapshot.empty) {
                            // console.log("No purchased emails.");
                            this.setState({ checked_purchased: true });
                            return;
                          }

                          let purchased_gifts =
                            snapshot.docs.map(collectIdsAndDocs);

                          let temp_gift = this.state.gift;
                          temp_gift.invitation = {
                            code: invitation_code,
                            expiry: firestore.Timestamp.fromDate(
                              date.addDays(7)
                            ),
                          };

                          this.setState({
                            purchased_gifts,
                            gift: temp_gift,
                          });
                        });
                    });
                }}
              >
                Create invitation
              </Button>
            </Row>
          )}

          <br />
        </Modal>
        {this.context &&
          this.context.tree_num >= 0 && [
            <Container key="gift_container">
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ marginTop: "1.5em" }}> My Gifts 🎁</h3>

                  <ButtonGroup
                    style={{ height: "fit-content", marginTop: "auto" }}
                    size="lg"
                    className="mb-2"
                  >
                    <Button
                      style={{
                        width: "120px",
                      }}
                      onClick={() => {
                        this.setState({
                          gift_type: "purchased",
                        });
                      }}
                      variant={`${
                        this.state.gift_type === "purchased" ? "info" : "light"
                      }`}
                    >
                      Purchased
                    </Button>
                    <Button
                      style={{
                        width: "120px",
                      }}
                      onClick={() => {
                        this.setState({
                          gift_type: "received",
                        });
                      }}
                      variant={`${
                        this.state.gift_type === "received" ? "info" : "light"
                      }`}
                    >
                      Received
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
              <hr />

              <div>
                {this.state.gift_type === "purchased" &&
                  this.state.checked_purchased &&
                  (this.state.purchased_gifts.length > 0 ? (
                    this.state.purchased_gifts.map((gift, idx) => (
                      <Card key={gift.id} style={{ marginBottom: "25px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body
                          style={{
                            backgroundColor: "beige",
                          }}
                        >
                          <Card.Title>
                            {" "}
                            <a
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                              href={`/gifts/${gift.id}`}
                            >
                              <span style={{ fontSize: "1.5rem" }}>
                                {" "}
                                {gift.recipient_name}{" "}
                              </span>
                            </a>
                          </Card.Title>
                          {!this.state.gift_edits[idx] ? (
                            <>
                              <Card.Text>{gift.message}</Card.Text>{" "}
                              <Button
                                size="md"
                                id={idx}
                                onClick={(e) => {
                                  let gift_num = e.target.id;
                                  let gift_edits = this.state.gift_edits;
                                  gift_edits[gift_num] = !gift_edits[gift_num];
                                  this.setState({ gift_edits });
                                }}
                                variant="primary"
                              >
                                Edit
                              </Button>{" "}
                            </>
                          ) : (
                            <>
                              <Card.Text>
                                <textarea
                                  id={idx}
                                  type="text"
                                  onChange={this.onChangeHandle}
                                  // name="gift"
                                  value={gift.message}
                                  style={{
                                    border: "none",
                                    color: "black",
                                    backgroundColor: "#f7f7f7",
                                    width: "100%",
                                  }}
                                />
                              </Card.Text>
                              <Button
                                size="md"
                                id={idx}
                                onClick={(e) => {
                                  let gift_num = e.target.id;
                                  let gift_edits = this.state.gift_edits;
                                  gift_edits[gift_num] = !gift_edits[gift_num];
                                  this.setState({ gift_edits });
                                }}
                                variant="secondary"
                              >
                                Discard
                              </Button>{" "}
                              <Button
                                size="md"
                                id={idx}
                                onClick={(e) => {
                                  let gift_num = e.target.id;
                                  db.collection("gifts")
                                    .doc(
                                      this.state.purchased_gifts[gift_num]["id"]
                                    )
                                    .update({
                                      message:
                                        this.state.purchased_gifts[gift_num][
                                          "message"
                                        ],
                                    })
                                    .then((resp) => {
                                      // console.log("RESP: ", resp);
                                      let gift_edits = this.state.gift_edits;
                                      gift_edits[gift_num] = false;
                                      toast("Message updated!", {
                                        type: "success",
                                      });
                                      this.setState({ gift_edits });
                                    });
                                }}
                                variant="success"
                              >
                                Save
                              </Button>
                            </>
                          )}
                        </Card.Body>
                        <div
                          style={{
                            position: "absolute",
                            top: "16px",
                            right: "20px",
                          }}
                        >
                          <Dropdown>
                            <Toggle
                              style={{
                                backgroundColor: "#f4f5dc",
                                borderColor: "#f4f5dc",
                                color: "black",
                              }}
                              variant="success"
                              id="dropdown-basic"
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                />
                              </svg>
                            </Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  // gift.id
                                  // console.log("Selected: ", gift);
                                  this.setState({
                                    showModal: true,
                                    selected_gift_index: idx,
                                    gift: gift,
                                  });
                                }}
                              >
                                Create invitation link
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <Card.Footer
                          style={{
                            backgroundColor: "#e0e0e0",
                          }}
                          // className="text-muted"
                        >
                          {gift.location.charAt(0).toUpperCase() +
                            gift.location.slice(1)}{" "}
                          - {gift.quantity} trees
                        </Card.Footer>
                      </Card>
                    ))
                  ) : (
                    <Jumbotron
                      style={{
                        backgroundColor: "#e0dac6",
                      }}
                    >
                      {" "}
                      <Container>
                        <h1>No gifts purchased</h1>
                        <p>
                          Claim a Tree Credit from the credits page to start
                          buying gifts carbon-free!
                        </p>
                      </Container>{" "}
                    </Jumbotron>
                  ))}

                {this.state.gift_type === "received" &&
                  this.state.checked_received &&
                  (this.state.received_gifts.length > 0 ? (
                    this.state.received_gifts.map((gift, idx) => (
                      <Card key={gift.id} style={{ marginBottom: "25px" }}>
                        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                        <Card.Body
                          style={{
                            backgroundColor: "beige",
                          }}
                        >
                          <Card.Title>
                            {" "}
                            <a
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                              href={`/gifts/${gift.id}`}
                            >
                              <span style={{ fontSize: "1.5rem" }}>
                                {" "}
                                {gift.buyer_name}{" "}
                              </span>
                            </a>
                          </Card.Title>
                          <Card.Text>{gift.message}</Card.Text>{" "}
                        </Card.Body>
                        <div
                          style={{
                            position: "absolute",
                            top: "16px",
                            right: "20px",
                          }}
                        >
                          <Dropdown>
                            <Toggle
                              style={{
                                backgroundColor: "#f4f5dc",
                                borderColor: "#f4f5dc",
                                color: "black",
                              }}
                              variant="success"
                              id="dropdown-basic"
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                                />
                              </svg>
                            </Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  // gift.id
                                  // console.log(
                                  //   "Selected: ",
                                  //   gift,
                                  //   "\nVS (index: ",
                                  //   idx,
                                  //   ")",
                                  //   "\n received_gifts: ",
                                  //   this.state.received_gifts[Number(idx)]
                                  // );
                                  this.setState({
                                    showModal: true,
                                    selected_gift_index: idx,
                                    gift: gift,
                                  });
                                }}
                              >
                                Create invitation link
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <Card.Footer
                          style={{
                            backgroundColor: "#e0e0e0",
                          }}
                          // className="text-muted"
                        >
                          {gift.location.charAt(0).toUpperCase() +
                            gift.location.slice(1)}{" "}
                          - {gift.quantity} trees
                        </Card.Footer>
                      </Card>
                    ))
                  ) : (
                    <Jumbotron
                      style={{
                        backgroundColor: "#fdf0d5",
                      }}
                    >
                      {" "}
                      <Container>
                        <h1>No gifts received</h1>
                        <p>You're awesome, and no-one can take that away :)</p>
                      </Container>{" "}
                    </Jumbotron>
                  ))}
              </div>
            </Container>,
          ]}
      </>
    );
  }
}

export default withRouter(GiftPurchases);
