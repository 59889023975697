import React, { Component } from "react";
import { UserContext } from "../../Providers/UserProvider";
import user_icon from "../../assets/user_icon.png";

class YourNetwork extends Component {
  static contextType = UserContext;

  state = {};

  process_network = (gifts) => {
    let network = {};
    let arr = [];
    gifts.map((gift) => {
      if (network[gift.recipient_name]) {
        network[gift.recipient_name].count++;
      } else {
        network[gift.recipient_name] = { count: 1 };
      }

      return null;
    });
    for (let [k, v] of Object.entries(network)) {
      arr.push({ name: k, count: v.count });
    }
    return arr;
  };

  render() {
    return (
      <>
        <h3>Your network </h3>
        <hr
          style={{
            height: "3px",
            backgroundColor: "#e5e5e5",
          }}
        />
        <div>
          {this.process_network(this.props.purchased_gifts).length == 0 &&
            "No gifts have been purchased yet."}
          {this.process_network(this.props.purchased_gifts).map((val, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                flexFlow: "row",
                paddingTop: idx > 0 ? "10px" : "0px",
              }}
            >
              {" "}
              <span>
                <img
                  alt="user icon"
                  style={{
                    width: "50px",
                  }}
                  src={user_icon}
                />
              </span>{" "}
              <span
                style={{
                  display: "flex",
                  flexFlow: "column",
                  paddingLeft: "10px",
                }}
              >
                {" "}
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {val.name}
                </span>{" "}
                <span>
                  {" "}
                  {val.count > 1
                    ? `${val.count} gifts`
                    : `${val.count} gift`}{" "}
                </span>
              </span>
            </div>
          ))}
          <br />
        </div>
      </>
    );
  }
}

export default YourNetwork;
