import React, { Component, Fragment } from "react";
import { Container, Card, Button } from "react-bootstrap";
import FileUploader from "react-firebase-file-uploader";

import { UserContext } from "../Providers/UserProvider";
// import OnboardingForm from "../Components/OnboardingForm";
// import { collectIdsAndDocs } from "../Utils/CollectIdsAndDocs";
import { db, storage } from "../firebase";
import { firestore } from "firebase";
import OnboardingForm from "../Components/OnboardingForm";

let credit_list = [
  {
    q_id: "q1",
    title: "Send a thank you message (2 trees)",
    body:
      "Thank someone for being awesome, and upload a screenshot of your message. We trust they'll be delighted by your gesture and we'll award you with 2 Tree Credits!",
    q_value: 2,
  },
  {
    q_id: "q2",
    title: "Answer a 30 second survey (2 trees)",
    body:
      "Let us know how we can do a better job in making your gifts more special, we'll award you with 2 Tree Credits.",
    q_value: 2,
  },
];

class Credits extends Component {
  static contextType = UserContext;
  state = {
    credits: 0,
    image: null,
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
    q_id: "",
    q_value: 0,
    onboarding_form: false,
  };

  handleUploadStart = (q_id, q_value) => {
    // console.log("ID: ", q_id);
    this.setState({ q_id, q_value, isUploading: true, progress: 0 });
  };
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  showOnboardingForm = () => {
    this.setState({ onboarding_form: false });
  };

  onSubmitSuccess = ({ user, url = "" }) => {
    db.collection("users")
      .doc(user.id)
      .update({
        credit_questions: firestore.FieldValue.arrayUnion({
          id: this.state.q_id,
          url,
          redeemed: new Date(),
        }),
        tree_credits: user.tree_credits + this.state.q_value,
      });
  };

  render() {
    let user = this.context ? this.context : { id: 1, credit_questions: "" };

    const handleUploadSuccess = (filename) => {
      // console.log("filename: ", filename);
      this.setState({ avatar: filename, progress: 100, isUploading: false });

      storage
        .ref(`users/${user.id}/credit_questions/${this.state.q_id}`)
        .child(filename)
        .getDownloadURL()
        .then((url) => {
          db.collection("users")
            .doc(user.id)
            .update({
              credit_questions: firestore.FieldValue.arrayUnion({
                id: this.state.q_id,
                url,
                redeemed: new Date(),
              }),
              tree_credits: user.tree_credits + this.state.q_value,
            });

          this.setState({ avatarURL: url });
        });
    };

    const renderButton = (q_id, q_value) => {
      // console.log("q_id: ", q_id);
      if (q_id === "q1") {
        return (
          <label
            style={{
              backgroundColor: "#28a745",
              color: "white",
              padding: 10,
              borderRadius: 4,
              cursor: "pointer",
            }}
          >
            Claim credit
            <FileUploader
              hidden
              accept="image/*"
              storageRef={storage.ref(
                `users/${user.id}/credit_questions/${q_id}`
              )}
              onUploadStart={() => this.handleUploadStart(q_id, q_value)}
              onUploadError={this.handleUploadError}
              onUploadSuccess={handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </label>
        );
      } else if (q_id === "q2") {
        return (
          <Button
            onClick={() => {
              this.setState({
                onboarding_form: true,
                q_id: q_id,
                q_value: q_value,
              });
            }}
            variant={"success"}
            style={{
              padding: 10,
              borderRadius: 4,
              cursor: "pointer",
            }}
          >
            Claim credit
          </Button>
        );
      }
    };

    return (
      <>
        {this.state.onboarding_form && (
          <OnboardingForm
            showOnboardingForm={this.showOnboardingForm}
            onSubmitSuccess={this.onSubmitSuccess}
            user={user}
          />
        )}
        <Container key="gift_container">
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* // eslint-disable-next-line */}
              <h3 style={{ marginTop: "1.5em" }}> My Tree Credits 🌲</h3>
            </div>
            <p>
              {" "}
              You have{" "}
              <strong>
                {" "}
                {this.context && this.context.tree_credits} Tree Credit
                {this.context && this.context.tree_credits > 1 ? "s" : ""}
              </strong>{" "}
              to use for your next gift purchase.
            </p>
          </div>
          <hr />
          <div>
            {this.context &&
              this.context.id &&
              credit_list.map((val, idx) => (
                <Fragment key={idx}>
                  <Card key={idx}>
                    <Card.Header> Tree Credit offer </Card.Header>
                    <Card.Body>
                      <Card.Title>
                        <span style={{ fontWeight: "600" }}>{val.title}</span>
                      </Card.Title>
                      <Card.Text>{val.body}</Card.Text>

                      {user.credit_questions.filter((a) => a.id === val.q_id)
                        .length > 0 ? (
                        <Button variant="success" disabled>
                          Credit redeemed
                        </Button>
                      ) : (
                        renderButton(val.q_id, val.q_value)
                      )}
                    </Card.Body>
                  </Card>
                  <br />
                </Fragment>
              ))}
          </div>
        </Container>
      </>
    );
  }
}

export default Credits;
