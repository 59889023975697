// jsx-a11y/accessible-emoji
import React, { Component } from "react";
import { Modal, Form, Button, ButtonToolbar } from "react-bootstrap";
import { UserContext } from "../Providers/UserProvider";
import { db } from "../firebase";

class OnboardingForm extends Component {
  state = {
    showModal: true,
    validated: false,
    step: 1,
    prevgift: "",

    answers: {
      occasion: [],
      discover: [],
      prevgift: "",
      created_at: new Date(),
      name: this.context.display_name,
    },
  };
  static contextType = UserContext;

  get onboardingRef() {
    return db.collection("forms").doc(this.context.id);
  }

  get userRef() {
    return db.collection("users").doc(this.context.id);
  }

  submitOnboardingForm = () => {
    console.log("FORM");
    this.onboardingRef
      .set({
        onboarding_form: this.state.answers,
        form_name: "intro_questions",
      })
      .then((resp) => {
        console.log("submitOnboardingForm/RESP")
        this.setState({ validated: true, showModal: false });
        this.props.onSubmitSuccess({ user: this.props.user });
      });
  };

  render() {
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.setState({ validated: true });
    };

    const handleCheckboxChange = (event) => {
      let { name, value } = event.target;
      let category = name.split("_")[0];
      let id_ = name.split("_")[1];
      // console.log("VAL: ", value);

      let answers = this.state.answers;
      if (category === "occasion" || category === "discover") {
        if (answers[category].indexOf(id_) === -1) {
          answers[category] = [...answers[category], id_];
          this.setState({ answers });
        } else {
          answers[category] = answers[category].filter((a) => a !== id_);
          this.setState({ answers });
        }
      } else if (category === "prevgift") {
        answers[category] = value;
        this.setState({ answers });
      }
    };

    let { validated, showModal, step } = this.state;
    let { occasion, discover, prevgift } = this.state.answers;

    // console.log("answers: ", this.state.answers);
    return (
      <>
        {this.context &&
          this.context.id &&
          showModal && [
            <div key="form" className="container">
              <Modal
                size="lg"
                show={showModal}
                keyboard
                onHide={() => {
                  this.setState({ showModal: false });
                  this.props.showOnboardingForm();
                }}
              >
                <Modal.Header>
                  <Modal.Title>
                    Welcome {this.context.display_name}{" "}
                    <span aria-label="" role="img">👋</span>
                  </Modal.Title>
                </Modal.Header>
                {step === 1 && (
                  <Modal.Body>
                    <p className="text-muted">
                      We want to give you the best gifting experience possible.
                      In order to do that, we'd be grateful if you could answer
                      3 short questions.
                    </p>
                    <Form validated={validated} onSubmit={handleSubmit}>
                      <Form.Group
                        style={{
                          fontSize: "large",
                        }}
                        controlId="exampleForm.ControlSelect1"
                      >
                        <Form.Label>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "larger",
                            }}
                          >
                            What occasions are your zero-waste gifts for?
                          </span>
                        </Form.Label>

                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_bday_gitfs"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="occasion_birthday"
                            type="checkbox"
                            label="Birthday gifts"
                            checked={occasion.indexOf("birthday") !== -1}
                          />
                        </Form.Group>

                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_ann_gitfs"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="occasion_anniversary"
                            type="checkbox"
                            label="Anniversary gifts"
                            checked={occasion.indexOf("anniversary") !== -1}
                          />
                        </Form.Group>

                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_ty_gitfs"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="occasion_thankyous"
                            type="checkbox"
                            label="Thank you gifts"
                            checked={occasion.indexOf("thankyous") !== -1}
                          />
                        </Form.Group>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                )}

                {step === 2 && (
                  <Modal.Body>
                    <Form validated={validated} onSubmit={handleSubmit}>
                      <Form.Group
                        style={{
                          fontSize: "large",
                        }}
                        controlId="exampleForm.ControlSelect1"
                      >
                        <Form.Label>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "larger",
                            }}
                          >
                            How did you learn about Thank You Trees?
                          </span>
                        </Form.Label>

                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_search"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="discover_search"
                            type="checkbox"
                            label="Search"
                            checked={discover.indexOf("search") !== -1}
                          />
                        </Form.Group>
                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_social_media"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="discover_socialmedia"
                            type="checkbox"
                            label="Social media"
                            checked={discover.indexOf("socialmedia") !== -1}
                          />
                        </Form.Group>
                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_word_of_mouth"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="discover_wordofmouth"
                            type="checkbox"
                            label="Word of mouth"
                            checked={discover.indexOf("wordofmouth") !== -1}
                          />
                        </Form.Group>
                        <Form.Group
                          style={{
                            marginBottom: "0rem",
                          }}
                          controlId="checkbox_other"
                        >
                          <Form.Check
                            onChange={handleCheckboxChange}
                            name="discover_other"
                            type="checkbox"
                            label="Other"
                            checked={discover.indexOf("other") !== -1}
                          />
                        </Form.Group>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                )}

                {step === 3 && (
                  <Modal.Body>
                    <Form validated={validated} onSubmit={handleSubmit}>
                      <Form.Group
                        style={{
                          fontSize: "large",
                        }}
                        controlId="exampleForm.ControlSelect1"
                      >
                        <Form.Label>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "larger",
                            }}
                          >
                            What was the last gift you bought for someone?
                          </span>
                          <br />
                          <span className="text-muted">
                            It doesn’t have to be a zero-waste gift
                          </span>
                        </Form.Label>
                        <Form.Control
                          value={prevgift}
                          name="prevgift"
                          onChange={handleCheckboxChange}
                          type="text"
                          placeholder="A kiwi"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                )}

                <Modal.Footer style={{ display: "block", textAlign: "center" }}>
                  <ButtonToolbar
                    style={{
                      justifyContent: "center",
                    }}
                    className="mb-2"
                  >
                    {step > 1 && (
                      <Button
                        style={{ width: "40%" }}
                        onClick={() => {
                          this.setState({ step: step - 1 });
                        }}
                        variant="secondary"
                        // size="lg"
                        block
                        active
                      >
                        Back
                      </Button>
                    )}

                    {step < 3 && (
                      <Button
                        style={{ width: "40%", marginLeft: step > 1 && "10px" }}
                        variant="success"
                        disabled={
                          (step === 1 && occasion.length === 0) ||
                          (step === 2 && discover.length === 0)
                            ? true
                            : false
                        }
                        type="submit"
                        //   block
                        onClick={() =>
                          this.setState({ step: this.state.step + 1 })
                        }
                      >
                        Next
                      </Button>
                    )}

                    {step === 3 && (
                      <Button
                        style={{ width: "40%", marginLeft: "10px" }}
                        variant="success"
                        disabled={
                          occasion.length > 0 &&
                          discover.length > 0 &&
                          prevgift.length > 0
                            ? false
                            : true
                        }
                        type="submit"
                        onClick={() => this.submitOnboardingForm()}
                      >
                        Done
                      </Button>
                    )}
                  </ButtonToolbar>
                </Modal.Footer>
              </Modal>
            </div>,
          ]}
      </>
    );
  }
}
export default OnboardingForm;
