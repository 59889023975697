/* eslint-disable react/jsx-no-target-blank */

import React, { Component, useEffect, useContext } from "react";
import {
  Row,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  ButtonGroup,
  Button,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import { Switch, Route, Link, NavLink } from "react-router-dom";

import ReactGA from "react-ga";

// Config
import { config } from "../config.js";

// firebase
import { db, signOut } from "../firebase";
import { UserContext } from "../Providers/UserProvider";

import "../App.css";
import Gifts from "./Gifts.js";
import FAQ from "./Faq.jsx";
import About from "./About.jsx";
import HowItWorks from "../Components/HowItWorks.jsx";
import Slack from "../Components/Slack.jsx";
import Footer from "../Components/Footer.jsx";
import GiftingEngine from "../Components/GiftingEngine.jsx";

import History from "./History";
import Messages from "./Messages";

// import bg_img from "../assets/background_img.png";
import Admin from "./Admin";
import SignIn from "../Components/SignIn";
import Country from "./Country.jsx";
import GiftPurchases from "./GiftPurchases.jsx";

import PrivateRoute from "../Utils/PrivateRoute";
// import SignUp from "./SignUp.jsx";
import Credits from "./Credits.jsx";
import DemoProfile from "./DemoProfile.jsx";

import styled from "styled-components";
import Profile from "../Components/Profile.jsx";
import Forests from "./Forests.jsx";

let BUILD_LEVEL = process.env.REACT_APP_BUILD_LEVEL;

if (BUILD_LEVEL === "prod") {
  config.stripe = "pk_live_OHeHbMJISyJsEh2K20sVbtNI";
} else if (BUILD_LEVEL === "staging") {
  config.stripe = "pk_test_EFglonl3FLuRht8ikEVQJ9lL";
}

const CountriesCSS = styled.span`
  display: flex;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

class Application extends Component {
  static contextType = UserContext;

  state = {
    isOpen: false,
    feedback: "users",
  };

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <span>
        <Row
          style={{
            width: "100%",
            marginRight: "0px",
            marginLeft: "0px",
          }}
        >
          <div
            style={{
              background: "#fbeebad6",
              backdropFilter: "blur(4px)",
              display: "flex",
              // position: "absolute",
              width: "100%",
              zIndex: "1",
            }}
          >
            <span
              style={{
                margin: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span>
                <strong> Message: </strong> Hey there! This is Fady (builder of
                Thank You Trees). I've disabled the payment options due to work
                restrictions as I recently moved countries. Users who've planted
                trees will still have access to their gift pages and can
                contribute them (
                <a
                  href="https://fadyazmy.notion.site/How-to-contribute-trees-to-your-TYT-gift-page-06a48ee75fd640029f97b1692d20475d"
                  target="_blank"
                >
                  read more
                </a>
                ).
              </span>
              <span></span>
            </span>
          </div>

          <div
            className="section"
            style={{
              position: "relative",
              paddingTop: "48px",
              paddingBottom: "48px",
              backgroundColor: "beige",
            }}
          >
            <div className="w-layout-grid grid-halves overlap-grid-halves">
              <div id="w-node-c097d2c04f1e-19901cfa" className="container">
                <div className="content-width-large align-center">
                  <h1
                    className="display-heading-2 animate-in-first"
                    style={{
                      opacity: "1",
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <strong>
                      {" "}
                      <span
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          borderRadius: "5px",
                          paddingLeft: "3px",
                          margin: "auto",
                        }}
                      >
                        Plant real trees.
                      </span>
                      <br />
                      <span className="text-muted">
                        Gift an interactive experience.
                        <br />
                      </span>
                    </strong>
                  </h1>
                  <h5
                    className="large-text animate-in-second"
                    style={{
                      opacity: "1",
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <strong>
                      {/* Plant real trees and share an interactive experience. */}
                      For birthdays, anniversaries and thank you gifts.
                    </strong>
                  </h5>
                  <br />
                  <Button
                    disabled={true}
                    variant="success"
                    size="lg"
                    href="#gift"
                  >
                    Gift now
                  </Button>{" "}
                  <Button
                    target="_blank"
                    variant="secondary"
                    className="example_gift_home_btn"
                    size="lg"
                    href="/gifts/ch_1I9SfVJGYzY5FZVdMwcIdGi5"
                  >
                    Example gift
                  </Button>
                </div>
              </div>
              <img
                style={{ width: "90%", borderRadius: "10px" }}
                src="https://images.unsplash.com/photo-1551855350-c86caeaf8707?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                // srcset="https://assets.website-files.com/5e58549685a7904fa5912b7c/5e70117008830f324bf212ae_girl-on-laptop-couch-p-500.jpeg 500w, https://assets.website-files.com/5e58549685a7904fa5912b7c/5e70117008830f324bf212ae_girl-on-laptop-couch.jpg 592w"
                sizes="(max-width: 767px) 90vw, (max-width: 991px) 592px, 49vw"
                id="w-node-c097d2c04f2c-19901cfa"
                alt=""
                className="right-aligned-hero-image"
              />
            </div>
            <div className="hero-background-color-block"></div>
          </div>
        </Row>

        <br />
        <br />
        <GiftingEngine />

        <br />
        <br />

        <HowItWorks />

        <Row
          style={{
            paddingTop: "4.95238095em",
            paddingBottom: "4.95238095em",
            textAlign: "center!important",
            width: "100%!important",
            marginLeft: "0px",
            marginRight: "0px",
            backgroundColor: "beige",
          }}
        >
          <Container style={{ width: "100%!important" }}>
            <Row
              style={{
                justifyContent: "center",
                flexFlow: "column",
              }}
            >
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "-20px",
                }}
              >
                ★ ★ ★ ★ ★
              </h4>
              <br />
              {/* <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "2px",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "5px",
                }}
              >
                500+ trees planted
              </p> */}
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "2px",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "-5px",
                }}
              >
                {/* 500 trees planted <br />  */}
                <strong> 300,000 kg </strong>of CO₂ removed through this
                platform
              </p>
              <span
                style={{
                  display: "block",
                  margin: "auto",
                  float: "unset",
                }}
              >
                <h2
                  style={{
                    paddingTop: "40px",
                    textAlign: "center",
                  }}
                  className="rich-text__heading separator has-animated"
                  data-animate
                >
                  We plant you <strong> A FOREST </strong> for every thank you
                  gift — literally.
                </h2>
                {/* <div className="rich-text__body-text rich-text__body-text--medium rte-setting">
                  <p
                    className="text-muted"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Measure the impact of your gift.
                  </p>
                </div> */}
              </span>
            </Row>
          </Container>
        </Row>

        <Row
          style={{
            padding: "39.84px 0px 20px",
            textAlign: "center",
            width: "100%",
            marginLeft: "0px",
            marginRight: "0px",
            backgroundColor: "white",
          }}
        >
          <h2
            style={{
              fontSize: "3rem",
              width: "100%",
              // paddingBottom: "3.125rem",
              margin: "39.84px 0px 20px",
            }}
          >
            <strong>What people are saying</strong>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "1rem",
                fontWeight: "400",
                marginTop: "10px",
              }}
              className="text-muted"
            >
              {this.state.feedback === "users"
                ? "Feedback from our users"
                : "Messages from your Tree Planters"}
            </p>
            <ButtonGroup size="lg" className="mb-2">
              <Button
                style={{
                  width: "120px",
                }}
                onClick={() => {
                  this.setState({
                    feedback: "users",
                  });
                }}
                variant={`${
                  this.state.feedback === "users" ? "info" : "light"
                }`}
              >
                Users
              </Button>
              <Button
                style={{
                  width: "120px",
                }}
                onClick={() => {
                  this.setState({
                    feedback: "workers",
                  });
                }}
                variant={`${
                  this.state.feedback === "workers" ? "info" : "light"
                }`}
              >
                Tree Planters
              </Button>
            </ButtonGroup>
          </h2>
        </Row>
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            marginRight: "15px",
            marginLeft: "0px",
            backgroundColor: "white",
            paddingBottom: "50px",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
            className="row"
          >
            <div className="col-lg-6 mx-auto">
              <blockquote
                style={{
                  backgroundColor: "beige",
                }}
                className="blockquote blockquote-custom p-5 shadow rounded"
              >
                <p className="mb-0 mt-2 font-italic">
                  {this.state.feedback === "users"
                    ? "Thank you for this gift!! It's the coolest thing. I've never had a tree in my name. I'm making the world better!"
                    : "The forest is too important for our village. Because we are protecting the forest, we can survive on our own from the land here and not rely on other places. We have a plan to save our region."}
                </p>
                {this.state.feedback === "users" ? (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    A birthday gift, <cite title="Source Title">Toronto</cite>
                  </footer>
                ) : (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <a target="_blank" href="https://youtu.be/Y8swl--QWJ4?t=36">
                      {" "}
                      Jean Zamanjisy
                    </a>
                    ,{" "}
                    <cite title="Source Title">
                      Chief of Antanamarina (True Village) and Tree Planter.
                    </cite>
                  </footer>
                )}
              </blockquote>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            className="row"
          >
            <div className="col-lg-6 mx-auto">
              <blockquote
                style={{
                  backgroundColor: "beige",
                }}
                className="blockquote blockquote-custom p-5 shadow rounded"
              >
                <p className="mb-0 mt-2 font-italic">
                  {this.state.feedback === "users"
                    ? "Mary enjoyed her gift so very much. She's really into nature so she's very happy about her forest. Thank you for making this possible!"
                    : "Life has been better after getting a job. I could get a water pump, a stove to cook, a cow. I bought my kids bikes. So they can get to school. It has helped so much."}
                </p>
                {this.state.feedback === "users" ? (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    A birthday gift, <cite title="Source Title">London</cite>
                  </footer>
                ) : (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <a target="_blank" href="https://youtu.be/w5MO7L5DL8w?t=23">
                      Nirmala Basnet
                    </a>
                    ,<cite title="Source Title"> Tree Planter in Nepal</cite>
                  </footer>
                )}
              </blockquote>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            className="row"
          >
            <div className="col-lg-6 mx-auto">
              <blockquote
                style={{
                  backgroundColor: "beige",
                }}
                className="blockquote blockquote-custom p-5 shadow rounded"
              >
                <p className="mb-0 mt-2 font-italic">
                  {this.state.feedback === "users"
                    ? "This is SOOOOOO incredible, you have no idea how much I appreciate this! Thanks so much for the planting me trees. Honestly the best gift anyone could ask for. You are the best"
                    : "Now I can pay for medical treatment to get better. I’m very grateful to you for hiring us."}
                </p>
                {this.state.feedback === "users" ? (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    A thank you gift, <cite title="Source Title"> Toronto</cite>
                  </footer>
                ) : (
                  <footer className="blockquote-footer pt-4 mt-4 border-top">
                    <a target="_blank" href="https://youtu.be/w5MO7L5DL8w?t=56">
                      Rama Niroula
                    </a>
                    , <cite title="Source Title"> Tree Planter in Nepal</cite>
                  </footer>
                )}
              </blockquote>
            </div>
          </div>
        </Row>
      </span>
    );
  }
}

function ApplicationMaster(history) {
  useEffect(() => {
    ReactGA.initialize("UA-158743275-1");
    // To Report Page View
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const context = useContext(UserContext);

  return (
    <main>
      <Navbar
        bg="bg-light"
        className="p-3 px-md-4 border-bottom shadow-sm"
        style={{
          justifyContent: "space-between",
        }}
        expand="lg"
      >
        <Navbar.Brand
          style={{
            color: "#416e22",
            fontWeight: "300px",
          }}
          href="/"
        >
          <img
            alt=""
            src="https://www.openmoji.org/data/color/svg/1F333.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Thank You Trees
        </Navbar.Brand>
        <CountriesCSS>
          <Nav.Link
            key="track_your_forest"
            style={{
              color: "#00000080",
              fontWeight: "bold",
            }}
            target="_blank"
            href="https://www.notion.so/fadyazmy/Thank-You-Trees-Track-Your-Forests-0b1fe1460dc3401485a74cbf0245635d"
          >
            Track Your Forest
          </Nav.Link>
          <Nav.Link
            key="themes"
            style={{
              color: "#00000080",
              fontWeight: "bold",
            }}
            target="_blank"
            href="https://www.notion.so/fadyazmy/Thank-You-Trees-Themes-a9fd41d7529d4e1caad5017a53255166"
          >
            Themes
          </Nav.Link>
          <Nav.Link
            key="faq"
            style={{
              color: "#00000080",
              fontWeight: "bold",
            }}
            target="_blank"
            href="https://www.notion.so/fadyazmy/Thank-You-Trees-FAQ-e5e21a5e506f4967b44e76bd4d211a29"
          >
            FAQ
          </Nav.Link>
          <NavDropdown
            className="text-dark"
            title="Countries"
            id="basic-nav-dropdown-countries"
            style={{
              color: "#00000080",
              fontWeight: "bold",
            }}
          >
            <NavDropdown.Item href="/country/madagascar">
              Madagascar
            </NavDropdown.Item>
            <NavDropdown.Item href="/country/nepal">Nepal</NavDropdown.Item>
            <NavDropdown.Item href="/country/haiti">Haiti</NavDropdown.Item>
          </NavDropdown>
        </CountriesCSS>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="justify-content-end">
            {context && context.id ? (
              <>
                <Dropdown style={{ alignSelf: "center" }} alignRight>
                  <Dropdown.Toggle
                    style={{
                      fontWeight: "bold",
                      color: "#00000080",
                      padding: ".5rem 1rem",
                      textDecoration: "none",
                    }}
                    to="#"
                    id="user-dropdown"
                    as={NavLink}
                  >
                    {context && context.display_name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile">
                      {" "}
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/mygifts">
                      {" "}
                      My Gifts
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/credits">
                      {" "}
                      Credits
                    </Dropdown.Item>
                    <Dropdown.Item
                      to="#"
                      onClick={() => signOut().then((window.location = "/"))}
                    >
                      {" "}
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              [
                <Nav.Link
                  key="my_gifts"
                  style={{
                    // color: "black",
                    fontWeight: "bold",
                  }}
                  as={Link}
                  to="/mygifts"
                >
                  My Gifts
                </Nav.Link>,
                <Nav.Link
                  key="messages"
                  style={{
                    color: "#00000080",
                    fontWeight: "bold",
                  }}
                  as={Link}
                  to="/messages"
                >
                  Messages
                </Nav.Link>,
              ]
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div>
        <Switch>
          <PrivateRoute exact path="/mygifts" component={GiftPurchases} />
          <PrivateRoute exact path="/credits" component={Credits} />
          <PrivateRoute exact path="/profile" component={Profile} />

          <Route exact path="/login" component={SignIn} />
          {/* <Route exact path="/faq" component={FAQ} /> */}
          <Route exact path="/slack" component={Slack} />
          <Route exact path="/about" component={About} />
          <Route exact path="/messages" component={Messages} />
          <Route exact path="/demo" component={DemoProfile} />
          <Route exact path="/forests" component={Forests} />

          <Route
            exact
            path="/gifting"
            render={() => (
              <Gifts gift_id={"ch_1I9SfVJGYzY5FZVdMwcIdGi5"} db={db} />
            )}
          />

          {/* <Route exact path="/country/madagascar" component={Country} /> */}
          <Route
            exact
            path="/country/:country"
            render={(props) => <Country {...props} />}
          />
          {BUILD_LEVEL === "staging" && [
            <Route
              key={"/admin"}
              exact
              path="/admin"
              render={(props) => <Admin {...props} db={db} />}
            />,
            // <Route key={"/signin"} path="/signin" component={SignIn} />,
          ]}

          {/* <Route
            exact
            path="/history/:email"
            render={(props) => <History {...props} db={db} />}
          /> */}
          <Route
            exact
            path="/gifts/:id"
            render={(props) => <Gifts {...props} gift_id="" db={db} />}
          />
          <Route exact path="/" component={Application} />
        </Switch>
        <Footer />
      </div>
    </main>
  );
}

export default ApplicationMaster;
