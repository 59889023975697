/* eslint-disable */

import React from "react";
// import "../bootstrap_css/css/how-it-works.css";
import { Row } from "react-bootstrap";

// https://www.manypixels.co/gallery/?s=plant
import ecology from "../assets/ecology.svg";
import world_map from "../assets/world_map.svg";
import creative_process from "../assets/creative_process.svg";
import watering_plant from "../assets/watering_plant.svg";

const HowItWorks = () => {
  return (
    <>
      <Row
        style={{
          // backgroundColor: "#e9efd1",
          background: "#FAFBFE",
          margin: "0px 0px 20px",
          textAlign: "center",
          width: "100%!important",
          paddingTop: "3.125rem",
          paddingBottom: "3.125rem",
          marginBottom: "0px",
        }}
        className=""
      >
        <div
          style={{
            paddingBottom: "3.125rem",
            margin: "39.84px 0px 20px",
            width: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "3rem",
            }}
          >
            <strong>How does it work?</strong>
          </h2>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
          }}
          className="custom__item-inner custom__item-inner--html container flex-icons "
        >
          <div className="how-container">
            <div>
              <img src={world_map} />
              <h4>
                {" "}
                <strong>Step 1 </strong>
              </h4>
              <p>Choose your location of trees.</p>
            </div>
          </div>
          <div className="how-container">
            <div>
              <img src={creative_process} />
              <h4>
                {" "}
                <strong> Step 2 </strong>
              </h4>
              <p>Personalise your gift.</p>
            </div>
          </div>
          <div className="how-container">
            <div>
              <img src={ecology} />
              <h4>
                {" "}
                <strong>Step 3 </strong>
              </h4>
              <p> Share your gift.</p>
            </div>
          </div>
          <div className="how-container">
            <div>
              <img src={watering_plant} />
              <h4>
                {" "}
                <strong>Step 4 </strong>
              </h4>
              <p>We plant your trees!</p>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default HowItWorks;
