import React from "react";
import imageCompression from "browser-image-compression";

class Croppie extends React.Component {
  state = {
    file: null,
    img_upload_loading: false,
  };

  render() {
    return (
      <div key={"upload_photo_div"}>
        {!this.props.on_gift_page && (
          <>
            {this.state.img_upload_loading == false ? (
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "beige",
                  borderRadius: "80px",
                  border: "3px solid #000000",
                }}
                src={
                  this.props.cover_image == null
                    ? null
                    : URL.createObjectURL(this.props.cover_image)
                }
              />
            ) : (
              <div
                style={{
                  backgroundColor: "beige",
                  width: "150px",
                  height: "150px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexFlow: "column",
                }}
              >
                <span>Loading ...</span>
              </div>
            )}
            <br />
            <br />
          </>
        )}

        <input
          type="file"
          onChange={async (event) => {
            const imageFile = event.target.files[0];
            if (imageFile) {
              this.setState({ img_upload_loading: true });
              await imageCompression(imageFile, {
                // maxWidthOrHeight: 600,
                maxSizeMB: 0.5,
                useWebWorker: true,
              }).then((compressed_image) => {
                // console.log("croppie upload success");
                this.props.handleImageUpload(compressed_image);
                this.setState({ img_upload_loading: false });
              });
            }
          }}
        />
      </div>
    );
  }
}

export default Croppie;
