import React, { Component } from "react";

import MarkerSVG from "../assets/logo.svg";
import GoogleMapReact from "google-map-react";

// https://snazzymaps.com/style/98318/focus-on-nature
let mapStyling = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ff0000",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        lightness: "25",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#1b682f",
      },
      {
        visibility: "on",
      },
      {
        weight: "2.82",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text",
    stylers: [
      {
        color: "#1b682f",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#e8e9db",
      },
      {
        saturation: "0",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        lightness: "1",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "all",
    stylers: [
      {
        lightness: "0",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      {
        lightness: "-19",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c6d36e",
      },
      {
        saturation: "0",
      },
      {
        lightness: "0",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        lightness: "-30",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: "-47",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#9fdbe1",
      },
      {
        visibility: "on",
      },
      {
        saturation: "0",
      },
      {
        lightness: "50",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        gamma: "1.00",
      },
      {
        saturation: "-2",
      },
      {
        lightness: "-45",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        hue: "#0081ff",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#9500ff",
      },
      {
        saturation: "-9",
      },
    ],
  },
];

let mapData = {
  madagascar: {
    center: {
      lat: -16.214861,
      lng: 46.935444,
    },
    marker: {
      lat: -16.155296,
      lng: 46.995444,
    },
    zoom: 10,
    height: "720px",
  },
  nepal: {
    center: {
      lat: 27.530812,
      lng: 84.4451192,
    },
    marker: {
      lat: 27.530812,
      lng: 84.4451192,
    },
    zoom: 10,
    height: "820px",
  },
  haiti: {
    // 18.561270, -72.064549
    center: {
      lat: 18.56127,
      lng: -72.064549,
    },
    marker: {
      lat: 18.56127,
      lng: -72.064549,
    },
    zoom: 13,
    height: "730px",
  },
};

const AnyReactComponent = () => (
  <img
    alt="tree marker pointing to location"
    src={MarkerSVG}
    style={{
      width: "75px",
      position: "absolute",
      transform: "translate(-50%, -100%)",
    }}
  />
);

class TreeInfo extends Component {
  render() {
    return (
      <div
        style={{
          height: this.props.country
            ? mapData[this.props.country].height
            : "650px",
        }}
        id="location"
        className={`location_section_${this.props.country}`}
      >
        <h3
          style={{
            paddingTop: "0.5em",
          }}
        >
          {" "}
          Location 🗺
        </h3>
        <hr />

        <div style={{ height: "500px", padding: "20px", width: "100%" }}>
          {this.props.country ? (
            <span>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCkRxiZZQqlt5WSCWsD71lxjXYKcVCfXRI",
                }}
                defaultCenter={mapData[this.props.country].center}
                defaultZoom={mapData[this.props.country].zoom}
                options={{ styles: mapStyling }}
              >
                <AnyReactComponent
                  lat={mapData[this.props.country].marker.lat}
                  lng={mapData[this.props.country].marker.lng}
                />
              </GoogleMapReact>
              <br />
              {this.props.country === "madagascar" && (
                <div>
                  The <strong> Ankarafantsika National Park </strong>is a
                  tropical dry deciduous forest and the home to 8 species of
                  endangered lemurs. The forest is under great pressure of
                  deforestation, jeopardizing the wildlife it shelters.
                  <br />
                  <br />
                  The goal of the project is to secure the forest and its
                  wildlife by supporting local communities in preventing
                  slash-and-burn farming or other practices jeopardizing
                  biodiversity. Your contribution will reforest and revive the
                  natural habitat for endangered and endemic animal species.
                </div>
              )}
              {this.props.country === "nepal" && (
                <div>
                  <strong>
                    {" "}
                    Chitwan ("Heart of the Jungle") National Park{" "}
                  </strong>{" "}
                  is an epic wildlife sanctuary near the Indian Border; Home to
                  a huge variety of different mammals, birds, fish, reptiles &
                  amphibians. Most notably the park protects the endangered One
                  Horned Rhinoceros, Royal Bengal Tiger & Gharial Crocodile.
                  <br />
                  <br />
                  It was established in 1973, making it the first national park
                  in Nepal. By partnering with the National Park system your
                  sponsored trees goes to the reforestation of a buffer zone
                  around the national park, extending the animal habitat.
                  <br />
                  <br />
                  The trees sponsored by your gift will also help our
                  reforestation partners plant native and agroforestry trees,
                  employ local mothers and villagers which provides numerous
                  families a primary and secondary source of income.
                </div>
              )}
              {this.props.country === "haiti" && (
                <div>
                  <strong> Providence University </strong> has been one of
                  Eden’s most challenging locations for reforestation. Eden’s
                  employees began using the singling process to “tame” the wild
                  shrubs that once were Bayawonn trees.
                  <br />
                  <br />
                  The workers cut back all but one to three of the strongest and
                  straightest stems growing from the Bayawonn shrub. These
                  remaining stems are then protected, and within two to five
                  years will grow back into a tree. The excess branches that are
                  removed are either used for fencing or fuel so that nothing
                  goes to waste.
                </div>
              )}
            </span>
          ) : (
            <p> Loading</p>
          )}
        </div>
      </div>
    );
  }
}

export default TreeInfo;
