import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import dayjs from "dayjs";

// Images for profile page
import YourGifts from "../Components/ProfileDemo/YourGifts";
import YourNetwork from "../Components/ProfileDemo/YourNetwork";
import ImpactSummary from "../Components/ProfileDemo/ImpactSummary";
import GiftUpdates from "../Components/ProfileDemo/GiftUpdates";
import GoogleMaps from "../Components/ProfileDemo/GoogleMaps";
import ProfileCard from "../Components/ProfileDemo/ProfileCard";
import YourForests from "../Components/ProfileDemo/YourForests";

const Toggle = styled.span`
  .Not-scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1;
    background: #ffcd5c21;
  }

  .Scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    text-align: center;
    padding-top: 23%;
  }
  .button {
    background-color: #ffffff;
    border: none;
    color: #4c4848;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }
`;
let purchased_gifts = [
  {
    id: "#",
    buyer_name: "Fady",
    createdAt: dayjs("2005-12-25T19:15:14.098Z"),
    first_name: "Sarah",
    gift_cover: "christmas_2",
    location: "madagascar",
    quantity: 20,
    recipient_email: "fadel@uwo.ca",
    recipient_name: "Sarah",
    supporters: [
      {
        date: dayjs("2020-06-14T19:15:14.098Z"),
        name: "Fady Azmy",
        quantity: 1,
        version: "v2",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167754,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Arthur",
        quantity: "0",
        time_taken: "352",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167757,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Forest Grump #1",
        quantity: "0",
        time_taken: "360",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167922,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Fady Azmy",
        quantity: "0",
        time_taken: "30",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1607896743,
            nanoseconds: 74000000,
          })
        ),
        invitation_code: true,
        name: "Fady Azmy #2",
        quantity: "0",
        time_taken: "402",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592156909,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Jenny #5",
        quantity: "0",
        time_taken: "34",
      },
    ],
  },
  {
    id: "ch_1I9SfVJGYzY5FZVdMwcIdGi5",
    buyer_email: "fadel@uwo.cam",
    buyer_name: "Fady Azmy",
    createdAt: dayjs("2020-06-14T20:10:05.752Z"),
    env: "staging",
    group_gift: false,
    location: "haiti",
    quantity: 45,
    recipient_email: "",
    recipient_name: "Lina",
    supporters: [
      {
        date: dayjs("2020-06-14T20:10:05.752Z"),
        name: "Fady Azmy",
        quantity: 1,
        version: "v2",
      },
      {
        date: dayjs("2020-06-14T20:11:05.752Z"),
        name: "Sarah",
        quantity: "10",
      },
      {
        date: dayjs("2020-06-14T20:11:05.752Z"),
        name: "Julia",
        quantity: "28",
      },
    ],
    treeAttributes:
      '{"truncColor":15987687,"truncHeight":"85","truncStartRadius":4,"verticalSegments":10,"radiusSegments":9,"shapeAngleStart":1.4013175199757515,"noise":0.5,"foliageDensity":5,"shapeAngle":1.7402751336140416,"freq":0.17402751336140415,"segHeight":8.5}',
    type: "bought",
  },
  {
    id: "#",
    buyer_email: "fadel@uwo.cam",
    buyer_name: "Fady",
    createdAt: dayjs("2015-01-02T21:56:46.199Z"),
    location: "madagascar",
    quantity: 30,
    recipient_email: "",
    recipient_name: "Mark",
    show_history: true,
    supporters: [
      {
        date: dayjs("2020-01-02T21:56:46.199Z"),
        name: "Fady Azmy",
        quantity: 6,
        version: "v2",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1578023732,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Menelaos",
        quantity: "0",
        time_taken: "150",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1578023745,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Lina",
        quantity: "0",
        time_taken: "169",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1578023755,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Anwar",
        quantity: "0",
        time_taken: "179",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1578023768,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Raul",
        quantity: "0",
        time_taken: "192",
      },
    ],
    time_taken_breakdown: {
      step_1: "3",
      step_2: "7",
      step_3: "10",
      step_4: "13",
      total: "31",
    },
    treeAttributes:
      '{"truncColor":14800863,"truncHeight":"85","truncStartRadius":4,"verticalSegments":10,"radiusSegments":9,"shapeAngleStart":1.55806337401452,"noise":0.5,"foliageDensity":5,"shapeAngle":1.5835292795752731,"freq":0.1583529279575273,"segHeight":8.5}',
    type: "bought",
  },
];

let gifts_for_me = [
  {
    id: "#",
    buyer_name: "Fady",
    createdAt: dayjs("2005-12-25T19:15:14.098Z"),
    first_name: "Sarah",
    gift_cover: "christmas_2",
    location: "madagascar",
    quantity: 20,
    recipient_email: "fadel@uwo.ca",
    recipient_name: "Sarah",
    supporters: [
      {
        date: dayjs("2020-06-14T19:15:14.098Z"),
        name: "Fady Azmy",
        quantity: 1,
        version: "v2",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167754,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Arthur",
        quantity: "0",
        time_taken: "352",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167757,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Forest Grump #1",
        quantity: "0",
        time_taken: "360",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592167922,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Fady Azmy",
        quantity: "0",
        time_taken: "30",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1607896743,
            nanoseconds: 74000000,
          })
        ),
        invitation_code: true,
        name: "Fady Azmy #2",
        quantity: "0",
        time_taken: "402",
      },
      {
        date: dayjs(
          new Date({
            seconds: 1592156909,
            nanoseconds: 0,
          })
        ),
        invitation_code: true,
        name: "Jenny #5",
        quantity: "0",
        time_taken: "34",
      },
    ],
  },
];

class DemoProfile extends Component {
  state = {
    scrollable_map: false,
    purchased_gifts: purchased_gifts,
    contributed_gifts: [],
    gifts_for_me: gifts_for_me,
    loaded: false,
    network: [],
  };

  render() {
    return (
      <div
        style={{
          maxWidth: "100%",
          padding: "0px",
          margin: "0px",
          backgroundColor: "#FFFFF3",
        }}
      >
        {" "}
        {/* Profile cover section */}
        <Row
          style={{
            width: "100%",
            padding: "0px",
            margin: "0px",
          }}
        >
          {/* Profile card */}
          <Col
            xs={5}
            style={{
              backgroundColor: "#FFEEC6",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <ProfileCard purchased_gifts={this.state.purchased_gifts} />
          </Col>
          {/* Google maps */}
          <Col
            style={{
              height: "300px",
              padding: "0px",
            }}
          >
            <Toggle
              style={{
                display: "flex",
              }}
            >
              <div
                className={
                  this.state.scrollable_map
                    ? "Scrollable-world"
                    : "Not-scrollable-world"
                }
                onClick={() => {
                  this.setState({ scrollable_map: true });
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    alignSelf: "center",
                  }}
                  className="button button3"
                >
                  &nbsp;
                  <span>View gift map</span>
                </button>
              </div>
            </Toggle>
            <span
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <GoogleMaps purchased_gifts={this.state.purchased_gifts} />
            </span>
          </Col>
        </Row>
        <YourForests purchased_gifts={this.state.purchased_gifts} />
        <Container>
          <Row
            style={{
              width: "100%",
            }}
          >
            <Col lg={4}>
              <YourGifts purchased_gifts={this.state.purchased_gifts} />
              <br />
              <YourNetwork purchased_gifts={this.state.purchased_gifts} />
            </Col>
            <Col lg={8}>
              {" "}
              <ImpactSummary
                gifts_for_me={this.state.gifts_for_me}
                purchased_gifts={this.state.purchased_gifts}
              />
              <GiftUpdates
                contributed_gifts={this.state.contributed_gifts}
                purchased_gifts={this.state.purchased_gifts}
                gifts_for_me={[]}
              />
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    );
  }
}

export default DemoProfile;
