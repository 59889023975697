import React, { Component, Fragment } from "react";
import mangrove from "../assets/mangrove_img.jpg";

// https://commons.wikimedia.org/wiki/File:Starr-120606-6794-Dalbergia_sissoo-habit-Kahanu_Garden_NTBG_Hana-Maui_(24513675274).jpg
import dalbergia from '../assets/dalbergia_img.jpg'

// https://en.wikipedia.org/wiki/Mesquite#/media/File:Velvet_mesquite.jpg
import mesquite from "../assets/mesquite_img.jpg";
import { Container } from "react-bootstrap";

class TreeInfo extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
        }}
        id="tree"
      >
        {this.props.country &&
          this.props.country === "madagascar" && [
            <Fragment key={`1`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                Mangrove trees 🌳🌳
              </h3>
              <hr />
              <Container
                style={{
                  width: "90%",
                  padding: "20px",
                }}
              >
                <img
                  alt=""
                  style={{ width: "100%", borderRadius: "10px" }}
                  src={mangrove}
                />
              </Container>
              <br />
              <span
                style={{
                  width: "100%",
                }}
              >
                {" "}
                A mangrove is a tree that lives in tidal coastal swamps. They
                have numerous tangled roots that grow above ground and form
                dense thickets and under water networks.{" "}
              </span>
              <br />
              <br />
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://iopscience.iop.org/article/10.1088/1748-9326/aa9f03/pdf"
                >
                  Scientific studies
                </a>{" "}
                have shown that mangroves “sequester carbon at a rate two to
                four times greater than mature tropical forests”, and contain
                “the highest carbon density of all terrestrial ecosystems.”
              </span>
            </Fragment>,
          ]}

        {this.props.country &&
          this.props.country === "haiti" && [
            <Fragment key={`2`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                Bayawonn trees 🌳🌳
              </h3>
              <hr />
              <Container
                style={{
                  width: "90%",
                  padding: "20px",
                  textAlignLast: "center",
                }}
              >
                <img
                  alt=""
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                  src={mesquite}
                />
              </Container>
              <br />
              <span
                style={{
                  width: "100%",
                }}
              >
                {" "}
                Bayawonn or Mesquite is an extremely hardy, drought-tolerant
                plant due to its incredible ability to draw water from deep,
                hidden sources through its long taproots (recorded reaching up
                to 58 m depth), while also able to access water closer to the
                surface depending upon availability.
              </span>
              <br />
              <br />
              <span>
                The Bayawonn can grow to 60 meters, it improves the soil by
                absorbing nitrogen from the air and transferring it to their
                roots making the nitrogen readily available for use by other
                trees. The Bayawonn pods can be used as food for livestock and
                the wood can be used for furniture, carving, and flooring.
              </span>
            </Fragment>,
          ]}

        {this.props.country &&
          this.props.country === "nepal" && [
            <Fragment key={`2`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                North Indian Rosewood trees 🌳🌳
              </h3>
              <hr />
              <Container
                style={{
                  width: "90%",
                  padding: "20px",
                  textAlignLast: "center",
                }}
              >
                <img
                  alt=""
                  style={{
                    width: "60%",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                  src={dalbergia}
                />
              </Container>
              <br />
              <span
                style={{
                  width: "100%",
                }}
              >
                The <strong>North Indian Rosewood</strong> (
                <a
                  target="_blank"
                  rel="noopener noreferrer" 
                  href="https://uses.plantnet-project.org/en/Dalbergia_sissoo_(PROTA)"
                >
                  Dalbergia Sissoo{" "}
                </a>{" "}
                in Nepal) is an important multipurpose timber tree that is
                native to the Himalayan foothills in Northern India. It is also
                best known internationally as a premier timber species of the
                rosewood tree family. It can grow up to 30 meters, but does not
                grow particularly straight, and grows leathery leaves with white
                or pink flowers.
              </span>
              <br />
              <br />
              <span>
                A minimum of 10% of the trees to be planted are
                agroforestry species (fruit, fodder and construction species
                designed to provide food security and benefit legitimate human
                needs). Over time these trees become a source of sustainable
                income. This prevents the community from going into newly
                restored forests and provides greater community benefit and
                involvement in the project.
              </span>
            </Fragment>,
          ]}
      </div>
    );
  }
}

export default TreeInfo;
