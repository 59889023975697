import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { Alert } from "react-bootstrap";

// 25 lifespan

const options = {
  bezierCurve: true,
  responsive: true,
  tooltips: {
    mode: "index",
  },
  hover: {
    mode: "index",
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Year(s)",
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: "KG",
        },
        ticks: {
          beginAtZero: true,

          // Add commas appropriately to large numbers
          userCallback: function (value, index, values) {
            return value.toLocaleString();
          },
        },
      },
    ],
  },
};

class TreeGraph extends Component {
  state = {
    alert: true,
  };
  render() {
    let label_text =
      this.props.quantity > 1
        ? `${this.props.quantity} trees`
        : `${this.props.quantity} tree`;
    const graph_data = {
      // labels: labels_set.map((num) => num + ''),
      labels: [
        "1 year",
        "3 years",
        "5 years",
        "10 years",
        "15 years",
        "20 years",
        "25 years",
      ],
      datasets: [
        {
          label: `Co₂ Sequestered from ${label_text} (KG)`,
          fill: true,
          // lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          responsive: true,
          data: [0, 2, 15, 83, 227, 295, 308].map(
            (val) => this.props.quantity * val
          ),
          // data: labels_set.map(num => Math.round((Math.tanh(num - 3.5) +1) *155))
        },
      ],
    };

    return (
      <div id="impact" style={{ width: "100%" }}>
        <h3 style={{ marginTop: "1.5em" }}>
          {" "}
          Your impact{" "}
          <span
            role="img"
            aria-label="tree emoji representing sequestration of CO2"
          >
            🌍
          </span>
        </h3>
        <hr />
        <p>
          Over 25 years this Thank You Tree gift will remove about{" "}
          <strong>
            {" "}
            {(308 * this.props.quantity).toLocaleString()} kg of CO₂ (
            {((this.props.quantity * 308) / 20000).toFixed(2)} human years of
            carbon footprint){" "}
          </strong>
          from the atmosphere. You can see a more detailed explanation from the
          graph below.
        </p>

        <Line options={options} data={graph_data} />
        {this.state.alert && [
          <Alert
            onClose={() => this.setState({ alert: false })}
            key={1}
            variant={"info"}
            dismissible
            style={{
              marginTop: "1rem",
            }}
          >
            The average CO₂ emissions per person annually is{" "}
            <strong>
              {" "}
              20 metric tons (20,000 KG) or 65 trees (over 25 years).{" "}
            </strong>
          </Alert>,
        ]}
      </div>
    );
  }
}

export default TreeGraph;
