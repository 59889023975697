import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Alert,
  ButtonToolbar,
  Button,
} from "react-bootstrap";

import Scrollspy from "react-scrollspy";

import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";

import TreeInfo from "../Components/TreeInfo";
import TreeGraph from "../Components/TreeGraph";
import SupportList from "../Components/SupportList";
import Gallery from "../Components/Gallery";
import GiftMap from "../Components/GiftMap";
import GiftCover from "../Components/GiftCover";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

// Config
import { config } from "../config.js";

// STEP 3 (Stripe)
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import ReactButtonHandler from "../Utils/ReactButtonHandler";
import GiftComments from "../Components/GiftComments";
// import Timeline from "../Components/Timeline";
import GiftCertificate from "../Components/GiftCertificate";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { storage } from "../firebase";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import PlantingTeam from "../Components/PlantingTeam";
import GiftMessage from "../Components/GiftMessage";
import GiftSummary from "../Components/GiftSummary";
import GiftBanner from "../Components/GiftBanner";
import dayjs from "dayjs";
import NewGallery from "../Components/NewGallery";
import { UserContext } from "../Providers/UserProvider";

let BUILD_LEVEL = process.env.REACT_APP_BUILD_LEVEL;

if (BUILD_LEVEL === "prod") {
  config.stripe = "pk_live_OHeHbMJISyJsEh2K20sVbtNI";
} else if (BUILD_LEVEL === "staging") {
  config.stripe = "pk_test_EFglonl3FLuRht8ikEVQJ9lL";
}

const SocialBar = styled.div`
  .social-buttons {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top: -0.5em;
    margin-right: -0.5em;
  }
  .social-buttons > div {
    margin-right: 0.5em;
  }
  .social-buttons > div a {
    border: 0 !important;
    text-decoration: none;
    display: block;
  }
  .social-buttons .fa {
    // height: 1em;
    line-height: 2.25em;
    border-radius: 100%;
    color: #fff;
    font-size: 2em;
    text-align: center;
  }
  .social-buttons .fa.fa-facebook {
    background-color: #3b5998;
  }
  .social-buttons .fa.fa-twitter {
    background-color: #1da1f2;
  }
  .social-buttons .fa.fa-tumblr {
    background-color: #36465d;
  }
  .social-buttons .fa.fa-pinterest {
    background-color: #bd081c;
  }
  .social-buttons .fa.fa-get-pocket {
    background-color: #ef3f56;
  }
  .social-buttons .fa.fa-envelope {
    background-color: #333;
  }

  .social-buttons {
    padding: 1em;
  }
`;

const World = styled.span`
  position: relative;
  width: 100%;
  height: 500px;
  background: #cefaeb;
  overflow: hidden;
`;

const GiftMessageStyle = styled.div`
  @media (max-width: 600px) {
    display: contents;
    padding-left: 2%;
    padding-right: 2%;
  }
  @media (min-width: 601px) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const MobileBodyMargin = styled.div`
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Toggle = styled.div`
  .Not-scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 23%;
    z-index: 1;
    background: rgba(211, 211, 211, 0.4);
  }

  .Scrollable-world {
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    text-align: center;
    padding-top: 23%;
  }

  .button {
    background-color: #ffffff;
    border: none;
    color: #4c4848;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
`;

const GiftIntro = styled.span`
  background: rgba(255, 216, 77, 0.5);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const handleColor = (color) => {
  switch (color) {
    case "christmas_1":
      return "#FCFEF4";
    case "christmas_2":
      return "#fbf6e6";
    case "christmas_3":
      return "#FFFAF0";
    case "birthday_1":
      return "#ffecd4";
    case "togetherness":
      return "#FFF5D8";
    default:
      return "#fff";
  }
};

const GiftBackground = styled.div`
  background-color: ${({ color }) => handleColor(color)};
`;

// invitation {expiry: timestamp, code: ""}
const validateInvitationCode = (gift) => {
  // if there's no invitation key, return false
  if (Object.keys(gift).indexOf("invitation") === -1) {
    return false;
  }

  let arr = window.location.search.slice(1).split("=");
  let time_from_now = dayjs(gift.invitation.expiry.toDate()).fromNow();

  let is_expired =
    time_from_now.split(" ")[time_from_now.split(" ").length - 1] === "ago";

  // if invitation expired, return false
  if (is_expired) {
    return false;
  }

  if (arr[0] !== "invitation_code") {
    return false;
  }

  if (arr[1] === gift.invitation.code) {
    return true;
  } else {
    return false;
  }
};

class Gifts extends Component {
  static contextType = UserContext;
  state = {
    gift: {},
    alert: true,
    scrollable_world: false,
    showModal: false,
    // gift
    name: "",
    email: "",
    quantity: "1",
    error: false,
    support_message: "",
    // Form validation
    validated: false,
    loading: false,
    time_taken: "",
    // invitation
    invitation: false,
    tree_num_contribution_flag: true,
    // cover photos
    img_counter: 0,
  };

  get giftRef() {
    if (this.props.gift_id !== "") {
      return this.props.db.collection("gifts").doc(this.props.gift_id);
    } else {
      return this.props.db.collection("gifts").doc(this.props.match.params.id);
    }
  }

  jsPdfGenerator2 = () => {
    const element = document.getElementById("print_cert");

    html2canvas(element, {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      width: 1250,
      height: 1150,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("print_cert").style.display = "block";
        // console.log("DOC: ", clonedDoc);
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1);
      // eslint-disable-next-line
      const pdf = new jsPDF();
      // console.log("window.innerWidth: ", window.innerWidth);
      let diff = 0;

      /**
       * 1440 - GOOD
       * 1341 - GOOD
       * 1319 - GOOD
       * 1259 - GOOD
       * 1206 - GOOD
       */

      if (1310 <= Number(window.innerWidth)) {
        diff = (1440 - window.innerWidth) / 8;
        diff += 2;
      } else if (1310 <= Number(window.innerWidth)) {
        diff = (1440 - window.innerWidth) / 8;
      } else if (1200 <= Number(window.innerWidth)) {
        diff = (1440 - window.innerWidth) / 8;
      } else if (1100 <= Number(window.innerWidth)) {
        diff = (1440 - window.innerWidth) / 8;
        diff += 4;
      } else if (1000 <= Number(window.innerWidth)) {
        diff = (1440 - window.innerWidth) / 8;
        diff += 6;
      }

      pdf.addImage(imgData, "JPEG", -110 + diff, -210, 375, 0);
      pdf.save(`Gift certificate.pdf`);
    });
  };

  componentDidMount() {
    this.giftRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // console.log("No such document!");
          this.setState({ error: true });
        } else {
          if (doc) {
            let gift = doc.data();
            this.setState({
              gift,
              time_taken: new Date(),
              invitation: validateInvitationCode(gift),
            });
          }
        }
      })
      .catch((err) => {
        // console.log("Error getting document", err);
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "tree_num_contribution_flag") {
      this.setState({
        tree_num_contribution_flag: !this.state.tree_num_contribution_flag,
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  get_correct_quantity = () => {
    // if invitation == false:
    //   return quantity
    // else
    //   if tree_num_contribution_flag == true
    //     return quantity
    //   else
    //     return "0"
    if (this.state.invitation === false) {
      return this.state.quantity;
    } else {
      if (this.state.tree_num_contribution_flag === true) {
        return this.state.quantity;
      } else {
        return "0";
      }
    }
  };

  /**
   * V2
   If(name, email, support_message): // base case
      If invitation valid:
        If tree_num_contribution_flag is true: 
            If quantity > 0:
              - show stripe button 
            Else:
              - show disabled button
        Else: 
          - show submit with invitation link button
      Else: 
        If quantity > 0:
          - show stripe button 
        Else:
          - show disabled button
    Else: 
        - show disabled button
   */
  supportCTAButton = ({
    name,
    email,
    support_message,
    tree_num_contribution_flag,
    quantity,
    invitation,
  }) => {
    if (name.length > 0 && email.length > 3 && support_message.length > 0) {
      if (invitation === true) {
        if (tree_num_contribution_flag === true) {
          // false because switching off stripe
          if (false) {
            // - show stripe button
            return (
              <StripeCheckout
                style={{
                  width: "100%",
                  marginBottom: "8px",
                  background: "none!important",
                  backgroundImage: "none!important",
                  boxShadow: "none!important",
                }}
                allowRememberMe={false}
                image="https://www.openmoji.org/data/color/svg/1F333.svg" // the pop-in header image (default none)
                currency="USD"
                token={this.onToken}
                amount={Number(this.state.quantity) * 100}
                name="Thank You Trees"
                label="Gift"
                stripeKey={config.stripe}
              >
                <Button style={{ width: "40%" }} variant="success">
                  Gift
                </Button>
              </StripeCheckout>
            );
          } else {
            // - show disabled button
            return (
              <Button
                style={{ width: "40%" }}
                variant="success"
                type="submit"
                // disabled={true}
                onClick={this.handleSubmitWithInvitation}
              >
                Gift
              </Button>
            );
          }
        } else {
          // - show submit with invitation link button
          return (
            <Button
              style={{ width: "40%" }}
              variant="success"
              type="submit"
              onClick={this.handleSubmitWithInvitation}
            >
              Submit message
            </Button>
          );
        }
      } else {
        // show stripe button
        if (quantity > 0) {
          return (
            // <StripeCheckout
            //   style={{
            //     width: "100%",
            //     marginBottom: "8px",
            //     background: "none!important",
            //     backgroundImage: "none!important",
            //     boxShadow: "none!important",
            //   }}
            //   allowRememberMe={false}
            //   image="https://www.openmoji.org/data/color/svg/1F333.svg" // the pop-in header image (default none)
            //   currency="USD"
            //   token={this.onToken}
            //   amount={Number(quantity) * 100}
            //   name="Thank You Trees"
            //   label="Gift"
            //   stripeKey={config.stripe}
            // >
            //   <Button style={{ width: "40%" }} variant="success">
            //     Gift
            //   </Button>
            // </StripeCheckout>

            <Button
              style={{
                width: "40%",
                marginBottom: "8px",
                background: "none!important",
                backgroundImage: "none!important",
                boxShadow: "none!important",
              }}
              variant="success"
              disabled={true}
            >
              Gift
            </Button>
          );
        } else {
          return (
            <Button
              style={{ width: "40%" }}
              variant="success"
              type="submit"
              disabled={true}
              onClick={this.handleSubmit}
            >
              Gift
            </Button>
          );
        }
      }
    } else {
      // show disabled button
      return (
        <Button
          style={{ width: "40%" }}
          variant="success"
          type="submit"
          disabled={true}
          onClick={this.handleSubmit}
        >
          Gift
        </Button>
      );
    }
  };

  onToken = (token) => {
    const body = {
      support: {
        price: Number(this.get_correct_quantity()) * 100, // Dollars
        name: this.state.name, // supporter
        gift_id: this.props.match.params.id,
        quantity: this.get_correct_quantity(),
        buyer_email: this.state.email,
        // v2 attr for email system
        support_message: this.state.support_message,
        recipient_name: this.state.gift.recipient_name,
        location: this.state.gift.location,
        invitation_code: this.state.invitation,
        time_taken:
          Math.floor((new Date() - this.state.time_taken) / 1000) + "",
      },
      token: token,
    };

    if (BUILD_LEVEL === "prod") {
      body.env = "prod";
    } else if (BUILD_LEVEL === "staging") {
      body.env = "staging";
    }

    this.setState({ showModal: false, loading: true });

    axios
      .post(
        `https://the-tree-project-server.firebaseapp.com/${BUILD_LEVEL}/v1/support`,
        body
      )
      .then((response) => {
        this.setState({ loading: false });
        if (response.data.status === "success") {
          toast(
            `You have successfully supported this gift ${this.state.name}! Please check back in a minute.`,
            {
              type: "success",
            }
          );
          // console.log("SUCCESS! ");
        }
      });
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ validated: true });
  };

  handleSubmitWithInvitation = () => {
    this.setState({ showModal: false });

    let body = {
      gift_id: this.props.match.params.id,
      name: this.state.name, // supporter
      buyer_email: this.state.email,
      quantity: this.state.quantity,
      // v2 attr for email system
      support_message: this.state.support_message,
      recipient_name: this.state.gift.recipient_name,
      location: this.state.gift.location,
      time_taken: Math.floor((new Date() - this.state.time_taken) / 1000) + "",
    };

    this.setState({ loading: true });
    axios
      .post(
        `https://the-tree-project-server.firebaseapp.com/${BUILD_LEVEL}/v1/submit_invitation`,
        body
      )
      .then((response) => {
        // console.log("response: ", response);
        this.setState({ loading: false });

        if (response.data.status === 200) {
          if (this.state.quantity == "0") {
            toast(
              `Your message has been submitted! Please refresh to see the updated gift.`,
              {
                type: "success",
              }
            );
          } else {
            toast(
              `You have successfully supported this gift ${this.state.name}! Please check back in a minute.`,
              {
                type: "success",
              }
            );
          }
        }
      });
  };

  /** [start] methods for together forest page */
  uploadImage = (compressed_image) => {
    let gift_id = this.props.match.params.id;
    storage
      .ref(`gift_cover_images/${gift_id}`)
      .put(compressed_image)
      .then((resp) => {
        if (resp.state == "success") {
          this.setState({
            referral_url: `gifts/${gift_id}`,
            img_counter: this.state.img_counter + 1,
          });
          toast("Success!", { type: "success" });
        }
      });
  };

  onChangeForestNameHandle = (event) => {
    const { name, value } = event.target;
    let gift = this.state.gift;
    gift.forest_name = value;
    this.setState({ gift });
  };

  submitForestName = () => {
    let user_email =
      this.context && this.context.email ? this.context.email : "";

    if (
      this.state.gift.recipient_email === user_email ||
      this.state.gift.buyer_email === user_email
    ) {
      // submit edit
      this.giftRef
        .update({
          forest_name: this.state.gift.forest_name,
        })
        .then((resp) => {
          toast("Success!", { type: "success" });
        });
    }
  };

  /** [end] methods for together forest page */
  render() {
    let { name, email, quantity, validated, support_message } = this.state;

    return (
      <>
        {this.state.invitation && <GiftBanner {...this.state} />}
        <GiftBackground color={this.state && this.state.gift.gift_cover}>
          {/* {this.state.invitation && <GiftBanner {...this.state} />} */}
          <GiftCover
            {...this.state}
            gift_id_demo={this.props.gift_id === "" ? "" : this.props.gift_id}
          />
          <ToastContainer />
          {this.state.loading && (
            <div className="loading">
              <div className="loader"></div>
            </div>
          )}
          {this.state.gift && this.state.gift.no_world === true && (
            <Container
              style={{
                height: "100%",
                paddingTop: "5px",
                paddingBottom: "20px",
                backgroundColor: "#FFF5D8",
                maxWidth: "100%",
              }}
            >
              <NewGallery {...this.state} page={"gifts"} />
            </Container>
          )}
          <Container
            style={{
              height: "100%",
            }}
          >
            {this.state.error === false ? (
              <>
                <Card
                  style={{
                    border: "none",
                    backgroundColor: handleColor(
                      this.state && this.state.gift.gift_cover
                    ),
                  }}
                >
                  <Card.Body>
                    {this.state.gift && this.state.gift.no_world === true ? (
                      <> </>
                    ) : (
                      <>
                        <Row style={{ paddingBottom: "1.5rem" }}>
                          <Card.Subtitle
                            as="h3"
                            style={{ textAlign: "center", margin: "auto" }}
                            // className="mb-2 "
                          >
                            {Number(this.state.gift.quantity) === 1 ? (
                              <GiftIntro>
                                <span>
                                  One tree was planted in{" "}
                                  {this.state.gift.location &&
                                    this.state.gift.location
                                      .charAt(0)
                                      .toUpperCase() +
                                      this.state.gift.location.slice(1) +
                                      ` for ${this.state.gift.recipient_name} `}
                                  🎉
                                </span>
                              </GiftIntro>
                            ) : (
                              <GiftIntro>
                                <span>
                                  {this.state.gift.quantity} trees planted in{" "}
                                  {this.state.gift.location &&
                                    this.state.gift.location
                                      .charAt(0)
                                      .toUpperCase() +
                                      this.state.gift.location.slice(1) +
                                      ` for ${this.state.gift.recipient_name} `}
                                  🎉
                                </span>
                              </GiftIntro>
                            )}
                          </Card.Subtitle>
                        </Row>

                        <Row
                          style={{ padding: "0px 1% 0px 1%", height: "500px" }}
                        >
                          <World id="gift-forest-vis">
                            <Toggle>
                              <div
                                className={
                                  this.state.scrollable_world
                                    ? "Scrollable-world"
                                    : "Not-scrollable-world"
                                }
                                onClick={() => {
                                  this.setState({ scrollable_world: true });
                                }}
                              >
                                <button
                                  onClick={ReactButtonHandler(
                                    `Button - Navigate World ${window.location.pathname}`,
                                    this.state.time_taken
                                  )}
                                  className="button button3"
                                >
                                  <img
                                    alt=""
                                    style={{ width: "25px" }}
                                    src={
                                      "https://image.flaticon.com/icons/svg/81/81422.svg"
                                    }
                                  />{" "}
                                  &nbsp;
                                  <span>
                                    Click here to navigate the world{" "}
                                    {this.state.gift.quantity
                                      ? this.state.gift.quantity === 1
                                        ? "of your tree"
                                        : this.state.gift.quantity > 30
                                        ? "your trees"
                                        : `of your ${this.state.gift.quantity} trees`
                                      : ""}
                                  </span>
                                </button>
                              </div>
                            </Toggle>
                            <div
                              truncheight={75}
                              foliagedensity={65}
                              quantity={
                                this.state.gift.quantity > 30
                                  ? 30
                                  : this.state.gift.quantity
                              }
                              id="worlds"
                            />
                          </World>
                        </Row>

                        <Row
                          style={{
                            color: "white",
                            height: "0px",
                          }}
                        >
                          <Col
                            style={{
                              paddingTop: "0.5rem",
                              textAlign: "center",
                              bottom: "40px",
                              color: "white!important",
                              fontWeight: "700",
                            }}
                          >
                            Left click to rotate and hold both buttons to move.{" "}
                          </Col>
                        </Row>

                        <Row
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <SocialBar>
                            <div className="social-buttons">
                              <FacebookShareButton
                                url={
                                  `https://www.thankyoutrees.io/` +
                                  window.location.pathname
                                }
                                style={{
                                  paddingLeft: "5px",
                                }}
                              >
                                <FacebookIcon size={32} round={"true"} />
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={
                                  `https://www.thankyoutrees.io/` +
                                  window.location.pathname
                                }
                                size={32}
                                round={"true"}
                                style={{
                                  paddingLeft: "5px",
                                }}
                              >
                                <TwitterIcon size={32} round={"true"} />
                              </TwitterShareButton>
                              <WhatsappShareButton
                                url={
                                  `https://www.thankyoutrees.io/` +
                                  window.location.pathname
                                }
                                size={32}
                                round={"true"}
                                style={{
                                  paddingLeft: "5px",
                                }}
                              >
                                <WhatsappIcon size={32} round={"true"} />
                              </WhatsappShareButton>

                              <EmailShareButton
                                url={
                                  `https://www.thankyoutrees.io/` +
                                  window.location.pathname
                                }
                                size={32}
                                round={"true"}
                                style={{
                                  paddingLeft: "5px",
                                }}
                              >
                                <EmailIcon size={32} round={"true"} />
                              </EmailShareButton>

                              <span
                                className="download_cert"
                                style={{
                                  paddingLeft: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    variant="success"
                                    onClick={this.jsPdfGenerator2}
                                    type="primary"
                                    className="btn-download"
                                    size="sm"
                                  >
                                    {" "}
                                    Download Certificate{" "}
                                  </Button>
                                </div>
                              </span>
                            </div>
                          </SocialBar>
                        </Row>
                        <GiftCertificate
                          quantity={this.state.gift.quantity}
                          location={this.state.gift.location}
                          country={
                            this.state.gift.location &&
                            this.state.gift.location.charAt(0).toUpperCase() +
                              this.state.gift.location.slice(1)
                          }
                          to={this.state.gift && this.state.gift.recipient_name}
                          by={
                            (this.state.gift.sender &&
                              this.state.gift.sender.name) ||
                            (this.state.gift.buyer_name &&
                              this.state.gift.buyer_name)
                          }
                          gift_id={
                            this.props.match ? this.props.match.params.id : ""
                          }
                        />
                      </>
                    )}

                    <Row>
                      <Row
                        style={{
                          width: "100%",
                          paddingBottom: "25px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Col
                          md={4}
                          style={{
                            paddingRight: "0",
                            paddingLeft: "0",
                          }}
                        >
                          <GiftSummary
                            uploadImage={this.uploadImage}
                            onChangeForestNameHandle={
                              this.onChangeForestNameHandle
                            }
                            submitForestName={this.submitForestName}
                            {...this.state}
                          />{" "}
                        </Col>
                        <Col>
                          <div className="wrapper wrapper_message" id="message">
                            {/* GIFT Message */}
                            <GiftMessage {...this.state} />

                            {/* GIFT COMMENTS */}
                            {this.state.gift.gift_cover !== "togetherness" && (
                              <GiftComments gift={this.state.gift} />
                            )}
                          </div>
                          <span
                            style={{
                              position: "absolute",
                              top: "-100px",
                            }}
                            id={"contribute_message"}
                          ></span>
                          <ButtonToolbar
                            id="contribute_message"
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              justifyContent: "space-around",
                              width: "100%",
                            }}
                          >
                            <Button
                              className="contribute_button"
                              style={{ width: "40%" }}
                              onClick={() => {
                                ReactButtonHandler(
                                  `Button - Contribute Gift ${window.location.pathname}`,
                                  this.state.time_taken
                                );
                                this.setState({ showModal: true });
                              }}
                              variant="success"
                              size="lg"
                              disabled={!this.state.invitation}
                            >
                              {this.state.invitation
                                ? `Write a message`
                                : this.state.gift.gift_cover === "togetherness"
                                ? "Contribute to this forest"
                                : "Contribute to this gift"}
                            </Button>
                          </ButtonToolbar>
                          {!this.state.invitation && (
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <div>
                                <a href="https://fadyazmy.notion.site/How-to-contribute-trees-to-your-TYT-gift-page-06a48ee75fd640029f97b1692d20475d" target="_blank">
                                  Read this{" "}
                                </a>
                                to contribute
                              </div>
                            </div>
                          )}
                        </Col>
                        <Modal
                          show={this.state.showModal}
                          onHide={() => this.setState({ showModal: false })}
                        >
                          <Modal.Header closeButton>
                            {this.state.gift.gift_cover === "togetherness" ? (
                              "Contribute to this forest"
                            ) : (
                              <Modal.Title>
                                Contribute to{" "}
                                {(this.state.gift.sender &&
                                  this.state.gift.sender.name) ||
                                  (this.state.gift.buyer_name &&
                                    this.state.gift.buyer_name)}
                                's gift{" "}
                              </Modal.Title>
                            )}
                          </Modal.Header>
                          <Modal.Body>
                            <Form
                              validated={validated}
                              onSubmit={this.handleSubmit}
                            >
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control
                                  value={name}
                                  required
                                  name="name"
                                  onChange={this.handleChange}
                                  type="text"
                                  placeholder="Forest Grump"
                                />
                              </Form.Group>

                              <Form.Group controlId="exampleForm.ControlInput2">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                  value={email}
                                  required
                                  name="email"
                                  onChange={this.handleChange}
                                  type="email"
                                  placeholder="trunk@treemail.com"
                                />
                              </Form.Group>
                              {!this.state.invitation && (
                                <Form.Group controlId="exampleForm.ControlSelect3">
                                  <Form.Label>Number of trees</Form.Label>
                                  <Form.Control
                                    type="number"
                                    required
                                    placeholder="3"
                                    value={quantity}
                                    name="quantity"
                                    onChange={this.handleChange}
                                  />
                                </Form.Group>
                              )}
                              <Form.Group
                                style={{
                                  marginBottom: "0.5rem",
                                }}
                                controlId="exampleForm.ControlSelect3"
                              >
                                {this.state.gift.gift_cover ===
                                "togetherness" ? (
                                  <Form.Label>Your message</Form.Label>
                                ) : (
                                  <Form.Label>
                                    Your message to{" "}
                                    {this.state.gift &&
                                      this.state.gift.recipient_name}{" "}
                                  </Form.Label>
                                )}
                                <Form.Control
                                  type="number"
                                  placeholder="Thank you for everything that you do!"
                                  value={support_message}
                                  name="support_message"
                                  as="textarea"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>

                              {this.state.invitation && (
                                <label>
                                  <input
                                    type="checkbox"
                                    name="tree_num_contribution_flag"
                                    value={
                                      this.state.tree_num_contribution_flag
                                    }
                                    checked={
                                      this.state.tree_num_contribution_flag
                                    }
                                    onChange={this.handleChange}
                                  />
                                  &nbsp;&nbsp; Plant trees with your
                                  contribution (
                                  <a
                                    target="_blank"
                                    href="https://fadyazmy.notion.site/How-to-contribute-trees-to-your-TYT-gift-page-06a48ee75fd640029f97b1692d20475d"
                                  >
                                    read more
                                  </a>
                                  ). Your name will also be included in the
                                  Community section of this gift.{" "}
                                </label>
                              )}
                              {this.state.invitation &&
                                this.state.tree_num_contribution_flag && (
                                  <Form.Group controlId="exampleForm.ControlSelect3">
                                    <Form.Label>Number of trees</Form.Label>
                                    <Form.Control
                                      type="number"
                                      required
                                      placeholder="3"
                                      value={quantity}
                                      name="quantity"
                                      onChange={this.handleChange}
                                    />
                                  </Form.Group>
                                )}
                            </Form>
                          </Modal.Body>
                          <Modal.Footer
                            style={{ display: "block", textAlign: "center" }}
                          >
                            <Button
                              style={{ width: "40%" }}
                              variant="secondary"
                              onClick={() =>
                                this.setState({ showModal: false })
                              }
                            >
                              Close
                            </Button>

                            {this.supportCTAButton({ ...this.state })}
                          </Modal.Footer>
                        </Modal>
                      </Row>

                      <Row
                        as={MobileBodyMargin}
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="col-2 side-menu" id="myScrollspy">
                          <Scrollspy
                            style={{
                              marginTop: "2.5em",
                            }}
                            items={[
                              // "message",
                              "community",
                              "location",
                              "tree",
                              "ecosystem",
                              "planting_team",
                              "impact",
                            ]}
                            componentTag="div"
                            currentClassName="active"
                            scrolledPastClassName=""
                            className="list-group"
                          >
                            {/* <a
                          className="list-group-item list-group-item-action"
                          href="#message"
                        >
                          Message
                        </a> */}
                            {this.state.gift &&
                              this.state.gift.gift_cover !== "togetherness" && (
                                <a
                                  className="list-group-item list-group-item-action"
                                  href="#community"
                                >
                                  Community
                                </a>
                              )}
                            {/* {this.state.gift.location !== "haiti" && ( */}
                            <a
                              className="list-group-item list-group-item-action"
                              href="#location"
                            >
                              Location
                            </a>
                            <a
                              key={"tree"}
                              className="list-group-item list-group-item-action"
                              href="#tree"
                            >
                              Tree type
                            </a>
                            <a
                              className="list-group-item list-group-item-action"
                              href="#ecosystem"
                            >
                              Ecosystem
                            </a>
                            <a
                              className="list-group-item list-group-item-action"
                              href="#planting_team"
                            >
                              Planting team
                            </a>
                            <a
                              className="list-group-item list-group-item-action"
                              href="#impact"
                            >
                              Your impact
                            </a>
                          </Scrollspy>
                        </div>

                        <div className="col-10 gift_body">
                          <Row>
                            <GiftMessageStyle>
                              {/* Gift community */}
                              {this.state.gift &&
                                this.state.gift.gift_cover !==
                                  "togetherness" && (
                                  <SupportList
                                    community={this.state.gift.supporters}
                                  />
                                )}
                              <GiftMap country={this.state.gift.location} />
                              <TreeInfo country={this.state.gift.location} />
                              {/* <Timeline /> */}
                              {/* Gift Gallery */}
                              <Gallery country={this.state.gift.location} />

                              {/* PlantingTeam */}
                              <PlantingTeam
                                country={this.state.gift.location}
                              />

                              {/* CO2 GRAPH */}
                              <TreeGraph quantity={this.state.gift.quantity} />
                              <div style={{ width: "100%" }}>
                                {/* madagascar tooltip */}
                                {this.state.gift.location === "madagascar" &&
                                  this.state.alert && (
                                    <Alert
                                      key={"success"}
                                      variant={"success"}
                                      onClose={() =>
                                        this.setState({
                                          alert: !this.state.alert,
                                        })
                                      }
                                      dismissible
                                    >
                                      <Alert.Heading>
                                        Reforestation in Madagascar{" "}
                                      </Alert.Heading>
                                      <p>
                                        We work with reforestation partners, in
                                        this case the{" "}
                                        <a
                                          style={{ fontWeight: "800" }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="https://edenprojects.org/madagascar/"
                                        >
                                          Eden Project
                                        </a>
                                        , to plant your tree gift. Eden project
                                        has planted about 250 million trees to
                                        date.
                                      </p>
                                    </Alert>
                                  )}

                                {/* nepal tooltip */}
                                {this.state.gift.location === "nepal" && (
                                  <Alert
                                    key={"success"}
                                    variant={"success"}
                                    onClose={() =>
                                      this.setState({
                                        alert: !this.state.alert,
                                      })
                                    }
                                    dismissible
                                  >
                                    <Alert.Heading>
                                      Planting in Nepal{" "}
                                    </Alert.Heading>
                                    <p>
                                      This gift will help our reforestation
                                      partner the{" "}
                                      <a
                                        style={{ fontWeight: "800" }}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://edenprojects.org/nepal/"
                                      >
                                        Eden Project
                                      </a>
                                      , who have planted more than 3 million
                                      trees in the country to date.
                                    </p>
                                  </Alert>
                                )}

                                {/* Haiti tooltip */}
                                {this.state.gift.location === "haiti" && (
                                  <Alert
                                    key={"success"}
                                    variant={"success"}
                                    onClose={() =>
                                      this.setState({
                                        alert: !this.state.alert,
                                      })
                                    }
                                    dismissible
                                  >
                                    <Alert.Heading>
                                      {" "}
                                      Planting in Haiti{" "}
                                    </Alert.Heading>
                                    <p>
                                      We work with reforestation partners, in
                                      this case the{" "}
                                      <a
                                        style={{ fontWeight: "800" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://edenprojects.org/madagascar/"
                                      >
                                        Eden Project
                                      </a>
                                      , to plant your tree gift. Eden project
                                      has planted about 250 million trees to
                                      date.
                                    </p>
                                  </Alert>
                                )}
                              </div>
                            </GiftMessageStyle>
                          </Row>
                        </div>
                      </Row>
                    </Row>
                  </Card.Body>
                  <br />
                </Card>
              </>
            ) : (
              <Row
                style={{
                  backgroundColor: handleColor(
                    this.state && this.state.gift.gift_cover
                  ),
                }}
              >
                <div className="col-md-12">
                  <div className="error-template">
                    <h1>Oh no!</h1>
                    <h2>Your gift link was incorrect</h2>
                    <div className="error-details">
                      The requested gift was not found, try using the gift link
                      from your confirmation email.
                    </div>
                    <div className="error-actions">
                      <Button
                        variant="primary"
                        href="/"
                        className="btn btn-primary btn-lg"
                      >
                        Take Me Home{" "}
                      </Button>
                      <Button
                        href={
                          "https://docs.google.com/forms/d/e/1FAIpQLSe2sjRt380cxdi3q9C-JbTYThxm7NvEqXQfokBvTc0vYqFoPA/viewform"
                        }
                        target="_blank"
                        className="btn btn-default btn-lg"
                        variant="secondary"
                      >
                        Contact Support
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            )}
            <br />
            <br />
          </Container>
        </GiftBackground>
      </>
    );
  }
}

export default Gifts;
