import React, { Component } from "react";
import { UserContext } from "../../Providers/UserProvider";

import summary_1 from "../../assets/summary_1.png";
import summary_1_for_me from "../../assets/summary_1_for_me.png";
import summary_2 from "../../assets/summary_2.png";
import summary_2_for_me from "../../assets/summary_2_for_me.png";
import summary_3 from "../../assets/summary_3.png";
import summary_3_for_me from "../../assets/summary_3_for_me.png";

import question_mark from "../../assets/question_mark.png";
import dayjs from "dayjs";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

class ImpactSummary extends Component {
  static contextType = UserContext;

  state = {
    summary_type: "purchased_gifts",
  };

  carbon_sequestration_calc = (tree_quantity) => {
    return 308 * tree_quantity;
  };

  // JFK to LAX 565.7 KG
  // Using calculator from https://www.icao.int/environmental-protection/Carbonoffset/Pages/default.aspx
  flight_calc = (sequestration) => {
    return Math.floor(sequestration / 141.3);
  };

  // Using note from https://www.nature.org/en-us/get-involved/how-to-help/carbon-footprint-calculator/
  human_year_calc = (sequestration) => {
    return (sequestration / 14515).toFixed(2);
  };

  // 404g per mile, 252g per KM. Therefor, it's seq/0.252 = #KM
  // Using note from https://www.epa.gov/greenvehicles/greenhouse-gas-emissions-typical-passenger-vehicle
  car_calc = (tree_quantity) => {
    let sequestration = this.carbon_sequestration_calc(tree_quantity);
    let res = (sequestration / 0.252).toFixed(0);
    return Number(res).toLocaleString();
  };

  // logic here, 308KG/25 year
  months_to_CO2 = (months) => {
    let yearlyCO2 = 308 / 25;
    let tree_years_lived = months / 12;
    return (yearlyCO2 * tree_years_lived).toFixed(0);
  };

  real_timeCO2 = (gifts) => {
    let total = 0;
    let now = dayjs();
    gifts.map((gift) => {
      let created_at = gift.createdAt;
      let num_months = now.diff(created_at.toDate(), "month", true);
      total += num_months * gift.quantity;
      return null;
    });

    return this.months_to_CO2(total);

    // for every gift (WHERE env = prod)
    // calculate number of months since planted
    // convert months to CO2 and add to sum
  };

  render() {
    let sequestration = "";
    if (this.state.summary_type == "purchased_gifts") {
      sequestration = this.real_timeCO2(this.props.purchased_gifts);
    } else if (this.state.summary_type == "received_gifts") {
      sequestration = this.real_timeCO2(this.props.gifts_for_me);
    }
    return (
      <>
        {" "}
        <div>
          <Form>
            <Form.Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0px",
              }}
            >
              <h3
                style={{
                  marginBottom: "0px",
                }}
              >
                Real-time impact summary
              </h3>
              <Form.Group
                style={{
                  marginBottom: "0px",
                }}
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control
                  onChange={(e) => {
                    this.setState({
                      summary_type: e.target.value,
                    });
                  }}
                  as="select"
                >
                  <option value="purchased_gifts">Purchased gifts</option>
                  <option value="received_gifts">Received gifts</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
          <hr
            style={{
              height: "3px",
              backgroundColor: "#e5e5e5",
            }}
          />
        </div>{" "}
        <span>
          {" "}
          As your trees grow, they remove more CO₂ from the atmosphere. Your
          impact summary explains what this means with daily updated figures.
        </span>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            // height: "126px",
          }}
        >
          <div
            style={{
              width: "206px",
              backgroundImage: `url(${
                this.state.summary_type == "purchased_gifts"
                  ? summary_1
                  : summary_1_for_me
              })`,
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              fontSize: "30px",
              fontWeight: "700",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "10px",
            }}
            alt=""
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
                marginLeft: "2px",
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="">
                    This is the total CO₂ the gifts you{" "}
                    {this.state.summary_type === "purchased_gifts"
                      ? "bought "
                      : "received "}
                    have absorbed so far.
                  </Tooltip>
                }
              >
                <img
                  style={{
                    width: "24px",
                    cursor: "pointer",
                  }}
                  alt="question_mark"
                  src={question_mark}
                />
              </OverlayTrigger>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <span>{sequestration}</span>
              <span>KG</span>
            </div>
          </div>
          <div
            style={{
              width: "206px",
              backgroundImage: `url(${
                this.state.summary_type == "purchased_gifts"
                  ? summary_2
                  : summary_2_for_me
              })`,
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              fontSize: "30px",
              fontWeight: "700",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "10px",
            }}
            alt=""
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
                marginLeft: "2px",
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="">
                    This is the number of 2-hour flights{" "}
                    {this.state.summary_type === "purchased_gifts"
                      ? "you have offset to date."
                      : "people have offset to date for you."}
                  </Tooltip>
                }
              >
                <img
                  style={{
                    width: "24px",
                    cursor: "pointer",
                  }}
                  alt="question_mark"
                  src={question_mark}
                />
              </OverlayTrigger>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <span>{this.flight_calc(sequestration)}</span>
              <span>flight</span>
            </div>
          </div>
          <div
            style={{
              width: "206px",
              backgroundImage: `url(${
                this.state.summary_type == "purchased_gifts"
                  ? summary_3
                  : summary_3_for_me
              })`,
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              fontSize: "30px",
              fontWeight: "700",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "10px",
            }}
            alt=""
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
                marginLeft: "2px",
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="">
                    This is the amount{" "}
                    {this.state.summary_type === "purchased_gifts"
                      ? "you've "
                      : "people have offset for you "}
                    relative to CO₂ emissions of the average person in USA (16
                    tons of CO₂/year).
                  </Tooltip>
                }
              >
                <img
                  style={{
                    width: "24px",
                    cursor: "pointer",
                  }}
                  alt="question_mark"
                  src={question_mark}
                />
              </OverlayTrigger>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "25px",
                }}
              >
                {this.human_year_calc(sequestration)}
              </span>
              <span
                style={{
                  fontSize: "30px",
                }}
              >
                years
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ImpactSummary;
