import React, { Component } from "react";
import dayjs from "dayjs";
import forest_0cm from "../../assets/forests/forest_0cm.png";
import forest_5cm from "../../assets/forests/forest_5cm.png";
import forest_10cm from "../../assets/forests/forest_10cm.png";
import forest_15cm from "../../assets/forests/forest_15cm.png";
import forest_25cm from "../../assets/forests/forest_25cm.png";
import forest_50cm from "../../assets/forests/forest_50cm.png";
import forest_100cm from "../../assets/forests/forest_100cm.png";
import forest_150cm from "../../assets/forests/forest_150cm.png";
import forest_200cm from "../../assets/forests/forest_200cm.png";
import forest_250cm from "../../assets/forests/forest_250cm.png";

class YourForests extends Component {
  state = {};

  getCorrectForestImg = (current_height) => {
    let array = [0, 5, 10, 15, 25, 50, 100, 150, 200, 250];
    let closest = Math.max(...array.filter((num) => num <= current_height));
    switch (closest) {
      case 0:
        return forest_0cm;
      case 5:
        return forest_5cm;
      case 10:
        return forest_10cm;
      case 15:
        return forest_15cm;
      case 25:
        return forest_25cm;
      case 50:
        return forest_50cm;
      case 100:
        return forest_100cm;
      case 150:
        return forest_150cm;
      case 200:
        return forest_200cm;
      case 250:
        return forest_250cm;
    }
  };

  getCurrentHeight = (gift) => {
    let created_at = gift.createdAt;
    let now = dayjs();
    let num_months = now.diff(created_at.toDate(), "month", true);
    let height_growth_year = 15; // 15 cm growth every year

    // if < 0, return 2 decimal places
    // else, return full number
    let curr_height = (height_growth_year / 12) * num_months; // (x cm/month) * num_months
    if (Number(curr_height) > 1) {
      return Math.floor(Number(curr_height) / 5) * 5;
    } else {
      return curr_height.toFixed(2);
    }
  };

  newForestCard = (gift) => {
    let current_height = this.getCurrentHeight(gift);
    return {
      card: (
        <div
          style={{
            background: "#947151",
            display: "flex",
            justifyContent: "center",
            margin: "10px",
          }}
          key={`new_forest_${gift.id}`}
        >
          <div
            style={{
              width: "321px",
              height: "160px",
              background: "#F8FFD8",
              border: "3px solid #438F00",
              boxSizing: "borderBox",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                margin: "24px",
                display: "flex",
              }}
            >
              <div>
                <img
                  style={{
                    height: "110px",
                  }}
                  alt="icon_1"
                  src={this.getCorrectForestImg(current_height)}
                />{" "}
              </div>
              <div
                style={{
                  paddingLeft: "12px",
                }}
              >
                <div>
                  {" "}
                  <a
                    style={{
                      color: "rgb(33, 37, 41)",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    href={`gifts/${gift.id}`}
                    target="_blank"
                  >
                    {gift.gift_cover === "togetherness" ? (
                      <strong>{gift.forest_name}</strong>
                    ) : (
                      <strong>{gift.recipient_name}</strong>
                    )}
                  </a>
                </div>
                <div>
                  {gift.quantity} {gift.quantity > 1 ? "trees" : "tree"}
                </div>
                <div>{current_height} cm</div>
              </div>
            </div>
          </div>
        </div>
      ),
      curr_height: current_height,
    };
  };
  render() {
    return (
      <>
        <div
          style={{
            background: "#947151",
            display: "flex",
            justifyContent: "center",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            style={{
              width: "383px",
              height: "55px",
              background: "#FFFFF3",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <h3
              style={{
                margin: "auto",
              }}
            >
              Your forests
            </h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            background: "#947151",
          }}
        >
          {this.props.all_gifts.length == 0 && (
            <div
              style={{
                color: "#FFFFF3",
                marginBottom: "30px",
              }}
            >
              No forests have been planted yet.
            </div>
          )}
          {this.props.all_gifts
            .map((gift) => this.newForestCard(gift))
            .sort((a, b) => a.curr_height - b.curr_height)
            .map((gift) => gift.card)}
        </div>
        <br />
      </>
    );
  }
}
export default YourForests;
