import React, { Component, Fragment } from "react";
import { Card, Container } from "react-bootstrap";

import Jean_Zamanjisy from "../assets/jean_zamanjisy.png";
import Ravelo from "../assets/ravelo.png";
import mr_frederic from "../assets/mr_frederic.jpg";
import rachhya from "../assets/rachhya.jpg";
import nirmala from "../assets/nirmala.png";
import chandra from "../assets/chandra.png";
import wilner from "../assets/wilner.jpg";
import ylfraunier from "../assets/ylfraunier.jpg";
import ketty from "../assets/ketty.jpg";

import styled from "styled-components";

const MobileView = styled.span`
  @media screen and (max-width: 475px) {
    flex-direction: column;
    width: auto;
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

class PlantingTeam extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
        }}
        id="planting_team"
      >
        {this.props.country &&
          this.props.country === "madagascar" && [
            <Fragment key={`1`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                Planting team 👱‍♂️👱‍♀️👳‍♂️🧕
              </h3>
              <hr />
              <span
                style={{
                  width: "100%",
                }}
              >
                {" "}
                {this.props.forest_page_flag === true
                  ? "One Tree Planted "
                  : "Eden "}
                employs local people to gather and plant mature propagules from
                mangroves. Below are some of the people that you are helping
                employ to mitigate climate change.{" "}
              </span>
              <br />
              <br />
              <Container
                className="planting_team_card"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexFlow: "row",
                }}
              >
                <MobileView>
                  <Card
                    className="team_planter_card"
                    style={{ width: "15rem" }}
                  >
                    <Card.Img variant="top" src={Jean_Zamanjisy} />
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        <strong> Jean Zamanjisy </strong>{" "}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        “True Village” (Antanamarina) Chief
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>

                  <Card
                    className="team_planter_card"
                    style={{ width: "15rem" }}
                  >
                    <Card.Img variant="top" src={Ravelo} />
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        <strong> Ravélo </strong>{" "}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        Previously a fisherman, now a Forest Manager
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>

                  <Card style={{ width: "15rem" }}>
                    <Card.Img variant="top" src={mr_frederic} />
                    <Card.Body>
                      <Card.Title>
                        {" "}
                        <strong> Jean "Frederic" </strong>{" "}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        Director of Operations for Reforestation
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </MobileView>
              </Container>
            </Fragment>,
          ]}

        {this.props.country &&
          this.props.country === "nepal" && [
            <Fragment key={`1`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                Planting team 👱‍♂️👱‍♀️👳‍♂️🧕
              </h3>
              <hr />
              <span
                style={{
                  width: "100%",
                }}
              >
                {" "}
                Eden employs local women, enabling them to support their
                families with a steady income. Below are some of the people that
                you are helping employ to mitigate climate change.{" "}
              </span>
              <br />
              <br />
              <Container
                className="planting_team_card"
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Card className="team_planter_card" style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={rachhya} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> Rachhya Kayastha </strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      International Director for Nepal
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
                <Card className="team_planter_card" style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={nirmala} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> Nirmala Basnet </strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Mother and tree planter
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
                <Card style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={chandra} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> Chandra Kala Limbu </strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Mother and tree planter
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Container>
            </Fragment>,
          ]}

        {this.props.country &&
          this.props.country === "haiti" && [
            <Fragment key={`1`}>
              <h3
                style={{
                  paddingTop: "1.5em",
                }}
              >
                {" "}
                Planting team 👱‍♂️👱‍♀️👳‍♂️🧕
              </h3>
              <hr />
              <span
                style={{
                  width: "100%",
                }}
              >
                {" "}
                By empowering local farmers, the community recognizes the
                importance of the trees and the benefits they provide for the
                health of their families and environment. Below are some of the
                people that you are helping employ to mitigate climate change.{" "}
              </span>
              <br />
              <br />
              <Container
                className="planting_team_card"
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Card className="team_planter_card" style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={wilner} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> Wilner (town of Fonds-Verrettes) </strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Local farmer and tree planter
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
                <Card className="team_planter_card" style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={ylfraunier} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong>
                        {" "}
                        Ylfraunier Germain (village of Bwa Tombe)
                      </strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Local farmer and tree planter with four children.
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
                <Card style={{ width: "15rem" }}>
                  <Card.Img variant="top" src={ketty} />
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <strong> Ketty (town of Fonds-Verrettes)</strong>{" "}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      Lead Agronomist
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Container>
            </Fragment>,
          ]}
      </div>
    );
  }
}
export default PlantingTeam;
