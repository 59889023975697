import React, { Component } from "react";

class GiftComments extends Component {
  render() {
    return (
      <React.Fragment key={'some_key'}>
        {this.props.gift.supporters &&
          this.props.gift.supporters.length > 0 &&
          this.props.gift.supporters.map(
            (supp, id) =>
              supp.support_message && (
                <div className="speechbubble" key={'id-' + id}>
                  <p>
                    {supp.support_message}
                    <span className="username">{supp.name}</span>
                  </p>
                </div>
              )
          )}
      </React.Fragment>
    );
  }
}

export default GiftComments;
