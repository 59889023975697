import React, { Component } from "react";
import { Card, Container, Button } from "react-bootstrap";

// ### Madagascar
import madascar_cover from "../assets/madagascar_cover.jpg";
import mangrove_img from "../assets/mangrove_img.jpg";
/// icons - Madagascar
import leaf from "../assets/leaf.svg";
import fish from "../assets/fish.svg";
import wall from "../assets/wall.svg";
/// People - Madagascar
import Jean_Zamanjisy from "../assets/jean_zamanjisy.png";
import Ravelo from "../assets/ravelo.png";
import mr_frederic from "../assets/mr_frederic.jpg";

// ### Nepal
import nepal_cover from "../assets/nepal_cover.jpg";
import rosewood from "../assets/dalbergia_img.jpg";
/// icons - Nepal
import education from "../assets/education.svg";
import women from "../assets/women.svg";
import jobs from "../assets/jobs.svg";
/// People - Nepal
import rachhya from "../assets/rachhya.jpg";
import nirmala from "../assets/nirmala.png";
import chandra from "../assets/chandra.png";

// ## Haiti
import haiti_cover from "../assets/haiti_cover.jpg";
import bayawonn from "../assets/bayawonn.png";
// icons - Haiti
import food from "../assets/food.svg";
import tools from "../assets/tools.svg";
import slides from "../assets/slides.png";
// People - Haiti
import wilner from "../assets/wilner.jpg";
import ylfraunier from "../assets/ylfraunier.jpg";
import ketty from "../assets/ketty.jpg";

import countries from "./countries_info.json";
import countries_cover from "../assets/countries_cover.png";

let images = {
  madagascar: {
    cover: madascar_cover,
    planters: {
      "Jean Zamanjisy": Jean_Zamanjisy,
      Ravélo: Ravelo,
      'Jean "Frederic"': mr_frederic,
    },
    benefits: {
      "Carbon sequestration": leaf,
      "Wildlife and Fishing": fish,
      "Coastal Defense": wall,
    },
    tree_img: mangrove_img,
  },
  nepal: {
    cover: nepal_cover,
    planters: {
      "Rachhya Kayastha": rachhya,
      "Nirmala Basnet": nirmala,
      "Chandra Kala Limbu": chandra,
    },
    benefits: {
      Employment: jobs,
      "Empowering Women": women,
      Education: education,
    },
    tree_img: rosewood,
  },
  haiti: {
    cover: haiti_cover,
    planters: {
      "Wilner (town of Fonds-Verrettes)": wilner,
      "Ylfraunier Germain (village of Bwa Tombe)": ylfraunier,
      "Ketty (town of Fonds-Verrettes)": ketty,
    },
    benefits: {
      "Produce Food": food,
      "Education and Tools": tools,
      "Prevent Landslides": slides,
    },
    tree_img: bayawonn,
  },
};

class Country extends Component {
  state = {
    selected_country: this.props.match.params.country,
  };

  render() {
    return (
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src={images[this.state.selected_country]["cover"]}
              alt=""
            />
          </div>

          <div className="col-lg-5">
            <h1
              style={{
                marginBottom: "0px",
              }}
              className="font-weight-light"
            >
              {countries[this.state.selected_country]["country_name"]}
            </h1>
            <p
              style={{
                marginBottom: "0.5rem",
              }}
              className="lead text-muted"
            >
              {" "}
              {countries[this.state.selected_country]["country_location"]}
            </p>
            <p className="lead">
              {countries[this.state.selected_country]["country_bio"]}
            </p>
            <Button
              target="_blank"
              variant="secondary"
              size="lg"
              href={countries[this.state.selected_country]["example_gift_link"]}
            >
              Example gift
            </Button>{" "}
            <Button variant="success" size="lg" href="/#gift">
              Gift now
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#9abf33",
          }}
          className="card text-white my-5 py-4 text-center"
        >
          <div className="card-body">
            <p className="text-white m-0 lead">
              <strong>
                Thank You Trees empowers communities, fight climate change and
                bring meaningful gifts to celebrations.
              </strong>
            </p>
          </div>
        </div>

        <div
          className="row featurette align-items-center"
          style={{
            marginBottom: "3rem",
            marginTop: "3rem",
          }}
        >
          <div className="col-md-6">
            <h2 className="featurette-heading">
              {countries[this.state.selected_country]["tree_name"]}
              {this.state.selected_country === "madagascar" && (
                <span className="text-muted">(walking trees)</span>
              )}
            </h2>
            <p className="lead">
              {countries[this.state.selected_country]["tree_bio"]}
            </p>
          </div>
          <div className="col-md-6">
            <img
              className="featurette-image img-fluid mx-auto"
              data-src="holder.js/500x500/auto"
              alt="500x500"
              src={images[this.state.selected_country]["tree_img"]}
              data-holder-rendered="true"
            />
          </div>
        </div>

        <h3 style={{ marginTop: "1.5em" }}>
          {" "}
          Benefits <span role="img" aria-label="community emoji"></span>
        </h3>

        <hr />

        <span
          style={{
            width: "100%",
          }}
          className="lead"
        >
          {" "}
          {countries[this.state.selected_country]["benefits_bio"]}{" "}
        </span>

        <br />
        <br />

        <div className="row">
          {countries[this.state.selected_country]["benefits"].map(
            (benefit, idx) => (
              <div className="col-md-4 mb-5">
                <div className="card h-100">
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      paddingTop: "10px",
                    }}
                  >
                    <img
                      alt=""
                      style={{
                        padding: "1.25rem",
                        height: "150px",
                      }}
                      src={
                        images[this.state.selected_country]["benefits"][
                          benefit.benefit_name
                        ]
                      }
                    />
                  </div>

                  <div className="card-body">
                    <h2 className="card-title"> {benefit.benefit_name}</h2>
                    <p className="card-text">{benefit.benefit_bio}</p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <h3 style={{ marginTop: "1.0em" }}>
          {" "}
          Planting team <span role="img" aria-label="community emoji"></span>
        </h3>
        <hr />

        <span
          style={{
            width: "100%",
          }}
          className="lead"
        >
          {" "}
          {countries[this.state.selected_country]["planting_bio"]}{" "}
        </span>
        <br />
        <br />

        <Container
          className="planting_team_card"
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {countries[this.state.selected_country]["planting_team"].map(
            (planter, idx) => (
              <Card className="team_planter_card" style={{ width: "17rem" }}>
                <Card.Img
                  variant="top"
                  src={
                    images[this.state.selected_country]["planters"][
                      planter.planter_name
                    ]
                  }
                />
                <Card.Body>
                  <Card.Title>
                    {" "}
                    <strong> {planter.planter_name}</strong>{" "}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {planter.planter_bio}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            )
          )}
        </Container>
        <br />
        <br />
        <div className="py-5 c2a6">
          <div className="container">
            <div className="row justify-content-center">
              <div
                style={{
                  paddingBottom: "30px",
                }}
                className="col-md-8 text-center"
              >
                <h3 className="mb-3">
                  Do you want to gift someone this impact in{" "}
                  {countries[this.state.selected_country]["country_name"]}?
                </h3>

                <Button
                  style={{
                    background: "#ff4d7e",
                    border: "1px solid #ff4d7e",
                  }}
                  href="/#gift"
                  className="btn btn-danger btn-lg mt-3"
                >
                  <strong>Buy a thank you gift</strong>
                </Button>
              </div>
              <div className="col-md-12 text-center mt-3">
                <img
                  src={countries_cover}
                  alt="wrapkit"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Country;
