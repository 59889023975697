import React, { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import dayjs from "dayjs";

class GiftBanner extends Component {
  render() {
    let time_from_now = dayjs(
      this.props.gift.invitation.expiry.toDate()
    ).fromNow();
    return (
      <div
        style={{
          background: "#faf6e6d6",
          backdropFilter: "blur(4px)",
          display: "flex",
          // position: "absolute",
          width: "100%",
          zIndex: "1",
        }}
      >
        <span
          style={{
            margin: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span>
            <strong>Hi there 👋 </strong> {this.props.gift.buyer_name} has
            invited you to write a message (invitation expires {time_from_now})
          </span>
          <span>
            <ButtonGroup size="md">
              <Button variant="success" href="#contribute_message">
                Write message
              </Button>
            </ButtonGroup>
          </span>
        </span>
      </div>
    );
  }
}
export default GiftBanner;
